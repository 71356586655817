import React from 'react';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from '@components/button/LinkButton';
import Title from '@components/typography/Title';
import InstitutionResponse from 'src/types/models/Institution';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ButtonRow from '@components/button/ButtonRow';
import { getCountryName } from '@utils/countries';
import useInstitution from './hooks/useInstitution';

interface Props {
    institution: InstitutionResponse;
}

const Institution: React.FC<Props> = ({ institution }) => {
    const { institutionTypes } = useInstitution();
    const institutionTypeName = institutionTypes.find(
        i => i.id === institution.institutionTypeID,
    )?.name;

    return (
        <>
            <CreateHeader>
                <Title>Institution</Title>
                <ButtonRow alignment="left">
                    <LinkButton source="secondary" href={`/institutions/${institution.id}/edit`}>
                        Edit
                    </LinkButton>
                    <LinkButton source="negative" href={`/institutions/${institution.id}/delete`}>
                        Delete
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{institution.name}</p>
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Address">
                        {[
                            institution.addressLine1,
                            institution.addressLine2,
                            institution.city,
                            getCountryName(institution.country),
                        ]
                            .filter(x => x)
                            .map((x, i) => (
                                <p key={`${x}_{i}`}>{x}</p>
                            ))}
                    </ContentItem>
                    <ContentItem label="Institution Type">
                        <p>{institutionTypeName}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
        </>
    );
};

export default Institution;
