import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../../redux/store';
import {
    getSchoolGroupsIsFetching,
    getSchoolGroupsFetchError,
    getSchoolGroups,
} from '@selectors/schoolGroups';

import { fetchSchoolGroups } from '@actions/schoolGroups';

const useFetchSchools = () => {
    const dispatch = useDispatch();

    const isFetching = useSelector(getSchoolGroupsIsFetching);
    const fetchError = useSelector(getSchoolGroupsFetchError);
    const schoolGroups = useAppSelector(getSchoolGroups);

    useEffect(() => {
        dispatch(fetchSchoolGroups());
    }, [dispatch]);

    return {
        isFetching,
        fetchError,
        schoolGroups,
    };
};

export default useFetchSchools;
