import Form from '@components/form/Form';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';
import React from 'react';
import { Redirect } from 'react-router-dom';
import useEditSchoolGroup from './hooks/useEditSchoolGroup';

const SchoolGroupEditModal: React.FC = () => {
    const { closeModal, handleSubmit, isPosting, error, formState, handleChange, schoolGroup } =
        useEditSchoolGroup();

    if (!schoolGroup) return <Redirect to="/school-groups" />;
    return (
        <Modal title="Update School Group" size="small">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <TextInput
                    name="name"
                    label="name"
                    required
                    value={formState.name}
                    onChange={handleChange}
                />
            </Form>
        </Modal>
    );
};

export default SchoolGroupEditModal;
