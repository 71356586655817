import useCreateEditSchoolStep3 from '@pages/schools/shared/hooks/useCreateEditSchoolStep3';
import School from 'src/types/models/School';

const useSchool = (school: School) => {
    const { activities, activityGroups } = useCreateEditSchoolStep3();

    const activitiesInterestedInList: string[] = [];

    school.activitiesInterestedIn.forEach(activity => {
        const activityName = activities.find(item => item?.id === activity)?.name
            ? activities.find(item => item?.id === activity)?.name
            : null;
        const activityGroupId = activities.find(item => item?.id === activity)?.activityGroupID
            ? activities.find(item => item?.id === activity)?.activityGroupID
            : null;
        const activityGroup = Object.values(activityGroups).find(
            group => group.id === activityGroupId,
        )?.name;

        const activityFullName = `${activityGroup} > ${activityName}`;
        if (activityName && activities) {
            activitiesInterestedInList.push(activityFullName);
        }
    });

    const activitiesInvolvedInList: string[] = [];

    school.activitiesInvolvedIn.forEach(activity => {
        const activityName = activities.find(item => item?.id === activity)?.name
            ? activities.find(item => item?.id === activity)?.name
            : null;
        const activityGroupId = activities.find(item => item?.id === activity)?.activityGroupID
            ? activities.find(item => item?.id === activity)?.activityGroupID
            : null;
        const activityGroup = Object.values(activityGroups).find(
            group => group.id === activityGroupId,
        )?.name;

        const activityFullName = `${activityGroup} > ${activityName}`;
        if (activityName && activities) {
            activitiesInvolvedInList.push(activityFullName);
        }
    });

    return {
        activitiesInterestedInList,
        activitiesInvolvedInList,
    };
};

export default useSchool;
