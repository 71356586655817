import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { SchoolOption } from 'src/types/models/School';
import { APITableParams } from 'src/types/shared/table';

export const fetchSchoolOptionsRequest = createAction('fetchSchoolOptionsRequest');
export const fetchSchoolOptionsSuccess = createAction<SchoolOption[]>('fetchSchoolOptionsSuccess');
export const fetchSchoolOptionsFailure = createAction('fetchSchoolOptionsFailure');

export const fetchSchoolOptions =
    (params: APITableParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSchoolOptionsRequest());
        try {
            const { data } = await api.get<SchoolOption[]>('schools/options/filtered', params);

            dispatch(fetchSchoolOptionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSchoolOptionsFailure, e as APIError);
        }
    };
