import { RootState } from '@reducers/index';

export const getSearchedUsers = (state: RootState) => state.searchedUsersReducer.users;
export const getSearchedUsersItemCount = (state: RootState) => state.searchedUsersReducer.itemCount;
export const getSearchedUser = (state: RootState, id: number) =>
    state.searchedUsersReducer.users[id];
export const getSearchedUsersIsFetching = (state: RootState) =>
    state.searchedUsersReducer.isFetching;
export const getSearchedUsersError = (state: RootState) => state.searchedUsersReducer.error;

export const getSearchedUserIsPosting = (state: RootState) => state.searchedUsersReducer.isPosting;
export const getSearchedUserPostSuccess = (state: RootState) =>
    state.searchedUsersReducer.postSuccess;
