import React from 'react';
import { TableColumns } from 'src/types/shared/table';
import ButtonRow from '@components/button/ButtonRow';
import School from 'src/types/models/School';
import ActionButton from '@components/button/ActionButton';
import Table from '@components/table/Table';

interface Props {
    selectedSchools: School[];
    deselectSchool: (id: number) => void;
    openModal: () => void;
}

const SchoolsToMerge: React.FC<Props> = ({ selectedSchools, deselectSchool, openModal }) => {
    const columns = useColumns(deselectSchool);

    if (!selectedSchools.length) return null;

    return (
        <div className="fadein" style={{ width: '60%', paddingBottom: 40 }}>
            <Table noPagination columns={columns} rows={selectedSchools} />
            <div style={{ marginTop: 10 }}>
                <ButtonRow alignment="left">
                    <ActionButton
                        disabled={selectedSchools.length <= 1}
                        size="small"
                        source="primary"
                        onClick={openModal}
                    >
                        Merge
                    </ActionButton>
                </ButtonRow>
            </div>
        </div>
    );
};

const useColumns = (deselectSchool: (id: number) => void) => {
    const columns: TableColumns<School> = [
        {
            key: 2,
            heading: 'Schools To Merge',
            getValue: row => `${row.name} (${row.uniqueID})`,
        },
        {
            key: 8,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    <ActionButton source="negative" onClick={() => deselectSchool(row.id)}>
                        Remove
                    </ActionButton>
                </ButtonRow>
            ),
        },
    ];

    return columns;
};

export default SchoolsToMerge;
