import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import School from 'src/types/models/School';

export const fetchSchoolsRequest = createAction('fetchSchoolsRequest');
export const fetchSchoolsSuccess = createAction<FetchSchoolsResponse>('fetchSchoolsSuccess');
export const fetchSchoolsFailure = createAction('fetchSchoolsFailure');

export const fetchSchools =
    (params: Params) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSchoolsRequest());
        try {
            const { data } = await api.get<FetchSchoolsResponse>('schools', params);

            dispatch(fetchSchoolsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSchoolsFailure, e as APIError);
        }
    };

interface Params {
    schoolGroupIDs: number[];
}

export interface FetchSchoolsResponse {
    items: School[];
    totalItems: number;
}
