import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ActivityGroup } from '../../types/models/ActivityGroups';
import { convertArrToObj } from '@utils/generic';
import {
    createActivityGroupFailure,
    createActivityGroupRequest,
    createActivityGroupSuccess,
    deleteActivityGroupFailure,
    deleteActivityGroupRequest,
    deleteActivityGroupSuccess,
    fetchActivityGroupsFailure,
    fetchActivityGroupsRequest,
    fetchActivityGroupsSuccess,
    fetchSingleActivityGroupFailure,
    fetchSingleActivityGroupRequest,
    fetchSingleActivityGroupSuccess,
    fetchSingleActivityGroupByActivityIDFailure,
    fetchSingleActivityGroupByActivityIDRequest,
    fetchSingleActivityGroupByActivityIDSuccess,
    restoreActivityGroupFailure,
    restoreActivityGroupRequest,
    restoreActivityGroupSuccess,
    updateActivityGroupFailure,
    updateActivityGroupRequest,
    updateActivityGroupSuccess,
} from '@actions/activityGroups';

interface ActivityGroupState {
    isFetching: boolean;
    activityGroups: Record<number, ActivityGroup>;
    isPosting: boolean;
    postSuccess: boolean;
    fetchError: string | null;
    postError: string | null;
}

const initialState: ActivityGroupState = {
    isFetching: false,
    activityGroups: {},
    isPosting: false,
    postSuccess: false,
    fetchError: null,
    postError: null,
};

export default createReducer(initialState, {
    [fetchActivityGroupsRequest.type]: handleFetchRequest,
    [fetchActivityGroupsSuccess.type]: handleFetchSuccess,
    [fetchActivityGroupsFailure.type]: handleFetchFailure,
    [fetchSingleActivityGroupRequest.type]: handleFetchRequest,
    [fetchSingleActivityGroupSuccess.type]: handleFetchSingleSuccess,
    [fetchSingleActivityGroupFailure.type]: handleFetchFailure,
    [fetchSingleActivityGroupByActivityIDRequest.type]: handleFetchRequest,
    [fetchSingleActivityGroupByActivityIDSuccess.type]: handleFetchSingleSuccess,
    [fetchSingleActivityGroupByActivityIDFailure.type]: handleFetchFailure,
    [createActivityGroupRequest.type]: handlePostRequest,
    [createActivityGroupSuccess.type]: handlePostSuccess,
    [createActivityGroupFailure.type]: handlePostFailure,
    [updateActivityGroupRequest.type]: handlePostRequest,
    [updateActivityGroupSuccess.type]: handlePostSuccess,
    [updateActivityGroupFailure.type]: handlePostFailure,
    [deleteActivityGroupRequest.type]: handlePostRequest,
    [deleteActivityGroupSuccess.type]: handleDeleteSuccess,
    [deleteActivityGroupFailure.type]: handlePostFailure,
    [restoreActivityGroupRequest.type]: handlePostRequest,
    [restoreActivityGroupSuccess.type]: handlePostSuccess,
    [restoreActivityGroupFailure.type]: handlePostFailure,
});

function handleFetchRequest(state: ActivityGroupState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchSuccess(state: ActivityGroupState, action: PayloadAction<ActivityGroup[]>) {
    state.isFetching = false;
    state.activityGroups = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state: ActivityGroupState, action: PayloadAction<ActivityGroup>) {
    state.isFetching = false;
    state.activityGroups[action.payload.id] = action.payload;
}

function handlePostRequest(state: ActivityGroupState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostSuccess(state: ActivityGroupState, action: PayloadAction<ActivityGroup>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.activityGroups[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: ActivityGroupState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.activityGroups[action.payload];
}

function handleFetchFailure(state: ActivityGroupState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.fetchError = action.payload;
}

function handlePostFailure(state: ActivityGroupState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}
