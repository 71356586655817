import { MegeSchoolStepsProps } from './shared/MegeSchoolStepsProps';
import Select from '@components/form/Select';
import useSchoolMergeOptions from './hooks/useSchoolMergeOptions';
import { getCountryName } from '@utils/countries';

const SchoolsMergeStep1: React.FC<MegeSchoolStepsProps> = ({
    schools,
    formState,
    handleChange,
}) => {
    return (
        <>
            <Select
                required
                omitRemove
                label="Name"
                name="nameSourceID"
                value={formState.nameSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.name)}
            />
            <Select
                required
                omitRemove
                label="Website"
                name="websiteSourceID"
                value={formState.websiteSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.website)}
            />
            <Select
                required
                omitRemove
                label="Email"
                name="emailSourceID"
                value={formState.emailSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.email)}
            />
            <Select
                required
                omitRemove
                label="Country"
                name="countrySourceID"
                value={formState.countrySourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => getCountryName(s.country))}
            />
            <Select
                required
                omitRemove
                label="Address Line 1"
                name="addressLine1SourceID"
                value={formState.addressLine1SourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.addressLine1)}
            />
            <Select
                required
                omitRemove
                label="Address Line 2"
                name="addressLine2SourceID"
                value={formState.addressLine2SourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.addressLine2)}
            />
            <Select
                required
                omitRemove
                label="City"
                name="citySourceID"
                value={formState.citySourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.city)}
            />
            <Select
                required
                omitRemove
                label="Postcode"
                name="postcodeSourceID"
                value={formState.postcodeSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.postcode)}
            />
            <Select
                required
                omitRemove
                label="UK Region Source"
                name="ukRegionSourceID"
                value={formState.ukRegionSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.ukRegion)}
            />
            <Select
                required
                omitRemove
                label="School Area"
                name="schoolAreaSourceID"
                value={formState.schoolAreaSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.schoolArea)}
            />
        </>
    );
};

export default SchoolsMergeStep1;
