import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import Institution from 'src/types/models/Institution';

export const updateInstitutionRequest = createAction('updateInstitutionRequest');
export const updateInstitutionSuccess = createAction<Institution>('updateInstitutionSuccess');
export const updateInstitutionFailure = createAction('updateInstitutionFailure');

export const updateInstitution =
    (id: number, putBody: PutBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateInstitutionRequest());
        try {
            const { data } = await api.put<unknown, Institution>(`institutions/${id}`, putBody);

            dispatch(updateInstitutionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateInstitutionFailure, e as APIError);
        }
    };

type PutBody = Omit<Institution, 'isDeleted' | 'id' | 'createdOn' | 'country'> & {
    country: string;
};
