import SchoolGroups from '@pages/schoolGroups/schoolGroups/SchoolGroups';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DefaultRedirect from './DefaultRedirect';

const SchoolGroupsRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <SchoolGroups />
            </Route>
            <Route exact path={`${path}/create`}>
                <SchoolGroups showCreateModal />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <SchoolGroups showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <SchoolGroups showDeleteModal />
            </Route>
            <DefaultRedirect to={`${path}`} />
        </Switch>
    );
};

export default SchoolGroupsRoutes;
