import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import MultiSelect from '@components/form/MultiSelect';
import useApplyActivitiesToCcdbGroup from './_hooks/useApplyActivitiesToCcdbGroup';
import FormRow from '@components/form/FormRow';
import TextInput from '@components/form/TextInput';
import DatePicker from '@components/form/DatePicker';

const ApplyActivitiesToCcdbGroupModal = () => {
    const {
        form,
        handleChange,
        activityGroupOptions,
        activityOptions,
        handleSubmit,
        isPosting,
        closeModal,
        error,
        selectedActivityIDs,
        userOptions,
    } = useApplyActivitiesToCcdbGroup();

    return (
        <Modal title="Apply Activities To Users" style={{ overflow: 'unset' }}>
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <TextInput
                        required
                        label="Activity Location"
                        name="activityLocation"
                        value={form.activityLocation}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        required
                        label="Activity Date"
                        name="activityDate"
                        value={form.activityDate}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <MultiSelect
                        required
                        search
                        label="Users"
                        name="userIDs"
                        options={userOptions}
                        value={form.userIDs}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <MultiSelect
                        required
                        search
                        label="Activity Groups"
                        name="activityGroupIDs"
                        options={activityGroupOptions}
                        value={form.activityGroupIDs}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <MultiSelect
                        required
                        search
                        label="Activities"
                        name="activityIDs"
                        options={activityOptions}
                        value={selectedActivityIDs}
                        onChange={handleChange}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

export default ApplyActivitiesToCcdbGroupModal;
