import useAddUsersToCcdbGroup from '@pages/ccdbGroups/ccdbGroup/users/hooks/useAddUsersToCcdbGroup';

import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import MultiSelect from '@components/form/MultiSelect';

const AddUsersToCcdbGroupModal = () => {
    const {
        form,
        handleChange,
        userOptions,
        handleSubmit,
        isPosting,
        closeModal,
        error,
        onSearchTermChanged,
    } = useAddUsersToCcdbGroup();

    return (
        <Modal title="Add Users to CCDB Group" style={{ overflow: 'unset' }}>
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <MultiSelect
                    search
                    label="Users"
                    name="userIDs"
                    options={userOptions}
                    value={form.userIDs}
                    onChange={handleChange}
                    onSearchChange={onSearchTermChanged}
                />
            </Form>
        </Modal>
    );
};

export default AddUsersToCcdbGroupModal;
