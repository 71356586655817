import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from '@components/typography/Title';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';

import CcdbGroupUsers from '@pages/ccdbGroups/ccdbGroup/users/CcdbGroupUsers';
import CcdbGroupActivityHistories from '@pages/ccdbGroups/ccdbGroup/activityHistories/CcdbGroupActivityHistories';

import { CcdbGroup as ICcdbGroup } from '../../../types/models/Ccdb';

const CcdbGroup = ({ ccdbGroup }: Props) => {
    return (
        <>
            <CreateHeader>
                <Title>CCDB Group - {ccdbGroup.name}</Title>
                <ButtonRow>
                    <LinkButton source="secondary" href={`/ccdb-groups/${ccdbGroup.id}/edit`}>
                        Edit
                    </LinkButton>
                    <LinkButton source="negative" href={`/ccdb-groups/${ccdbGroup.id}/delete`}>
                        Delete
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{ccdbGroup?.name}</p>
                    </ContentItem>
                    <ContentItem label="Owner Admin Name">
                        <p>{ccdbGroup.ownerUserName || '-'}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
            <br />
            <CcdbGroupUsers />
            <br />
            <CcdbGroupActivityHistories />
        </>
    );
};

interface Props {
    ccdbGroup: ICcdbGroup;
}

export default CcdbGroup;
