import Form from '@components/form/Form';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';
import React from 'react';
import useCreateSchoolGroup from './hooks/useCreateSchoolGroup';

const SchoolGroupCreateModal: React.FC = () => {
    const { closeModal, handleSubmit, isPosting, error, formState, handleChange } =
        useCreateSchoolGroup();

    return (
        <Modal title="Update School Group" size="small">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <TextInput
                    name="name"
                    label="name"
                    required
                    value={formState.name}
                    onChange={handleChange}
                />
            </Form>
        </Modal>
    );
};

export default SchoolGroupCreateModal;
