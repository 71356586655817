import { postImportUsers } from './../../../../redux/actions/users/postImportUsers';
import useForm from '@hooks/useForm';
import usePrevious from '@hooks/usePrevious';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getUserImportErrors,
    getUserIsPosting,
    getUserPostSuccess,
    getUsersError,
} from '@selectors/users';
import { fetchSchoolOptions } from '@actions/schools';
import { APITableRef } from '@hooks/useApiFilterTableRef';
import { getFieldErrors, getFormError } from '@selectors/fieldErrors';

import Papa from 'papaparse';

const convertImportErrorsToCSV = (importErrors: string[]) => {
    const data = importErrors.map(x => ({
        error: x,
    }));

    const csvData = Papa.unparse(data, { quotes: true });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'import_errors.csv';
    a.click();
    window.URL.revokeObjectURL(url);
};

export default function useImportUsers(tableRef: APITableRef) {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const fieldError = useSelector(getFieldErrors)['File'];
    const formError = useSelector(getFormError);
    const importErrors = useSelector(getUserImportErrors);

    const [showDownloadErrors, setShowDownloadErrors] = useState(false);

    const [formState, handleChange] = useForm<FormState>({
        File: null,
    });

    const handleCancel = useCallback(() => {
        history.push('/users');
    }, [history]);

    const handleSubmit = useCallback(() => {
        if (showDownloadErrors) {
            convertImportErrorsToCSV(importErrors);
        } else if (showConfirmation) {
            dispatch(postImportUsers(formState.File as File, !!formError));
        } else {
            setShowConfirmation(true);
        }
    }, [dispatch, formState, showConfirmation, formError, showDownloadErrors, importErrors]);

    const isPosting = useSelector(getUserIsPosting);
    const error = useSelector(getUsersError);
    const postSuccess = useSelector(getUserPostSuccess);

    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            tableRef.current?.refreshData();

            if (importErrors && importErrors.length) {
                setShowDownloadErrors(true);
            } else {
                history.push('/users');
            }
        }
    }, [dispatch, postSuccess, prevPostSuccess, tableRef, history, importErrors]);

    useEffect(() => {
        dispatch(fetchSchoolOptions({ page: null, limit: null, searchTerm: '' }));
    }, [dispatch]);

    const prevFieldError = usePrevious(fieldError);
    useEffect(() => {
        if (prevFieldError !== fieldError && fieldError) {
            setShowConfirmation(false);
        }
    }, [prevFieldError, fieldError]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
        handleCancel,
        showConfirmation,
        fieldError,
        formError,
        showDownloadErrors,
    };
}

interface FormState {
    File: File | null;
}
