import { DropdownOption } from 'src/types/shared/DropdownOption';
import {
    DisabilityReported,
    disabilityReportedFriendlyNames,
    Ethnicity,
    ethnicityFriendlyNames,
    UserGender,
    userGenderFriendlyNames,
    UserSchoolAssociation,
    userSchoolAssociationFriendlyNames,
} from './../../../../types/models/User';
import useForm from '@hooks/useForm';
import usePrevious from '@hooks/usePrevious';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createUser } from '@actions/users/postCreateUser';
import { getUserIsPosting, getUserPostSuccess, getUsersError } from '@selectors/users';
import { getSchoolOptions } from '@selectors/schools';
import { fetchSchoolOptions } from '@actions/schools';
import { fetchAllCcdbGroups } from '@actions/ccdb';
import { selectCcdbGroups } from '@selectors/ccdbGroups';
import { Nullable } from 'src/types/shared/helpers';
import { fetchInstitutionTypes } from '@actions/institutionTypes';
import { getInstitutionTypes } from '@selectors/institutionTypes';
import { fetchInstitutions } from '@actions/institutions';
import { getInstitutions } from '@selectors/institutions';

const schoolAssociationOptions: DropdownOption<UserSchoolAssociation>[] = Object.entries(
    userSchoolAssociationFriendlyNames,
).map(([value, label]) => ({ value: value as UserSchoolAssociation, label }));

const schoolPositionOptions: DropdownOption<string>[] = [
    { value: 'Class Teacher', label: 'Class Teacher' },
    { value: 'Teaching Assisstant', label: 'Teaching Assisstant' },
    { value: 'Head Teacher', label: 'Head Teacher' },
    { value: 'Deputy Head Teacher', label: 'Deputy Head Teacher' },
    { value: 'Assisstand Head Teacher', label: 'Assisstand Head Teacher' },
    { value: 'Head Of Year', label: 'Head Of Year' },
    { value: 'Policy Maker', label: 'Policy Maker' },
    { value: 'Other', label: 'Other' },
];

const userGenderOptions: DropdownOption<UserGender>[] = Object.entries(userGenderFriendlyNames).map(
    ([value, label]) => ({ value: value as UserGender, label }),
);

const ethnicityOptions: DropdownOption<Ethnicity>[] = Object.entries(ethnicityFriendlyNames).map(
    ([value, label]) => ({ value: value as Ethnicity, label }),
);

const disabilityReportedOptions: DropdownOption<DisabilityReported>[] = Object.entries(
    disabilityReportedFriendlyNames,
).map(([value, label]) => ({ value: value as DisabilityReported, label }));

export default function useCreateUser() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formState, handleChange] = useForm(initialState);

    const closeModal = useCallback(() => {
        history.push('/users');
    }, [history]);

    const handleSubmit = useCallback(() => {
        const { schoolPositionDropdown, schoolPosition, ...rest } = formState;

        const postBody = {
            ...rest,
            schoolPosition:
                schoolPositionDropdown === 'Other' ? schoolPosition : schoolPositionDropdown,
        };
        dispatch(createUser(postBody));
    }, [dispatch, formState]);

    const isPosting = useSelector(getUserIsPosting);
    const error = useSelector(getUsersError);
    const postSuccess = useSelector(getUserPostSuccess);
    const schoolOpts = useSelector(getSchoolOptions);
    const institutionTypes = useSelector(getInstitutionTypes);
    const institutions = useSelector(getInstitutions);
    const prevPostSuccess = usePrevious(postSuccess);

    const [searchTerm, setSearchTerm] = useState('');
    const prevSearchTerm = usePrevious(searchTerm);
    const searchTimeout = useRef<Nullable<number>>(null);

    const onSearchTermChanged = useCallback(
        (newSearchTerm: string) => {
            setSearchTerm(newSearchTerm);
            if (searchTerm !== prevSearchTerm) {
                window.clearTimeout(searchTimeout.current || 0);
                searchTimeout.current = window.setTimeout(() => {
                    dispatch(
                        fetchSchoolOptions({ page: null, limit: null, searchTerm: newSearchTerm }),
                    );
                }, 1000);
            }
        },
        [dispatch, prevSearchTerm, searchTerm],
    );

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    const prevInsitutionTypeID = usePrevious(formState.institutionTypeID);
    useEffect(() => {
        handleChange('institutionID', null);
    }, [formState.institutionTypeID, prevInsitutionTypeID, dispatch, handleChange]);

    useEffect(() => {
        batch(() => {
            dispatch(fetchSchoolOptions({ page: null, limit: null, searchTerm: ' ' }));
            dispatch(fetchAllCcdbGroups());
            dispatch(fetchInstitutionTypes({ page: 1, limit: 10000, searchTerm: null }));

            dispatch(
                fetchInstitutions({
                    page: 1,
                    pageSize: 10000,
                    institutionTypeIDs: [],
                    searchTerm: null,
                }),
            );
        });
    }, [dispatch]);

    const schoolOptions = useMemo(
        () => schoolOpts.map(({ id, name }) => ({ label: name, value: id })),
        [schoolOpts],
    );

    const institutionTypeOptions = useMemo(() => {
        return institutionTypes
            .map(it => ({ label: it.name, value: it.id }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [institutionTypes]);

    // To do remove and replace with paginated select box
    const institutionOptions = useMemo(() => {
        if (!formState.institutionTypeID) return [];

        return institutions
            .filter(i => i.institutionTypeID === formState.institutionTypeID)
            .map(it => ({ label: `${it.name} (${it.country})`, value: it.id }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [institutions, formState.institutionTypeID]);

    const ccdbGroups = useSelector(selectCcdbGroups);

    const ccdbGroupOptions = useMemo(
        () =>
            Object.values(ccdbGroups).map(({ id, name }) => ({
                label: name,
                value: name,
            })),
        [ccdbGroups],
    );

    return {
        ccdbGroupOptions,
        closeModal,
        disabilityReportedOptions,
        error,
        ethnicityOptions,
        formState,
        handleChange,
        handleSubmit,
        institutionOptions,
        isPosting,
        onSearchTermChanged,
        schoolAssociationOptions,
        schoolOptions,
        schoolPositionOptions,
        userGenderOptions,
        institutionTypeOptions,
    };
}

const initialState: FormState = {
    agreedTo3rdPartyResearchDataSharing: false,
    agreedTo3rdPartySchoolSupportDataSharing: false,
    ccdbGroupNames: [],
    consentedToPersonalDataCollection: false,
    country: '',
    disabilityReported: null,
    email: '',
    ethnicity: null,
    firstName: '',
    gender: null,
    institutionID: null,
    lastName: '',
    phoneNumber: '',
    schoolAssociation: null,
    schoolEmail: '',
    schoolID: null,
    schoolPosition: '',
    schoolPositionDropdown: null,
    signedUpForBritishCouncilMail: false,
    signedUpForSchoolsOnlineNews: false,
    title: '',
    institutionTypeID: null,
};

export interface FormState {
    agreedTo3rdPartyResearchDataSharing: boolean;
    agreedTo3rdPartySchoolSupportDataSharing: boolean;
    ccdbGroupNames: string[];
    consentedToPersonalDataCollection: boolean;
    country: string;
    disabilityReported: Nullable<DisabilityReported>;
    email: string;
    ethnicity: Nullable<Ethnicity>;
    firstName: string;
    gender: Nullable<UserGender>;
    institutionTypeID: Nullable<number>;
    institutionID: Nullable<number>;
    lastName: string;
    phoneNumber: string;
    schoolAssociation: Nullable<UserSchoolAssociation>;
    schoolEmail: string;
    schoolID: Nullable<number>;
    schoolPosition: string;
    schoolPositionDropdown: Nullable<string>;
    signedUpForBritishCouncilMail: boolean;
    signedUpForSchoolsOnlineNews: boolean;
    title: string;
}
