import { RootState } from '@reducers/index';

export const getUsers = (state: RootState) => state.usersReducer.users;
export const getUsersItemCount = (state: RootState) => state.usersReducer.itemCount;
export const getUser = (state: RootState, id: number) => state.usersReducer.users[id];
export const getUsersIsFetching = (state: RootState) => state.usersReducer.isFetching;
export const getUsersError = (state: RootState) => state.usersReducer.error;

export const getUserIsPosting = (state: RootState) => state.usersReducer.isPosting;
export const getUserPostSuccess = (state: RootState) => state.usersReducer.postSuccess;
export const getUserImportErrors = (state: RootState) => state.usersReducer.importUsersErrors;
