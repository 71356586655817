import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import ActivityContainer from '@pages/activities/activity/ActivityContainer';

const ActivitiesRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/:id`}>
                <ActivityContainer />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <ActivityContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <ActivityContainer showDeleteModal />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default ActivitiesRoutes;
