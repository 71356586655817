import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import School, {
    SchoolGender,
    SchoolSENProvisioning,
    SchoolStatus,
    SchoolType,
} from '../../../types/models/School';

export const validateSchoolStep2Request = createAction('validateSchoolStep2Request');
export const validateSchoolStep2Success = createAction<School>('validateSchoolStep2Success');
export const validateSchoolStep2Failure = createAction('validateSchoolStep2Failure');

export const validateSchoolStep2 =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(validateSchoolStep2Request());
        try {
            const { data } = await api.post<PostBody, School>(
                `schools/Validate/AdditionalSchoolDetails`,
                postbody,
            );

            dispatch(validateSchoolStep2Success(data));
        } catch (e) {
            handleApiErrors(dispatch, validateSchoolStep2Failure, e as APIError);
        }
    };

interface PostBody {
    schoolPhotographS3Key: string | null;
    headTeacher: string | null;
    schoolGroup: string | null;
    schoolTypes: SchoolType[];
    schoolGender: SchoolGender | null;
    provisionsForSpecialEducationalNeeds: SchoolSENProvisioning | null;
    schoolStatus: SchoolStatus | null;
    facebookID: string | null;
    twitterID: string | null;
    hasVideoConferencing: boolean | null;
    hasSMS: boolean | null;
    hasIT: boolean | null;
    hasEmail: boolean | null;
    internationalCoordinator: string | null;
}
