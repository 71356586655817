import DataCheck from '@components/common/DataCheck';
import Institution from './Institution';
import useFetchInstitution from './hooks/useFetchInstitution';
import InstitutionEditModal from './InstitutionEditModal';
import InstitutionDeleteModal from './InstitutionDeleteModal';

interface Props {
    showEditModal?: boolean;
    showDeleteModal?: boolean;
}

const InstitutionContainer: React.FC<Props> = ({
    showEditModal = false,
    showDeleteModal = false,
}) => {
    const { institution, isFetching, fetchError } = useFetchInstitution();

    return (
        <>
            <DataCheck dataExists={!!institution} isFetching={isFetching} error={fetchError}>
                <Institution institution={institution} />
            </DataCheck>
            {!!institution && showEditModal && <InstitutionEditModal institution={institution} />}
            {showDeleteModal && <InstitutionDeleteModal />}
        </>
    );
};

export default InstitutionContainer;
