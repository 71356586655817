import useDeleteUserActivity from '@pages/users/user/hooks/useDeleteUserActivity';

import ConfirmModal from '@components/modal/ConfirmModal';

const DeleteUserActivityModal = () => {
    const { handleSubmit, closeModal, isPosting, error } = useDeleteUserActivity();

    return (
        <ConfirmModal
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            title="Delete Activity"
            description="Are you sure you want to delete this activity?"
            isPosting={isPosting}
            error={error}
        />
    );
};

export default DeleteUserActivityModal;
