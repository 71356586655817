import ConfirmModal from '@components/modal/ConfirmModal';
import useDeleteActivityGroup from './hooks/useDeleteActivityGroup';

const DeleteActivityGroupModal = () => {
    const { isPosting, handleSubmit, closeModal, error } = useDeleteActivityGroup();

    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete Activity Group"
            description="Are you sure you want to delete this activity group?"
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            error={error}
        />
    );
};

export default DeleteActivityGroupModal;
