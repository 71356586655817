import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { Region } from 'src/types/models/Regions';
import { APIError } from 'src/types/shared/APIError';

export const fetchRegionsRequest = createAction('fetchRegionsRequest');
export const fetchRegionsSuccess = createAction<Region[]>('fetchRegionsSuccess');
export const fetchRegionsFailure = createAction('fetchRegionsFailure');

export const fetchRegions = () => async (dispatch: AppDispatch) => {
    dispatch(fetchRegionsRequest());

    try {
        const { data }: { data: Region[] } = await api.get('schools/options/uk-regions');

        dispatch(fetchRegionsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchRegionsFailure, e as APIError);
    }
};
