import {
    fetchParticipantDataFailure,
    fetchParticipantDataRequest,
    fetchParticipantDataSuccess,
} from '@actions/participant/fetchParticipantData';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { ParticipantData } from 'src/types/models/ParticipantData';

interface ParticipantDataState {
    participantData: any;
    isFetching: boolean;
    error: string | null;
    pageCount: number | null;
    pageSize: number | null;
}

const initialState: ParticipantDataState = {
    participantData: [],
    isFetching: false,
    error: null,
    pageCount: null,
    pageSize: null,
};

const participantDataReducer = createReducer(initialState, {
    [fetchParticipantDataRequest.type]: handleFetchRequest,
    [fetchParticipantDataSuccess.type]: handleFetchSuccess,
    [fetchParticipantDataFailure.type]: handleFetchFailure,
});

function handleFetchRequest(state: ParticipantDataState) {
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(
    state: ParticipantDataState,
    action: PayloadAction<{ results: ParticipantData[]; pageCount: number; pageSize: number }>,
) {
    state.isFetching = false;
    state.participantData = action.payload.results;
    state.pageCount = action.payload.pageCount;
    state.pageSize = action.payload.pageSize;
}

function handleFetchFailure(state: ParticipantDataState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

export default participantDataReducer;
