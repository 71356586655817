import { getSchoolGroups } from './../../../../redux/selectors/schoolGroups';
import { useDispatch, useSelector } from 'react-redux';

import { SchoolCreateEditFormState } from '../types/SchoolCreateEditFormStep';
import { validateSchoolStep2 } from '@actions/schools';
import {
    SchoolGender,
    SchoolSENProvisioning,
    SchoolStatus,
    SchoolType,
} from './../../../../types/models/School';
import { convertEnumToDropdownOption } from '@utils/enums';
import { useMemo } from 'react';

const typeOptions = convertEnumToDropdownOption(SchoolType);
const genderOptions = convertEnumToDropdownOption(SchoolGender);
const provisionsForSpecialEducationalNeedOptions =
    convertEnumToDropdownOption(SchoolSENProvisioning);
const statusOptions = convertEnumToDropdownOption(SchoolStatus);

const useCreateEditSchoolStep2 = () => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: SchoolCreateEditFormState) => {
        const {
            schoolPhotographS3Key,
            headTeacher,
            schoolGroup,
            schoolTypes,
            schoolGender,
            provisionsForSpecialEducationalNeeds,
            schoolStatus,
            facebookID,
            twitterID,
            hasVideoConferencing,
            hasSMS,
            hasIT,
            hasEmail,
            internationalCoordinator,
        } = formState;

        const postBody = {
            schoolPhotographS3Key,
            headTeacher,
            schoolGroup,
            schoolTypes,
            schoolGender,
            provisionsForSpecialEducationalNeeds,
            schoolStatus,
            facebookID,
            twitterID,
            hasVideoConferencing,
            hasSMS,
            hasIT,
            hasEmail,
            internationalCoordinator,
        };
        dispatch(validateSchoolStep2(postBody));
    };

    const schoolGroups = useSelector(getSchoolGroups);

    const schoolGroupOptions = useMemo(() => {
        return schoolGroups.map(sg => ({ label: sg.name, value: sg.name }));
    }, [schoolGroups]);

    return {
        handleSubmit,
        schoolGroupOptions,
        typeOptions,
        genderOptions,
        provisionsForSpecialEducationalNeedOptions,
        statusOptions,
    };
};

export default useCreateEditSchoolStep2;
