import {
    addUsersToCcdbGroupFailure,
    addUsersToCcdbGroupRequest,
    addUsersToCcdbGroupSuccess,
    createCcdbGroupFailure,
    createCcdbGroupRequest,
    createCcdbGroupSuccess,
    deleteCcdbGroupFailure,
    deleteCcdbGroupRequest,
    deleteCcdbGroupSuccess,
    fetchAllCcdbGroupsFailure,
    fetchAllCcdbGroupsRequest,
    fetchAllCcdbGroupsSuccess,
    fetchSingleCcdbGroupFailure,
    fetchSingleCcdbGroupRequest,
    fetchSingleCcdbGroupSuccess,
    removeUsersFromCcdbGroupFailure,
    removeUsersFromCcdbGroupRequest,
    removeUsersFromCcdbGroupSuccess,
    restoreCcdbGroupFailure,
    restoreCcdbGroupRequest,
    restoreCcdbGroupSuccess,
    updateCcdbGroupFailure,
    updateCcdbGroupRequest,
    updateCcdbGroupSuccess,
} from '@actions/ccdb';

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';

import { CcdbGroup } from '../../types/models/Ccdb';

interface CcdbGroupState {
    isFetching: boolean;
    fetchError: string | null;
    ccdbGroups: Record<number, CcdbGroup>;
    isPosting: boolean;
    postSuccess: boolean;
    postError: string | null;
}

const initialState: CcdbGroupState = {
    isFetching: false,
    fetchError: null,
    ccdbGroups: {},
    isPosting: false,
    postSuccess: false,
    postError: null,
};

export default createReducer(initialState, {
    [fetchAllCcdbGroupsRequest.type]: handleFetchRequest,
    [fetchAllCcdbGroupsSuccess.type]: handleFetchSuccess,
    [fetchAllCcdbGroupsFailure.type]: handleFetchFailure,
    [fetchSingleCcdbGroupRequest.type]: handleFetchRequest,
    [fetchSingleCcdbGroupSuccess.type]: handleFetchSingleSuccess,
    [fetchSingleCcdbGroupFailure.type]: handleFetchFailure,
    [createCcdbGroupRequest.type]: handlePostRequest,
    [createCcdbGroupSuccess.type]: handlePostSuccess,
    [createCcdbGroupFailure.type]: handlePostFailure,
    [updateCcdbGroupRequest.type]: handlePostRequest,
    [updateCcdbGroupSuccess.type]: handlePostSuccess,
    [updateCcdbGroupFailure.type]: handlePostFailure,
    [deleteCcdbGroupRequest.type]: handlePostRequest,
    [deleteCcdbGroupSuccess.type]: handleDeleteSuccess,
    [deleteCcdbGroupFailure.type]: handlePostFailure,
    [restoreCcdbGroupRequest.type]: handlePostRequest,
    [restoreCcdbGroupSuccess.type]: handlePostSuccess,
    [restoreCcdbGroupFailure.type]: handlePostFailure,
    [addUsersToCcdbGroupRequest.type]: handlePostRequest,
    [addUsersToCcdbGroupSuccess.type]: handlePostSuccessBase,
    [addUsersToCcdbGroupFailure.type]: handlePostFailure,
    [removeUsersFromCcdbGroupRequest.type]: handlePostRequest,
    [removeUsersFromCcdbGroupSuccess.type]: handlePostSuccessBase,
    [removeUsersFromCcdbGroupFailure.type]: handlePostFailure,
});

function handleFetchRequest(state: CcdbGroupState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchSuccess(state: CcdbGroupState, action: PayloadAction<CcdbGroup[]>) {
    state.isFetching = false;
    state.ccdbGroups = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state: CcdbGroupState, action: PayloadAction<CcdbGroup>) {
    state.isFetching = false;
    state.ccdbGroups[action.payload.id] = action.payload;
}

function handleFetchFailure(state: CcdbGroupState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handlePostRequest(state: CcdbGroupState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostSuccessBase(state: CcdbGroupState, action: PayloadAction<CcdbGroup>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.fetchError = null;
}

function handlePostSuccess(state: CcdbGroupState, action: PayloadAction<CcdbGroup>) {
    handlePostSuccessBase(state, action);
    state.ccdbGroups[action.payload.id] = action.payload;
}

function handlePostFailure(state: CcdbGroupState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleDeleteSuccess(state: CcdbGroupState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.ccdbGroups[action.payload];
}
