import { useEffect, useMemo, useState } from 'react';
import School from 'src/types/models/School';
import SchoolsMergeSelectSource from '../SchoolsMergeSelectSource';
import SchoolsMergeStep1 from '../SchoolsMergeStep1';
import useForm from '@hooks/useForm';
import { MegeSchoolStepsProps, MergeSchoolsFormState } from '../shared/MegeSchoolStepsProps';
import SchoolsMergeStep2 from '../SchoolsMergeStep2';
import SchoolsMergeStep3 from '../SchoolsMergeStep3';
import SchoolsMergeStep4 from '../SchoolsMergeStep4';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivityGroups } from '@actions/activityGroups';
import { fetchActivities } from '@actions/activities';
import { mergeSchools } from '@actions/schools';
import {
    getSchoolsIsPosting,
    getSchoolsPostError,
    getSchoolsPostSuccess,
} from '@selectors/schools';
import usePrevious from '@hooks/usePrevious';

const mergeSchoolsSteps: React.FC<MegeSchoolStepsProps>[] = [
    SchoolsMergeSelectSource,
    SchoolsMergeStep1,
    SchoolsMergeStep2,
    SchoolsMergeStep3,
    SchoolsMergeStep4,
];

const useMergeSchools = (schoolsToMerge: School[], handleSuccess: () => void) => {
    const dispatch = useDispatch();

    const isPosting = useSelector(getSchoolsIsPosting);
    const error = useSelector(getSchoolsPostError);
    const success = useSelector(getSchoolsPostSuccess);

    const [sourceSchoolID, setSourceSchoolID] = useState(schoolsToMerge[0].id);
    const [mergeSchoolsStepIndex, setMergeSchoolsStepIndex] = useState(0);

    const [formState, handleChange] = useForm(getInitialFormState(sourceSchoolID));

    const isLastStep = mergeSchoolsStepIndex === mergeSchoolsSteps.length - 1;

    const handlePrev = () => {
        setMergeSchoolsStepIndex(prev => Math.max(prev - 1, 0));
    };

    const handleSubmit = () => {
        if (isLastStep) {
            const duplicateSchoolIDs = schoolsToMerge
                .filter(s => s.id !== sourceSchoolID)
                .map(s => s.id);

            const postBody = { ...formState, sourceSchoolID, duplicateSchoolIDs };
            dispatch(mergeSchools(postBody));
        } else {
            setMergeSchoolsStepIndex(prev => Math.min(prev + 1, mergeSchoolsSteps.length));
        }
    };

    const schools = useMemo(() => {
        const sortedSchools = schoolsToMerge.reduce((acc: School[], school) => {
            if (school.id === sourceSchoolID) return [school, ...acc];
            return [...acc, school];
        }, []);

        return sortedSchools;
    }, [schoolsToMerge, sourceSchoolID]);

    useEffect(() => {
        dispatch(fetchActivityGroups());
        dispatch(fetchActivities());
    }, [dispatch]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            handleSuccess();
        }
    }, [prevSuccess, success, handleSuccess]);

    return {
        sourceSchoolID,
        setSourceSchoolID,
        MergeSchoolsStep: mergeSchoolsSteps[mergeSchoolsStepIndex],
        isFirstStep: mergeSchoolsStepIndex === 0,
        setMergeSchoolsStepIndex,
        formState,
        handleChange,
        handlePrev,
        handleSubmit,
        isLastStep,
        schools,
        isPosting,
        error,
    };
};

const getInitialFormState = (sourceID: number) => {
    const formState: MergeSchoolsFormState = {
        nameSourceID: sourceID,
        websiteSourceID: sourceID,
        emailSourceID: sourceID,
        countrySourceID: sourceID,
        addressLine1SourceID: sourceID,
        addressLine2SourceID: sourceID,
        citySourceID: sourceID,
        postcodeSourceID: sourceID,
        schoolAreaSourceID: sourceID,
        ukRegionSourceID: sourceID,

        schoolPhotographS3KeySourceID: sourceID,
        headTeacherSourceID: sourceID,
        schoolGroupSourceID: sourceID,
        schoolTypesSourceID: sourceID,
        schoolGenderSourceID: sourceID,
        provisionsForSpecialEducationalNeedsSourceID: sourceID,
        schoolStatusSourceID: sourceID,
        numberOfPupilsSourceID: sourceID,
        facebookIDSourceID: sourceID,
        twitterIDSourceID: sourceID,
        hasVideoConferencingSourceID: sourceID,
        hasSMSSourceID: sourceID,
        hasITSourceID: sourceID,
        hasEmailSourceID: sourceID,
        internationalCoordinatorSourceID: sourceID,

        wholeSchoolPartnershipPreferencesSourceID: sourceID,
        partnerSchoolInterestLocationsSourceID: sourceID,
        currentPartnerSchoolLocationsSourceID: sourceID,
        activitiesInterestedInSourceID: sourceID,
        activitiesInvolvedInSourceID: sourceID,
        partnersFoundViaSchoolsOnlineSourceID: sourceID,
        schoolsOnlineProvidedUsefulInformationSourceID: sourceID,
        partnerSchoolSuggestedSourceID: sourceID,
        interestedInInternationalSchoolAwardSourceID: sourceID,

        sourceSourceID: sourceID,
    };
    return formState;
};

export default useMergeSchools;
