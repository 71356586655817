import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { ParticipantData, ParticipantDataSearchQueries } from 'src/types/models/ParticipantData';
import { APIError } from 'src/types/shared/APIError';

export const fetchParticipantDataRequest = createAction('fetchParticipantDataRequest');
export const fetchParticipantDataSuccess = createAction<{
    results: ParticipantData[];
    pageCount: number;
    pageSize: number;
}>('fetchParticipantDataSuccess');
export const fetchParticipantDataFailure = createAction('fetchParticipantDataFailure');

export const fetchParticipantData =
    (queries: ParticipantDataSearchQueries) => async (dispatch: any) => {
        dispatch(fetchParticipantDataRequest());

        try {
            const { data } = await api.get<{
                results: ParticipantData[];
                pageCount: number;
                pageSize: number;
            }>(`participant`, queries);

            dispatch(fetchParticipantDataSuccess(data));
        } catch (error) {
            handleApiErrors(dispatch, fetchParticipantDataFailure, error as APIError);
        }
    };
