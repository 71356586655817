import ConfirmModal from '@components/modal/ConfirmModal';
import { Redirect } from 'react-router-dom';
import useDeleteSchoolGroup from './hooks/useDeleteSchoolGroup';

const SchoolGroupDeleteModal = () => {
    const { handleSubmit, closeModal, isPosting, error, schoolGroup } = useDeleteSchoolGroup();

    if (!schoolGroup) return <Redirect to="/school-groups" />;
    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete school"
            description="Are you sure you want to delete this school group?"
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            error={error}
        />
    );
};

export default SchoolGroupDeleteModal;
