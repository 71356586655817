import Form from '@components/form/Form';
import Modal from '@components/modal/Modal';
import useMergeSchools from './hooks/useMergeSchools';
import School from 'src/types/models/School';
import ButtonRow from '@components/button/ButtonRow';
import ActionButton from '@components/button/ActionButton';

interface Props {
    closeModal: () => void;
    handleSuccess: () => void;
    schoolsToMerge: School[];
}

const SchoolsMergeModal: React.FC<Props> = ({ closeModal, schoolsToMerge, handleSuccess }) => {
    const {
        sourceSchoolID,
        setSourceSchoolID,
        MergeSchoolsStep,
        formState,
        handleChange,
        isFirstStep,
        handlePrev,
        handleSubmit,
        isLastStep,
        isPosting,
        error,
    } = useMergeSchools(schoolsToMerge, handleSuccess);

    return (
        <Modal title="Merge Schools" size="small">
            <Form
                omitButtons
                onSubmit={() => {}}
                onCancel={closeModal}
                isPosting={isPosting}
                error={error}
            >
                <MergeSchoolsStep
                    sourceSchoolID={sourceSchoolID}
                    setSourceSchoolID={setSourceSchoolID}
                    formState={formState}
                    handleChange={handleChange}
                    schools={schoolsToMerge}
                />

                <ButtonRow alignment="right">
                    <ActionButton source="secondary" onClick={closeModal}>
                        Cancel
                    </ActionButton>
                    {!isFirstStep && (
                        <ActionButton disabled={isPosting} source="negative" onClick={handlePrev}>
                            Prev
                        </ActionButton>
                    )}
                    <ActionButton
                        disabled={isPosting}
                        isPosting={isPosting}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        {isLastStep ? 'Submit' : 'Next'}
                    </ActionButton>
                </ButtonRow>
            </Form>
        </Modal>
    );
};

export default SchoolsMergeModal;
