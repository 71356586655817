import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const fetchActivitiesForGroupRequest = createAction('fetchActivitiesForGroupRequest');
export const fetchActivitiesForGroupSuccess = createAction<Activity>(
    'fetchActivitiesForGroupSuccess',
);
export const fetchActivitiesForGroupFailure = createAction('fetchActivitiesForGroupFailure');

export const fetchActivitiesForGroup = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchActivitiesForGroupRequest());

    try {
        const { data } = await api.get<Activity>(`activities/activitygroup/${id}`);

        dispatch(fetchActivitiesForGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchActivitiesForGroupFailure, e as APIError);
    }
};
