import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import User, { UserSchoolAssociation } from 'src/types/models/User';

export const updateUserRequest = createAction('updateUserRequest');
export const updateUserSuccess = createAction<User>('updateUserSuccess');
export const updateUserFailure = createAction('updateUserFailure');

export const updateUser =
    (id: number, postbody: EditUserRequestModel) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateUserRequest());

        try {
            const { data } = await api.put<EditUserRequestModel, User>(`users/${id}`, postbody);

            dispatch(updateUserSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateUserFailure, e as APIError);
        }
    };

export interface EditUserRequestModel {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    country: string;
    schoolAssociation: UserSchoolAssociation | null;
    schoolID: number | null;
    ccdbGroupNames: string[];
    schoolEmail: string;
    schoolPosition: string | null;
    consentedToPersonalDataCollection: boolean;
    signedUpForSchoolsOnlineNews: boolean;
    signedUpForBritishCouncilMail: boolean;
    agreedTo3rdPartyResearchDataSharing: boolean;
    agreedTo3rdPartySchoolSupportDataSharing: boolean;
}
