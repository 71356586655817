import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { CcdbGroup } from '../../../types/models/Ccdb';

export const createCcdbGroupRequest = createAction('createCcdbGroupRequest');
export const createCcdbGroupSuccess = createAction<CcdbGroup>('createCcdbGroupSuccess');
export const createCcdbGroupFailure = createAction('createCcdbGroupFailure');

export const createCcdbGroup = (postBody: PostBody) => async (dispatch: AppDispatch) => {
    dispatch(createCcdbGroupRequest());

    try {
        const { data } = await api.post<PostBody, CcdbGroup>('CCDBGroups', postBody);

        dispatch(createCcdbGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createCcdbGroupFailure, e as APIError);
    }
};

interface PostBody {
    name: string;
    ownerAdminUserID: number | null;
}
