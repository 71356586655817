import { useAppSelector } from '../../../../redux/store';
import {
    getInstitutionTypesIsFetching,
    getInstitutionTypesFetchError,
    getInstitutionTypes,
    getInstitutionTypesItemCount,
} from '@selectors/institutionTypes';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApiFilterTableRef from '@hooks/useApiFilterTableRef';
import { fetchInstitutionTypes } from '@actions/institutionTypes';

const useFetchInstitutionTypes = () => {
    const dispatch = useDispatch();

    const tableRef = useApiFilterTableRef();

    const isFetchingInstitutions = useSelector(getInstitutionTypesIsFetching);
    const fetchInstitutionsError = useSelector(getInstitutionTypesFetchError);
    const institutionTypes = useAppSelector(getInstitutionTypes);
    const itemCount = useSelector(getInstitutionTypesItemCount);

    const handleFetch = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            const params = { page, limit: pageSize, searchTerm };
            dispatch(fetchInstitutionTypes(params));
        },
        [dispatch],
    );

    useEffect(() => {
        tableRef.current?.refreshData();
    }, [tableRef, dispatch]);

    return {
        isFetching: isFetchingInstitutions,
        fetchError: fetchInstitutionsError,
        institutionTypes,
        handleFetch,
        tableRef,
        itemCount,
    };
};

export default useFetchInstitutionTypes;
