import useRedirect from '@hooks/useRedirect';
import useForm from '@hooks/useForm';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createActivity } from '@actions/activities';
import { useEffect } from 'react';
import {
    selectActivitiesIsPosting,
    selectActivitiesPostError,
    selectActivitiesPostSuccess,
} from '@selectors/activities';
import usePrevious from '@hooks/usePrevious';

const useCreateActivity = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const [form, handleChange] = useForm({
        name: '',
        activityCode: '',
    });

    const isPosting = useSelector(selectActivitiesIsPosting);
    const postSuccess = useSelector(selectActivitiesPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const postError = useSelector(selectActivitiesPostError);

    const handleSubmit = () => {
        const postBody = {
            name: form.name,
            activityGroupID: +id,
            activityCode: form.activityCode || null,
        };

        dispatch(createActivity(postBody));
    };

    const closeModal = useRedirect(`/activity-groups/${id}`);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return { form, handleChange, handleSubmit, isPosting, postError, closeModal };
};

export default useCreateActivity;
