import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteInstitutionRequest = createAction('deleteInstitutionRequest');
export const deleteInstitutionSuccess = createAction<number>('deleteInstitutionSuccess');
export const deleteInstitutionFailure = createAction('deleteInstitutionFailure');

export const deleteInstitution =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteInstitutionRequest());

        try {
            await api.delete(`institutions/${id}`);
            dispatch(deleteInstitutionSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteInstitutionFailure, e as APIError);
        }
    };
