import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    deleteUserFailure,
    deleteUserRequest,
    deleteUserSuccess,
    postImportUsersFailure,
    postImportUsersRequest,
    postImportUsersSuccess,
    fetchAllUsersFailure,
    fetchAllUsersRequest,
    fetchAllUsersSuccess,
    fetchUsersForCcdbGroupRequest,
    fetchUsersForCcdbGroupSuccess,
    fetchUsersForCcdbGroupFailure,
    fetchSingleUserFailure,
    fetchSingleUserRequest,
    fetchSingleUserSuccess,
    createUserFailure,
    createUserRequest,
    createUserSuccess,
    updateUserFailure,
    updateUserRequest,
    updateUserSuccess,
    ImportUsersResponse,
} from '@actions/users';
import User, { UsersResponse } from 'src/types/models/User';

interface UsersState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    itemCount: number;
    users: User[];
    importUsersErrors: string[];
}

const initialState: UsersState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    itemCount: 0,
    users: [],
    importUsersErrors: [],
};

export default createReducer(initialState, {
    [fetchAllUsersRequest.type]: handleFetchRequest,
    [fetchAllUsersSuccess.type]: handleFetchUsersSuccess,
    [fetchAllUsersFailure.type]: handleFetchError,
    [fetchUsersForCcdbGroupRequest.type]: handleFetchRequest,
    [fetchUsersForCcdbGroupSuccess.type]: handleFetchUsersForCcdbGroupSuccess,
    [fetchUsersForCcdbGroupFailure.type]: handleFetchError,
    [fetchSingleUserRequest.type]: handleFetchRequest,
    [fetchSingleUserSuccess.type]: handleFetchSingleUserSuccess,
    [fetchSingleUserFailure.type]: handleFetchError,
    [createUserRequest.type]: handlePostRequest,
    [createUserSuccess.type]: handleCreateUserSuccess,
    [createUserFailure.type]: handlePostError,
    [updateUserRequest.type]: handlePostRequest,
    [updateUserSuccess.type]: handleUpdateUserSuccess,
    [updateUserFailure.type]: handlePostError,
    [postImportUsersRequest.type]: handlePostRequest,
    [postImportUsersSuccess.type]: handleImportUsersSuccess,
    [postImportUsersFailure.type]: handlePostError,
    [deleteUserRequest.type]: handleDeleteRequest,
    [deleteUserFailure.type]: handleDeleteError,
    [deleteUserSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: UsersState) {
    state.isFetching = true;
}

function handlePostRequest(state: UsersState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handleDeleteRequest(state: UsersState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handleFetchError(state: UsersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
function handlePostError(state: UsersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}
function handleDeleteError(state: UsersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}

function handleFetchUsersSuccess(state: UsersState, action: PayloadAction<UsersResponse>) {
    state.users = action.payload.items;
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handleFetchUsersForCcdbGroupSuccess(
    state: UsersState,
    action: PayloadAction<UsersResponse>,
) {
    state.users = action.payload.items;
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handleFetchSingleUserSuccess(state: UsersState, action: PayloadAction<User>) {
    state.users[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleCreateUserSuccess(state: UsersState, action: PayloadAction<User>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.users[action.payload.id] = action.payload;
}
function handleUpdateUserSuccess(state: UsersState, action: PayloadAction<User>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.users[action.payload.id] = action.payload;
}

function handleImportUsersSuccess(state: UsersState, action: PayloadAction<ImportUsersResponse>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.importUsersErrors = action.payload.errors;
}

function handleDeleteSuccess(state: UsersState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.users[action.payload];
}
