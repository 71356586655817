import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import School from 'src/types/models/School';

export const updateSchoolRequest = createAction('updateSchoolRequest');
export const updateSchoolSuccess = createAction<School>('updateSchoolSuccess');
export const updateSchoolFailure = createAction('updateSchoolFailure');

export const updateSchool =
    (id: number, postbody: unknown) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateSchoolRequest());
        try {
            const { data } = await api.put<unknown, School>(`schools/${id}`, postbody);

            dispatch(updateSchoolSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateSchoolFailure, e as APIError);
        }
    };

// interface PostBody {}
