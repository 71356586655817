import React from 'react';
import InstitutionCreateEditModal from '../shared/InstitutionCreateEditModal';
import useCreateInstitution from './hooks/useCreateInstitution';

const InstitutionCreateModal: React.FC = () => {
    const { handleSubmit, initialFormState } = useCreateInstitution();

    return (
        <InstitutionCreateEditModal
            onSubmit={handleSubmit}
            initialFormState={initialFormState}
            title="Create Institution"
            redirectURL="/institutions"
        />
    );
};

export default InstitutionCreateModal;
