import {
    getSchoolGroupsIsFetching,
    getSchoolGroupsFetchError,
    getSchoolGroups,
} from '@selectors/schoolGroups';
import { useAppSelector } from '../../../../redux/store';
import {
    getSchoolsIsFetching,
    getSchoolsFetchError,
    getSchools,
    getSchoolsItemCount,
} from '@selectors/schools';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from '@hooks/useForm';
import useApiFilterTableRef from '@hooks/useApiFilterTableRef';
import { fetchSchools } from '@actions/schools';
import { fetchSchoolGroups } from '@actions/schoolGroups';

interface FromState {
    schoolGroupIDs: number[];
    countrySearchTerm: string;
    regionSearchTerm: string;
}

const useFetchSchools = () => {
    const dispatch = useDispatch();

    const tableRef = useApiFilterTableRef();

    const isFetchingSchools = useSelector(getSchoolsIsFetching);
    const fetchSchoolsError = useSelector(getSchoolsFetchError);
    const schools = useAppSelector(getSchools);
    const itemCount = useSelector(getSchoolsItemCount);

    const schoolGroups = useSelector(getSchoolGroups);
    const isFetchingGroups = useSelector(getSchoolGroupsIsFetching);
    const fetchGroupsError = useSelector(getSchoolGroupsFetchError);

    const [formState, handleChange] = useForm<FromState>({
        schoolGroupIDs: [],
        countrySearchTerm: '',
        regionSearchTerm: '',
    });

    const handleFetch = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            const params = { ...formState, page, limit: pageSize, searchTerm };
            dispatch(fetchSchools(params));
        },
        [dispatch, formState],
    );

    const schoolGroupOptions = useMemo(() => {
        return schoolGroups.map(({ name, id }) => ({ label: name, value: id }));
    }, [schoolGroups]);

    useEffect(() => {
        tableRef.current?.refreshData();
        dispatch(fetchSchoolGroups());
    }, [formState, tableRef, dispatch]);

    return {
        isFetching: isFetchingGroups || isFetchingSchools,
        fetchError: fetchGroupsError || fetchSchoolsError,
        schools,
        handleFetch,
        formState,
        handleChange,
        tableRef,
        itemCount,
        schoolGroupOptions,
    };
};

export default useFetchSchools;
