import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const updateActivityRequest = createAction('updateActivityRequest');
export const updateActivitySuccess = createAction<Activity>('updateActivitySuccess');
export const updateActivityFailure = createAction('updateActivityFailure');

export const updateActivity = (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
    dispatch(updateActivityRequest());

    try {
        const { data } = await api.put<PostBody, Activity>(`activities/${id}`, postBody);

        dispatch(updateActivitySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateActivityFailure, e as APIError);
    }
};

interface PostBody {
    name: string;
    activityCode: string | null;
}
