import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import School, {
    InterestedInInternationalSchoolAward,
    PartnerSchoolSuggested,
    SchoolArea,
    SchoolGender,
    SchoolPartnersFoundViaSchoolsOnline,
    SchoolSENProvisioning,
    SchoolsOnlineProvidedUsefulInformation,
    SchoolSource,
    SchoolStatus,
    SchoolType,
    WholeSchoolPartnershipPreferences,
} from '../../../types/models/School';

export const createSchoolRequest = createAction('createSchoolRequest');
export const createSchoolSuccess = createAction<School>('createSchoolSuccess');
export const createSchoolFailure = createAction('createSchoolFailure');

export const createSchool =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createSchoolRequest());
        try {
            const { data } = await api.post<PostBody, School>(`schools`, postbody);

            dispatch(createSchoolSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createSchoolFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
    website: string | null;
    email: string | null;
    country: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    postcode: string;
    schoolArea: SchoolArea | null;

    schoolPhotographS3Key: string | null;
    headTeacher: string | null;
    schoolGroup: string | null;
    schoolTypes: SchoolType[];
    schoolGender: SchoolGender | null;
    provisionsForSpecialEducationalNeeds: SchoolSENProvisioning | null;
    schoolStatus: SchoolStatus | null;
    facebookID: string | null;
    twitterID: string | null;
    hasVideoConferencing: boolean | null;
    hasSMS: boolean | null;
    hasIT: boolean | null;
    hasEmail: boolean | null;
    internationalCoordinator: string | null;

    wholeSchoolPartnershipPreferences: WholeSchoolPartnershipPreferences | null;
    partnerSchoolInterestLocations: string[];
    currentPartnerSchoolLocations: string[];
    activitiesInterestedIn: number[];
    activitiesInvolvedIn: number[];
    partnersFoundViaSchoolsOnline: SchoolPartnersFoundViaSchoolsOnline | null;
    schoolsOnlineProvidedUsefulInformation: SchoolsOnlineProvidedUsefulInformation | null;
    partnerSchoolSuggested: PartnerSchoolSuggested | null;
    interestedInInternationalSchoolAward: InterestedInInternationalSchoolAward | null;

    source: SchoolSource | null;
}
