import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteInstitutionTypeRequest = createAction('deleteInstitutionTypeRequest');
export const deleteInstitutionTypeSuccess = createAction<number>('deleteInstitutionTypeSuccess');
export const deleteInstitutionTypeFailure = createAction('deleteInstitutionTypeFailure');

export const deleteInstitutionType =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteInstitutionTypeRequest());

        try {
            await api.delete(`institutionTypes/${id}`);
            dispatch(deleteInstitutionTypeSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteInstitutionTypeFailure, e as APIError);
        }
    };
