import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import InstitutionTypes from '@pages/institutionTypes/institutionTypes/InstitutionTypes';
import InstitutionTypeContainer from '@pages/institutionTypes/institutionType/InstitutionTypeContainer';

const InstitutionTypesRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <InstitutionTypes />
            </Route>

            <Route exact path={`${path}`}>
                <InstitutionTypes />
            </Route>
            <Route exact path={`${path}/create`}>
                <InstitutionTypes showCreateModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <InstitutionTypeContainer />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <InstitutionTypeContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <InstitutionTypeContainer showDeleteModal />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default InstitutionTypesRoutes;
