import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';

export const deleteUserActivityRequest = createAction('deleteUserActivityRequest');
export const deleteUserActivitySuccess = createAction<number>('deleteUserActivitySuccess');
export const deleteUserActivityFailure = createAction('deleteUserActivityFailure');

export const deleteUserActivity = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteUserActivityRequest());

    try {
        await api.delete(`UserActivities/${id}`);

        dispatch(deleteUserActivitySuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deleteUserActivityFailure, e as APIError);
    }
};
