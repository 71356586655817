import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import Institution from '../../../types/models/Institution';

export const createInstitutionRequest = createAction('createInstitutionRequest');
export const createInstitutionSuccess = createAction<Institution>('createInstitutionSuccess');
export const createInstitutionFailure = createAction('createInstitutionFailure');

export const createInstitution =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createInstitutionRequest());
        try {
            const { data } = await api.post<PostBody, Institution>(`institutions`, postbody);

            dispatch(createInstitutionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createInstitutionFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
    country: string;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    institutionTypeID: number | null;
}
