import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import MultiSelect from '@components/form/MultiSelect';
import useRemoveUsersFromCcdbGroup from '@pages/ccdbGroups/ccdbGroup/users/hooks/useRemoveUsersFromCcdbGroup';

const RemoveUsersFromCcdbGroupModal = () => {
    const { form, handleChange, userOptions, handleSubmit, isPosting, closeModal, error } =
        useRemoveUsersFromCcdbGroup();

    return (
        <Modal title="Remove Users from CCDB Group" style={{ overflow: 'unset' }}>
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <MultiSelect
                    label="Users"
                    name="userIDs"
                    options={userOptions}
                    value={form.userIDs}
                    onChange={handleChange}
                />
            </Form>
        </Modal>
    );
};

export default RemoveUsersFromCcdbGroupModal;
