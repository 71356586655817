import useUsersFetch from './hooks/useUsersFetch';
import CreateUserModal from './CreateUserModal';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from '@components/typography/Title';
import LinkButton from '@components/button/LinkButton';
import ImportUsersModal from './ImportUsersModal';
import ButtonRow from '@components/button/ButtonRow';
import User, { disabilityReportedFriendlyNames } from '../../../types/models/User';
import { outputUsersFullName } from '@utils/users';
import ApiFilterTable from '@components/table/ApiFilterTable';
import { withKeys } from '@utils/generic';

interface Props {
    showCreateModal?: boolean;
    showImportModal?: boolean;
}

const Users: React.FC<Props> = ({ showCreateModal = false, showImportModal = false }) => {
    const { tableRef, users, itemCount, handleFetch, isFetching, error } = useUsersFetch();

    return (
        <>
            <CreateHeader>
                <Title>Users</Title>
                <ButtonRow>
                    <LinkButton source="primary" icon="upload" href="users/import">
                        Import CSV
                    </LinkButton>
                    <LinkButton source="positive" icon="plus" href="users/create">
                        Create
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <ApiFilterTable
                ref={tableRef}
                isLoading={isFetching}
                error={error}
                rows={users}
                columns={columns}
                totalItems={itemCount}
                fetchData={handleFetch}
            />
            {showCreateModal && <CreateUserModal />}
            {showImportModal && <ImportUsersModal tableRef={tableRef} />}
        </>
    );
};

const columns = withKeys([
    {
        heading: 'ID',
        getValue: (row: User) => row.id,
    },
    {
        heading: 'Name',
        getValue: (row: User) => outputUsersFullName(row.firstName, row.lastName),
    },
    {
        heading: 'Unique ID',
        getValue: (row: User) => row.uniqueID,
    },
    {
        heading: 'Disability Reported',
        getValue: (row: User) =>
            typeof row.disabilityReported === 'string'
                ? disabilityReportedFriendlyNames[row.disabilityReported]
                : 'Not reported',
    },
    {
        heading: '',
        getValue: (row: User) => (
            <ButtonRow alignment="right">
                <LinkButton href={`/users/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
]);

export default Users;
