import { ActivityGroup } from 'src/types/models/ActivityGroups';
import { useParams } from 'react-router-dom';

import usePrevious from '@hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
    selectActivitiesIsPosting,
    selectActivitiesPostError,
    selectActivitiesPostSuccess,
} from '@selectors/activities';

import useRedirect from '@hooks/useRedirect';
import { deleteActivity } from '@actions/activities';

const useDeleteActivity = (activityGroup: ActivityGroup) => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(selectActivitiesIsPosting);
    const error = useSelector(selectActivitiesPostError);
    const success = useSelector(selectActivitiesPostSuccess);

    const handleSubmit = () => {
        dispatch(deleteActivity(+id));
    };

    const closeModal = useRedirect(`/activity-groups/${activityGroup.id}`);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteActivity;
