import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import InstitutionType from 'src/types/models/InstitutionType';

export const fetchInstitutionTypesRequest = createAction('fetchInstitutionTypesRequest');
export const fetchInstitutionTypesSuccess = createAction<FetchInstitutionTypesResponse>(
    'fetchInstitutionTypesSuccess',
);
export const fetchInstitutionTypesFailure = createAction('fetchInstitutionTypesFailure');

export const fetchInstitutionTypes =
    (params?: InstitutionTypeProps) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchInstitutionTypesRequest());
        try {
            const { data } = await api.get<FetchInstitutionTypesResponse>(
                'institutionTypes',
                params,
            );

            dispatch(fetchInstitutionTypesSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchInstitutionTypesFailure, e as APIError);
        }
    };

export type InstitutionTypeProps = {
    page?: number | null;
    limit?: number | null;
    searchTerm?: string | null;
};

export interface FetchInstitutionTypesResponse {
    items: InstitutionType[];
    totalItems: number;
}
