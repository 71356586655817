import { fetchActivities } from '@actions/activities';
import { fetchActivityGroups } from '@actions/activityGroups';
import { fetchAllCcdbGroups } from '@actions/ccdb';
import { selectActivities } from '@selectors/activities';
import { selectActivityGroupsObj } from '@selectors/activityGroups';
import { selectCcdbGroups } from '@selectors/ccdbGroups';
import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

const useParticipantDataFilterOptions = () => {
    const dispatch = useDispatch();

    const activities = useSelector(selectActivities);
    const activityGroups = useSelector(selectActivityGroupsObj);
    const ccdbGroups = useSelector(selectCcdbGroups);

    useEffect(() => {
        batch(() => {
            dispatch(fetchActivities());
            dispatch(fetchActivityGroups());
            dispatch(fetchAllCcdbGroups());
        });
    }, [dispatch]);

    const activityOptions = convertToOptions(activities);
    const activityGroupOptions = convertToOptions(Object.values(activityGroups));
    const ccdbGroupOptions = convertToOptions(Object.values(ccdbGroups));

    return {
        activityOptions,
        activityGroupOptions,
        ccdbGroupOptions,
    };
};

function convertToOptions(data: any[]) {
    if (!data.length) {
        return [];
    }

    return data.map(item => ({
        label: item.name,
        value: item.id,
    }));
}

export default useParticipantDataFilterOptions;
