import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteSchoolRequest = createAction('deleteSchoolRequest');
export const deleteSchoolSuccess = createAction<number>('deleteSchoolSuccess');
export const deleteSchoolFailure = createAction('deleteSchoolFailure');

export const deleteSchool =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteSchoolRequest());

        try {
            await api.delete(`schools/${id}`);
            dispatch(deleteSchoolSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteSchoolFailure, e as APIError);
        }
    };
