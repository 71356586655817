import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import Institution from 'src/types/models/Institution';

export const fetchInstitutionsRequest = createAction('fetchInstitutionsRequest');
export const fetchInstitutionsSuccess = createAction<FetchInstitutionsResponse>(
    'fetchInstitutionsSuccess',
);
export const fetchInstitutionsFailure = createAction('fetchInstitutionsFailure');

export const fetchInstitutions =
    (params: Params) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchInstitutionsRequest());
        try {
            const { data } = await api.get<FetchInstitutionsResponse>('institutions', params);

            dispatch(fetchInstitutionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchInstitutionsFailure, e as APIError);
        }
    };

interface Params {
    institutionTypeIDs: number[];
    page: number;
    pageSize: number;
    searchTerm: string | null;
}

export interface FetchInstitutionsResponse {
    items: Institution[];
    totalItems: number;
}
