import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import FormField from '@components/form/FormField';
import TextInput from '@components/form/TextInput';
import useEditActivity from './hooks/useEditActivity';
import { Activity } from 'src/types/models/Activities';

interface Props {
    activity: Activity;
}

const EditActivityModal: React.FC<Props> = ({ activity }) => {
    const { form, handleSubmit, closeModal, handleChange, isPosting, postError } =
        useEditActivity(activity);

    return (
        <Modal title="Edit Activity">
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                error={postError}
                isPosting={isPosting}
            >
                <FormField name="name" label="Name">
                    <TextInput name="name" value={form.name} onChange={handleChange} />
                </FormField>
                <FormField name="activityCode" label="Activity Code">
                    <TextInput
                        name="activityCode"
                        value={form.activityCode}
                        onChange={handleChange}
                    />
                </FormField>
            </Form>
        </Modal>
    );
};

export default EditActivityModal;
