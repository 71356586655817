import usePrevious from '@hooks/usePrevious';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { deleteInstitutionType } from '@actions/institutionTypes';
import {
    getInstitutionType,
    getInstitutionTypesIsPosting,
    getInstitutionTypesPostSuccess,
    getInstitutionTypesPostError,
} from '@selectors/institutionTypes';

const useDeleteInstitutionType = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(getInstitutionTypesIsPosting);
    const error = useSelector(getInstitutionTypesPostError);
    const success = useSelector(getInstitutionTypesPostSuccess);
    const institutionType = useAppSelector(state => getInstitutionType(state, +id));

    const handleSubmit = () => {
        dispatch(deleteInstitutionType(+id));
    };

    const closeModal = useCallback(() => {
        history.replace('/institution-types');
    }, [history]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        institutionType,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteInstitutionType;
