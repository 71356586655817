import Title from '@components/typography/Title';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import React, { useMemo } from 'react';
import useFetchInstitutions from './hooks/useFetchInstitutions';
import ApiFilterTable from '@components/table/ApiFilterTable';
import { Column, TableColumns } from 'src/types/shared/table';
import MultiSelect from '@components/form/MultiSelect';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import Institution from 'src/types/models/Institution';
import InstitutionCreateModal from './InstitutionCreateModal';
import { getCountryName } from '@utils/countries';
import { truncateString, withKeys } from '@utils/generic';
import { humanizeEnumNames } from '@utils/enums';

interface Props {
    showCreateModal?: boolean;
}

const Institutions: React.FC<Props> = ({ showCreateModal = false }) => {
    const {
        isFetching,
        fetchError,
        institutions,
        handleFetch,
        formState,
        handleChange,
        tableRef,
        institutionTypeOptions,
        itemCount,
    } = useFetchInstitutions();

    const columns = useColumns();

    return (
        <>
            <CreateHeader>
                <Title>Institutions</Title>
                <ButtonRow>
                    <LinkButton source="positive" icon="plus" href="institutions/create">
                        Create
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <div className="table-filters">
                <MultiSelect
                    search
                    name="institutionTypeID"
                    value={formState.institutionTypeID}
                    options={institutionTypeOptions}
                    onChange={handleChange}
                    label="Institution Types"
                    placeholder="All"
                />
            </div>

            <ApiFilterTable
                ref={tableRef}
                isLoading={isFetching}
                error={fetchError}
                rows={institutions}
                columns={columns}
                totalItems={itemCount}
                fetchData={handleFetch}
            />
            {showCreateModal && <InstitutionCreateModal />}
        </>
    );
};

const useColumns = () => {
    const columns: TableColumns<Institution> = useMemo(
        () =>
            withKeys<Omit<Column<Institution>, 'key'>>([
                {
                    heading: 'ID',
                    getValue: row => row.id,
                },
                {
                    heading: 'Name',
                    getValue: row => row.name,
                },
                {
                    heading: 'Type',
                    getValue: row => humanizeEnumNames(row.institutionTypeID),
                },
                {
                    heading: 'Address',
                    getValue: row =>
                        truncateString(
                            [row.addressLine1, row.addressLine2, row.city]
                                .filter(x => x)
                                .join(', '),
                            30,
                            '...',
                        ),
                },
                {
                    heading: 'Country',
                    getValue: row => getCountryName(row.country),
                },
                {
                    heading: 'City',
                    getValue: row => row.city || 'N/A',
                },
                {
                    heading: '',
                    getValue: row => (
                        <ButtonRow alignment="right">
                            <LinkButton size="small" href={`/institutions/${row.id}`}>
                                View
                            </LinkButton>
                        </ButtonRow>
                    ),
                },
            ]),
        [],
    );

    return columns;
};

export default Institutions;
