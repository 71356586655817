import { updateInstitutionType } from '@actions/institutionTypes';
import { useDispatch } from 'react-redux';
import InstitutionType from '../../../../types/models/InstitutionType';
import { InstitutionTypeCreateEditFormState } from '@pages/institutionTypes/shared/types/InstitutionTypeCreateEditFormStep';

const useEditInstitutionType = (institutionType: InstitutionType) => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: InstitutionTypeCreateEditFormState) => {
        dispatch(updateInstitutionType(institutionType.id, formState));
    };

    return {
        initialFormState: getInitialState(institutionType),
        handleSubmit,
    };
};

const getInitialState = (institutionType: InstitutionType) => {
    const formState = {
        name: institutionType.name,
    };
    return formState;
};

export default useEditInstitutionType;
