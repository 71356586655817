import SchoolContainer from '@pages/schools/school/SchoolContainer';
import Schools from '@pages/schools/schools/Schools';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DefaultRedirect from './DefaultRedirect';

const SchoolsRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Schools />
            </Route>
            <Route exact path={`${path}/create`}>
                <Schools showCreateModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <SchoolContainer />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <SchoolContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <SchoolContainer showDeleteModal />
            </Route>
            <DefaultRedirect to={`${path}`} />
        </Switch>
    );
};

export default SchoolsRoutes;
