import { MegeSchoolStepsProps } from './shared/MegeSchoolStepsProps';
import Select from '@components/form/Select';
import useSchoolMergeOptions from './hooks/useSchoolMergeOptions';
import { humanizeEnumName } from '@utils/enums';

const SchoolsMergeStep4: React.FC<MegeSchoolStepsProps> = ({
    schools,
    formState,
    handleChange,
}) => {
    return (
        <>
            <div style={{ minHeight: 150 }}>
                <Select
                    required
                    omitRemove
                    label="Source"
                    name="sourceSourceID"
                    value={formState.sourceSourceID}
                    onChange={handleChange}
                    options={useSchoolMergeOptions(schools, s => humanizeEnumName(s.source))}
                />
            </div>
        </>
    );
};

export default SchoolsMergeStep4;
