import { RootState } from '@reducers/index';

export const getSchools = (state: RootState) => Object.values(state.schoolsReducer.schools);
export const getSchool = (state: RootState, id: number) => state.schoolsReducer.schools[id];

export const getSchoolsIsFetching = (state: RootState) => state.schoolsReducer.isFetching;
export const getSchoolsFetchError = (state: RootState) => state.schoolsReducer.fetchError;
export const getSchoolsPostError = (state: RootState) => state.schoolsReducer.postError;

export const getSchoolsIsPosting = (state: RootState) => state.schoolsReducer.isPosting;
export const getSchoolsPostSuccess = (state: RootState) => state.schoolsReducer.postSuccess;
export const getSchoolsItemCount = (state: RootState) => state.schoolsReducer.itemCount;
export const getSchoolOptions = (state: RootState) => state.schoolsReducer.options;
