import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../redux/store';
import {
    getInstitution,
    getInstitutionsIsFetching,
    getInstitutionsFetchError,
} from '@selectors/institutions';
import { fetchInstitution } from '@actions/institutions';

const useFetchInstitution = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const institution = useAppSelector(state => getInstitution(state, +id));
    const isFetching = useSelector(getInstitutionsIsFetching);
    const fetchError = useSelector(getInstitutionsFetchError);

    useEffect(() => {
        dispatch(fetchInstitution(+id));
    }, [dispatch, id]);

    return {
        institution,
        isFetching,
        fetchError,
    };
};

export default useFetchInstitution;
