import React from 'react';
import School from 'src/types/models/School';
import SchoolCreateEditModal from '../shared/SchoolCreateEditModal';
import useEditSchool from './hooks/useEditSchool';

interface Props {
    school: School;
}

const SchoolEditModal: React.FC<Props> = ({ school }) => {
    const { initialFormState, handleSubmit } = useEditSchool(school);

    return (
        <SchoolCreateEditModal
            onSubmit={handleSubmit}
            initialFormState={initialFormState}
            title="Edit School"
            redirectURL={`/schools/${school.id}`}
        />
    );
};

export default SchoolEditModal;
