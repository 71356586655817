import ConfirmModal from '@components/modal/ConfirmModal';
import useDeleteSchool from './hooks/useDeleteSchool';

const SchoolDeleteModal = () => {
    const { handleSubmit, closeModal, isPosting, error, school } = useDeleteSchool();

    if (!school) return <></>;
    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete school"
            description="Are you sure you want to delete this school?"
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            error={error}
        />
    );
};

export default SchoolDeleteModal;
