import axios, { AxiosRequestConfig } from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Action, ActionCreator, Dispatch } from 'redux';
import qs from 'qs';

import { setFieldErrors } from '@actions/fieldErrors';
import { addFormError } from '@actions/fieldErrors';
import { setRedirectUrl } from '@actions/redirects';
import { APIError } from 'src/types/shared/APIError';
import { clearJwtAndRefreshToken } from './jwt';
import config from '@config/index';
const { API_URL } = config;

const get = async <TResponseBody>(url: string, params?: any) =>
    axios.get<TResponseBody>(`${API_URL}/${url}`, await getConfig(params));

const post = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody, params?: any) =>
    axios.post<TResponseBody>(`${API_URL}/${url}`, data, await getConfig(params));

const put = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody, params?: any) =>
    axios.put<TResponseBody>(`${API_URL}/${url}`, data, await getConfig(params));

const patch = async <TRequestBody, TResponseBody>(url: string, data: TRequestBody, params?: any) =>
    axios.patch<TResponseBody>(`${API_URL}/${url}`, data, await getConfig(params));

const del = async <TResponseBody>(url: string, params?: any) =>
    axios.delete<TResponseBody>(`${API_URL}/${url}`, await getConfig(params));

const postMultipart = async <TResponseBody>(url: string, data: FormData, params?: any) => {
    const config = { ...(await getConfig(params)), 'content-type': 'multipart/form-data' };
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, config);
};

const logError = async (body: ErrorLogRequest) => {
    // todo error request body
    return axios.post(`${API_URL}/error`, body, await getConfig());
};

export const api = {
    get,
    post,
    put,
    patch,
    delete: del,
    postMultipart,
    logError,
};

const getConfig = async (params: any = {}): Promise<AxiosRequestConfig> => {
    const jwt = await getToken();

    return {
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
        params,
        paramsSerializer: params => qs.stringify(params),
    };
};
export const handleApiErrors = <A extends Action>(
    dispatch: Dispatch,
    failureAction: ActionCreator<A>,
    err: APIError,
) => {
    const { response, message } = err;

    if (response && response.status === 400) {
        typeof response.data === 'string'
            ? dispatch(addFormError(response.data))
            : dispatch(setFieldErrors(response.data.errors));

        return dispatch(failureAction(null));
    }
    if (response && response.status === 401) {
        dispatch(failureAction('Unauthorized'));
        clearJwtAndRefreshToken();
        return dispatch(setRedirectUrl('/auth/login'));
    }

    return dispatch(failureAction(message));
};

const getToken = async () => {
    const jwt = localStorage.getItem('jwt');
    const refreshToken = localStorage.getItem('refreshToken');

    if (!jwt || !refreshToken) {
        return jwt;
    }

    const now = new Date().getTime() / 1000;
    const { exp = 0 } = jwtDecode<JwtPayload>(jwt);
    const minutesUntilExpiration = (exp - now) / 60;

    if (minutesUntilExpiration > 2) {
        return jwt;
    }

    try {
        const { data } = await axios.post<LoginResponse>(`${API_URL}/auth/refresh-token`, {
            refreshToken,
            expiredToken: jwt,
        });

        localStorage.setItem('jwt', data.token);
        localStorage.setItem('refreshToken', data.refreshToken);

        return data.token;
    } catch (error) {
        return null;
    }
};

export interface LoginResponse {
    token: string;
    refreshToken: string;
}

export interface ErrorLogRequest {
    message?: string;
    stackTrace?: string;
    appVersion: string;
    device: string;
    deviceOS: string;
    deviceRAM: number | null;
}
