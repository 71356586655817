import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCcdbGroupsFetchError,
    selectCcdbGroupsIsFetching,
    selectCcdbGroupsPostSuccess,
    selectSingleCcdbGroup,
} from '@selectors/ccdbGroups';
import { useAppSelector } from '../../../../redux/store';
import { isEmpty } from '@utils/generic';
import { fetchSingleCcdbGroup } from '@actions/ccdb';

const useFetchSingleCcdbGroup = (id: number) => {
    const dispatch = useDispatch();

    const isFetching = useSelector(selectCcdbGroupsIsFetching);
    const ccdbGroup = useAppSelector(state => selectSingleCcdbGroup(state, id));
    const fetchError = useSelector(selectCcdbGroupsFetchError);
    const postSuccess = useSelector(selectCcdbGroupsPostSuccess);

    useEffect(() => {
        if (isEmpty(ccdbGroup) && !isFetching && !postSuccess) {
            dispatch(fetchSingleCcdbGroup(id));
        }
    }, [id, ccdbGroup, isFetching, dispatch, postSuccess]);

    return {
        ccdbGroup,
        isFetching,
        fetchError,
    };
};

export default useFetchSingleCcdbGroup;
