import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@hooks/usePrevious';
import {
    getInstitutionsIsPosting,
    getInstitutionsPostError,
    getInstitutionsPostSuccess,
} from '@selectors/institutions';
import { useCallback, useEffect, useMemo } from 'react';
import useForm from '@hooks/useForm';

import { InstitutionCreateEditFormState } from '@pages/institutions/shared/types/InstitutionCreateEditFormStep';

import { fetchInstitutionTypes } from '@actions/institutionTypes';
import { getInstitutionTypes } from '@selectors/institutionTypes';
import { DropdownOption } from 'src/types/shared/DropdownOption';

interface Options {
    onSubmit: (formState: InstitutionCreateEditFormState) => void;
    initialFormState: InstitutionCreateEditFormState;
    redirectURL: string;
}

const useCreateEditInstitution = ({ onSubmit, initialFormState, redirectURL }: Options) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isPosting = useSelector(getInstitutionsIsPosting);
    const error = useSelector(getInstitutionsPostError);
    const success = useSelector(getInstitutionsPostSuccess);
    const [formState, handleChange] = useForm(initialFormState);

    const institutionTypes = useSelector(getInstitutionTypes);

    const handleSubmit = () => {
        onSubmit(formState);
    };

    const closeModal = useCallback(() => history.replace(redirectURL), [history, redirectURL]);

    const prevSuccess = usePrevious(success);

    const institutionTypeOptions = useMemo<DropdownOption<number>[]>(
        () => institutionTypes.map(i => ({ label: i.name, value: i.id })),
        [institutionTypes],
    );

    useEffect(() => {
        if (!prevSuccess && success) closeModal();
    }, [dispatch, prevSuccess, success, closeModal]);

    useEffect(() => {
        dispatch(fetchInstitutionTypes());
    }, [dispatch]);

    return {
        closeModal,
        handleSubmit,
        isPosting,
        error,
        formState,
        handleChange,
        institutionTypeOptions,
    };
};

export default useCreateEditInstitution;
