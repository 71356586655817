import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getInstitutionTypes,
    getInstitutionTypesFetchError,
    getInstitutionTypesIsFetching,
} from '@selectors/institutionTypes';
import { fetchInstitutionTypes } from '@actions/institutionTypes';

const useInstitution = () => {
    const dispatch = useDispatch();

    const institutionTypes = useSelector(getInstitutionTypes);
    const isFetchingTypes = useSelector(getInstitutionTypesIsFetching);
    const fetchTypesError = useSelector(getInstitutionTypesFetchError);

    useEffect(() => {
        dispatch(fetchInstitutionTypes());
    }, [dispatch]);

    return {
        institutionTypes
    };
};

export default useInstitution;
