import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import {
    selectActivitiesFetchError,
    selectActivitiesIsFetching,
    selectUserActivities,
} from '@selectors/activities';
import { fetchUserActivities } from '@actions/activities';

const useFetchUserActivities = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const isFetching = useSelector(selectActivitiesIsFetching);
    const error = useSelector(selectActivitiesFetchError);
    const activities = useSelector(selectUserActivities);

    useEffect(() => {
        dispatch(fetchUserActivities(+id));
    }, [dispatch, id]);

    return {
        isFetching,
        error,
        activities,
    };
};

export default useFetchUserActivities;
