import { forwardRef, useImperativeHandle } from 'react';

import Checkbox from '@components/form/Checkbox';
import ComboSelect from '@components/form/ComboSelect';
import Select from '@components/form/Select';
import SingleFileUpload from '@components/form/SingleFileUpload';
import TextInput from '@components/form/TextInput';

import {
    SchoolCreateEditFormState,
    SchoolCreateEditFormStepProps,
    SchoolCreateEditFormStepRefHandle,
} from './types/SchoolCreateEditFormStep';
import useCreateEditSchoolStep2 from './hooks/useCreateEditSchoolStep2';
import MultiSelect from '@components/form/MultiSelect';

const SchoolCreateEditStep2 = forwardRef<
    SchoolCreateEditFormStepRefHandle,
    SchoolCreateEditFormStepProps
>(({ formState, handleChange }, ref) => {
    const {
        schoolGroupOptions,
        typeOptions,
        genderOptions,
        provisionsForSpecialEducationalNeedOptions,
        statusOptions,
        handleSubmit,
    } = useCreateEditSchoolStep2();

    useImperativeHandle(
        ref,
        (): SchoolCreateEditFormStepRefHandle => ({
            onSubmit(formState: SchoolCreateEditFormState) {
                handleSubmit(formState);
            },
        }),
    );

    return (
        <>
            <SingleFileUpload
                position="left"
                label="School Photograph"
                name="schoolPhotographS3Key"
                value={formState.schoolPhotographS3Key}
                onChange={handleChange}
            />
            <TextInput
                label="Head Teacher"
                name="headTeacher"
                value={formState.headTeacher}
                onChange={handleChange}
            />
            <ComboSelect
                label="School Group"
                name="schoolGroup"
                value={formState.schoolGroup}
                onChange={handleChange}
                options={schoolGroupOptions}
            />
            <MultiSelect
                label="School Type"
                name="schoolTypes"
                value={formState.schoolTypes}
                onChange={handleChange}
                options={typeOptions}
            />
            <Select
                label="School Gender"
                name="schoolGender"
                value={formState.schoolGender}
                onChange={handleChange}
                options={genderOptions}
            />
            <Select
                label="Provisions For Special Educational Needs"
                name="provisionsForSpecialEducationalNeeds"
                value={formState.provisionsForSpecialEducationalNeeds}
                onChange={handleChange}
                options={provisionsForSpecialEducationalNeedOptions}
            />
            <Select
                label="School Status"
                name="schoolStatus"
                value={formState.schoolStatus}
                onChange={handleChange}
                options={statusOptions}
            />
            <TextInput
                label="Number of Pupils"
                name="numberOfPupils"
                value={formState.numberOfPupils}
                onChange={handleChange}
            />
            <TextInput
                name="facebookID"
                label="Facebook ID"
                value={formState.facebookID}
                onChange={handleChange}
            />
            <TextInput
                name="twitterID"
                label="Twitter ID"
                value={formState.twitterID}
                onChange={handleChange}
            />
            <TextInput
                label="International Coordinator"
                name="internationalCoordinator"
                value={formState.internationalCoordinator}
                onChange={handleChange}
            />
            <Checkbox
                label="Has video confrencing?"
                name="hasVideoConferencing"
                value={formState.hasVideoConferencing}
                onChange={handleChange}
            />
            <Checkbox
                label="Has SMS?"
                name="hasSMS"
                value={formState.hasSMS}
                onChange={handleChange}
            />
            <Checkbox
                label="Has IT?"
                name="hasIT"
                value={formState.hasIT}
                onChange={handleChange}
            />
            <Checkbox
                label="Has Email?"
                name="hasEmail"
                value={formState.hasEmail}
                onChange={handleChange}
            />
        </>
    );
});

export default SchoolCreateEditStep2;
