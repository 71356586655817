import { createSchool } from './../../../../redux/actions/schools/createSchool';
import { useDispatch } from 'react-redux';
import { SchoolCreateEditFormState } from '@pages/schools/shared/types/SchoolCreateEditFormStep';

const initialFormState: SchoolCreateEditFormState = {
    name: '',
    website: '',
    email: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    schoolArea: null,
    schoolPhotographS3Key: '',
    headTeacher: '',
    schoolGroup: '',
    schoolTypes: [],
    schoolGender: null,
    provisionsForSpecialEducationalNeeds: null,
    schoolStatus: null,
    numberOfPupils: '',
    facebookID: '',
    twitterID: '',
    hasVideoConferencing: false,
    hasSMS: false,
    hasIT: false,
    hasEmail: false,
    internationalCoordinator: '',
    wholeSchoolPartnershipPreferences: null,
    partnerSchoolInterestLocations: [],
    currentPartnerSchoolLocations: [],
    activitiesInterestedIn: [],
    activitiesInvolvedIn: [],
    partnersFoundViaSchoolsOnline: null,
    schoolsOnlineProvidedUsefulInformation: null,
    partnerSchoolSuggested: null,
    interestedInInternationalSchoolAward: null,
    source: null,
    ukRegion: null,
};

const useCreateSchool = () => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: SchoolCreateEditFormState) => {
        dispatch(createSchool(formState));
    };

    return {
        initialFormState,
        handleSubmit,
    };
};

export default useCreateSchool;
