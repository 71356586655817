import { useMemo, useRef } from 'react';
import School from 'src/types/models/School';
import { DropdownOption } from 'src/types/shared/DropdownOption';

const useSchoolMergeOptions = (schools: School[], getLabel: (school: School) => string | null) => {
    const lableFunc = useRef(getLabel);

    const options: DropdownOption<number>[] = useMemo(() => {
        return schools.reduce((acc: DropdownOption<number>[], school) => {
            const label = lableFunc.current(school) || '-- Empty --';

            if (acc.every(opt => opt.label !== label)) acc.push({ label, value: school.id });
            return acc;
        }, []);
    }, [schools]);

    return options;
};

export default useSchoolMergeOptions;
