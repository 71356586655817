import { RootState } from '@reducers/index';

export const getSearchedAdminUsers = (state: RootState) =>
    state.searchedAdminUsersReducer.adminUsers;
export const getSearchedAdminUsersItemCount = (state: RootState) =>
    state.searchedAdminUsersReducer.itemCount;
export const getSearchedAdminUser = (state: RootState, id: number) =>
    state.searchedAdminUsersReducer.adminUsers[id];
export const getSearchedAdminUsersIsFetching = (state: RootState) =>
    state.searchedAdminUsersReducer.isFetching;
export const getSearchedAdminUsersError = (state: RootState) =>
    state.searchedAdminUsersReducer.error;
export const getSearchedAdminUsersIsPosting = (state: RootState) =>
    state.searchedAdminUsersReducer.isPosting;
