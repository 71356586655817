import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import useRedirect from '@hooks/useRedirect';
import usePrevious from '@hooks/usePrevious';

import {
    selectActivitiesIsPosting,
    selectActivitiesPostError,
    selectActivitiesPostSuccess,
} from '@selectors/activities';

import { deleteUserActivity } from '@actions/activities/deleteUserActivity';

const useDeleteUserActivity = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { id, activityID } = useParams<{ id: string; activityID: string }>();

    const isPosting = useSelector(selectActivitiesIsPosting);
    const postSuccess = useSelector(selectActivitiesPostSuccess);
    const error = useSelector(selectActivitiesPostError);

    const prevPostSuccess = usePrevious(postSuccess);

    const closeModal = useRedirect(`/users/${id}`);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, history, id, closeModal]);

    const handleSubmit = () => {
        dispatch(deleteUserActivity(+activityID));
    };

    return { handleSubmit, closeModal, isPosting, error };
};

export default useDeleteUserActivity;
