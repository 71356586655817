import config from '@config/index';

const { S3_URL } = config;

interface Props {
    s3Key: string | null;
}

const SchoolLogo: React.FC<Props> = ({ s3Key }) => {
    if (!s3Key) return null;
    return <img alt="school-logo" className="school-logo" src={`${S3_URL}/${s3Key}`} />;
};

export default SchoolLogo;
