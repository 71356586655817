import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import SchoolGroup from 'src/types/models/SchoolGroup';

export const createSchoolGroupRequest = createAction('createSchoolGroupRequest');
export const createSchoolGroupSuccess = createAction<SchoolGroup>('createSchoolGroupSuccess');
export const createSchoolGroupFailure = createAction('createSchoolGroupFailure');

export const createSchoolGroup =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createSchoolGroupRequest());
        try {
            const { data } = await api.post<PostBody, SchoolGroup>(`schoolGroups`, postbody);

            dispatch(createSchoolGroupSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createSchoolGroupFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
}
