import { getSchoolGroup } from './../../../../redux/selectors/schoolGroups';
import { useParams, useHistory } from 'react-router-dom';

import usePrevious from '@hooks/usePrevious';
import { useAppSelector } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { deleteSchoolGroup } from '@actions/schoolGroups';
import {
    getSchoolsIsPosting,
    getSchoolsPostSuccess,
    getSchoolsPostError,
} from '@selectors/schools';

const useDeleteSchoolGroup = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(getSchoolsIsPosting);
    const error = useSelector(getSchoolsPostError);
    const success = useSelector(getSchoolsPostSuccess);
    const schoolGroup = useAppSelector(state => getSchoolGroup(state, +id));

    const handleSubmit = () => {
        dispatch(deleteSchoolGroup(+id));
    };

    const closeModal = useCallback(() => {
        history.replace(`/school-groups`);
    }, [history]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        schoolGroup,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteSchoolGroup;
