import useForm from '@hooks/useForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store';

import {
    selectCcdbGroupsIsPosting,
    selectCcdbGroupsPostError,
    selectCcdbGroupsPostSuccess,
    selectSingleCcdbGroup,
} from '@selectors/ccdbGroups';

import { updateCcdbGroup } from '@actions/ccdb';

import { isEmpty } from '@utils/generic';
import usePrevious from '@hooks/usePrevious';
import { getSearchedAdminUsers } from '@selectors/searchedAdminUsers';
import { fetchSearchedAdminUsers } from '@actions/adminUsers/fetchSearchedAdminUsers';
import { DropdownOption } from 'src/types/shared/DropdownOption';

const useUpdateCcdbGroup = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useDispatch();

    const ccdbGroup = useAppSelector(state => selectSingleCcdbGroup(state, +id));
    const isPosting = useAppSelector(selectCcdbGroupsIsPosting);
    const postSuccess = useAppSelector(selectCcdbGroupsPostSuccess);
    const postError = useAppSelector(selectCcdbGroupsPostError);

    const prevPostSuccess = usePrevious(postSuccess);

    const [searchTerm, setSearchTerm] = useState('');
    const prevSearchTerm = usePrevious(searchTerm);
    const searchTimeout = useRef<number | null>(null);

    const [form, handleChange] = useForm({
        name: ccdbGroup?.name,
        ownerAdminUserID: ccdbGroup?.ownerAdminUserId,
    });

    const onSearchTermChanged = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        if (searchTerm !== prevSearchTerm) {
            window.clearTimeout(searchTimeout.current || 0);
            searchTimeout.current = window.setTimeout(() => {
                dispatch(
                    fetchSearchedAdminUsers({ page: null, limit: null, searchTerm: newSearchTerm }),
                );
            }, 1000);
        }
    };

    useEffect(() => {
        if (!isEmpty(ccdbGroup)) {
            handleChange('name', ccdbGroup.name);
        }

        dispatch(fetchSearchedAdminUsers({ page: null, limit: null, searchTerm: '' }));
        // eslint-disable-next-line
    }, [ccdbGroup]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            history.replace(`/ccdb-groups/${id}`);
        }
    }, [prevPostSuccess, postSuccess, history, id]);

    const adminUsers = useSelector(getSearchedAdminUsers);
    const userOptions = useMemo(() => {
        return Object.values(adminUsers).reduce((acc: DropdownOption<number>[], adminUser) => {
            acc.push({
                label: `${adminUser.firstName} ${adminUser.lastName}`,
                value: adminUser.id,
            });

            return acc;
        }, []);
    }, [adminUsers]);

    const closeModal = () => {
        history.push(`/ccdb-groups/${id}`);
    };

    const handleSubmit = () => {
        dispatch(updateCcdbGroup(+id, form));
    };

    return {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        postError,
        userOptions,
        onSearchTermChanged,
    };
};

export default useUpdateCcdbGroup;
