import { CountryCode } from 'countries-and-timezones';
import { Nullable } from '../shared/helpers';

export default interface User {
    agreedTo3rdPartyResearchDataSharing: boolean;
    agreedTo3rdPartySchoolSupportDataSharing: boolean;
    ccdbGroupIds: number[];
    ccdbGroupNames: string[];
    consentedToPersonalDataCollection: boolean;
    country: CountryCode;
    disabilityReported: Nullable<DisabilityReported>;
    email: Nullable<string>;
    ethnicity: Nullable<Ethnicity>;
    firstName: Nullable<string>;
    gender: Nullable<UserGender>;
    id: number;
    institutionID: Nullable<number>;
    institutionName: Nullable<string>;
    isDeleted: boolean;
    lastName: Nullable<string>;
    phoneNumber: string;
    school: string;
    schoolAssociation: Nullable<UserSchoolAssociation>;
    schoolEmail: Nullable<string>;
    schoolID: Nullable<number>;
    schoolPosition: Nullable<string>;
    signedUpForBritishCouncilMail: boolean;
    signedUpForSchoolsOnlineNews: boolean;
    title: Nullable<string>;
    uniqueID: Nullable<string>;
}

export interface UsersResponse {
    items: User[];
    totalItems: number;
}

export enum UserSchoolAssociation {
    WorksAtSchool = 'WorksAtSchool',
    DoesntWorkAtSchool = 'DoesntWorkAtSchool',
    WorksForInstitutionOrLocalAuthority = 'WorksForInstitutionOrLocalAuthority',
}

export enum UserGender {
    Male = 'Male',
    Female = 'Female',
    Other = 'Other',
    PreferNotToSay = 'PreferNotToSay',
}

export enum Ethnicity {
    WhiteIrish = 'WhiteIrish',
    WhiteBritish = 'WhiteBritish',
    AnyOtherWhiteBackground = 'AnyOtherWhiteBackground',
    AsianBangladeshi = 'AsianBangladeshi',
    AsianChinese = 'AsianChinese',
    AsianPakistani = 'AsianPakistani',
    BlackAfrican = 'BlackAfrican',
    MixedWhiteAndAsian = 'MixedWhiteAndAsian',
    MixedWhiteAndBlackCaribbean = 'MixedWhiteAndBlackCaribbean',
    NotStated = 'NotStated',
}

export const ethnicityFriendlyNames: Record<Ethnicity, string> = {
    [Ethnicity.WhiteIrish]: 'White Irish',
    [Ethnicity.WhiteBritish]: 'White British',
    [Ethnicity.AnyOtherWhiteBackground]: 'Other White Background',
    [Ethnicity.AsianBangladeshi]: 'Asian Bangladeshi',
    [Ethnicity.AsianChinese]: 'Asian Chinese',
    [Ethnicity.AsianPakistani]: 'Asian Pakistani',
    [Ethnicity.BlackAfrican]: 'Black African',
    [Ethnicity.MixedWhiteAndAsian]: 'Mixed White and Asian',
    [Ethnicity.MixedWhiteAndBlackCaribbean]: 'Mixed White and Black Caribbean',
    [Ethnicity.NotStated]: 'Prefer Not To Say',
};

export const userSchoolAssociationFriendlyNames: Record<UserSchoolAssociation, string> = {
    [UserSchoolAssociation.WorksAtSchool]: 'Yes',
    [UserSchoolAssociation.DoesntWorkAtSchool]: 'No',
    [UserSchoolAssociation.WorksForInstitutionOrLocalAuthority]:
        'Works for an educational institution or local authority',
};

export const userGenderFriendlyNames: Record<UserGender, string> = {
    [UserGender.Male]: 'Male',
    [UserGender.Female]: 'Female',
    [UserGender.Other]: 'Other',
    [UserGender.PreferNotToSay]: 'Prefer Not To Say',
};

export enum DisabilityReported {
    Yes = 'Yes',
    No = 'No',
    PreferNotToSay = 'PreferNotToSay',
}

export const disabilityReportedFriendlyNames = {
    [DisabilityReported.Yes]: 'Yes',
    [DisabilityReported.No]: 'No',
    [DisabilityReported.PreferNotToSay]: 'Prefer not to say',
};
