import Title from '@components/typography/Title';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import React, { useMemo } from 'react';
import ApiFilterTable from '@components/table/ApiFilterTable';
import { Column, TableColumns } from 'src/types/shared/table';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import InstitutionCreateModal from './InstitutionTypeCreateModal';
import { withKeys } from '@utils/generic';
import useFetchInstitutionTypes from './hooks/useFetchInstitutionTypes';
import InstitutionType from 'src/types/models/InstitutionType';
import dayjs from 'dayjs';

interface Props {
    showCreateModal?: boolean;
}

const useColumns = () =>
    useMemo<TableColumns<InstitutionType>>(
        () =>
            withKeys<Omit<Column<InstitutionType>, 'key'>>([
                {
                    heading: 'ID',
                    getValue: row => row.id,
                },
                {
                    heading: 'Name',
                    getValue: row => row.name,
                },
                {
                    heading: 'Created On',
                    getValue: row => dayjs(row.createdOn).format('DD/MM/YYYY'),
                    getSort: (a, b) => a.createdOn.getTime() - b.createdOn.getTime(),
                },
                {
                    heading: '',
                    getValue: row => (
                        <ButtonRow alignment="right">
                            <LinkButton size="small" href={`/institution-types/${row.id}`}>
                                View
                            </LinkButton>
                        </ButtonRow>
                    ),
                },
            ]),
        [],
    );

const InstitutionTypes: React.FC<Props> = ({ showCreateModal = false }) => {
    const { isFetching, fetchError, institutionTypes, handleFetch, tableRef, itemCount } =
        useFetchInstitutionTypes();

    const columns = useColumns();

    return (
        <>
            <CreateHeader>
                <Title>Institution Types</Title>
                <ButtonRow>
                    <LinkButton source="positive" icon="plus" href="institution-types/create">
                        Create
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <ApiFilterTable
                ref={tableRef}
                isLoading={isFetching}
                error={fetchError}
                rows={institutionTypes}
                columns={columns}
                totalItems={itemCount}
                fetchData={handleFetch}
            />
            {showCreateModal && <InstitutionCreateModal />}
        </>
    );
};
export default InstitutionTypes;
