import { forwardRef, useImperativeHandle } from 'react';

import Select from '@components/form/Select';

import useCreateEditSchoolStep3 from './hooks/useCreateEditSchoolStep3';

import {
    SchoolCreateEditFormState,
    SchoolCreateEditFormStepProps,
    SchoolCreateEditFormStepRefHandle,
} from './types/SchoolCreateEditFormStep';
import MultiSelect from '@components/form/MultiSelect';
import CountryMultiSelect from '@components/form/CountryMultiSelect';

const SchoolCreateEditStep3 = forwardRef<
    SchoolCreateEditFormStepRefHandle,
    SchoolCreateEditFormStepProps
>(({ formState, handleChange }, ref) => {
    const {
        wholeSchoolPartnershipPreferenceOptions,
        partnersFoundViaSchoolsOnlineOptions,
        onlineProvidedUsefulInformationOptions,
        partnerSchoolSuggestedOptions,
        interestedInInternationalSchoolAwardOptions,
        handleSubmit,
        activitiesOptions,
    } = useCreateEditSchoolStep3();

    useImperativeHandle(
        ref,
        (): SchoolCreateEditFormStepRefHandle => ({
            onSubmit(formState: SchoolCreateEditFormState) {
                handleSubmit(formState);
            },
        }),
    );

    return (
        <>
            <Select
                required
                name="wholeSchoolPartnershipPreferences"
                label="Whole School Partnership Preferences"
                value={formState.wholeSchoolPartnershipPreferences}
                onChange={handleChange}
                options={wholeSchoolPartnershipPreferenceOptions}
            />
            <CountryMultiSelect
                name="partnerSchoolInterestLocations"
                value={formState.partnerSchoolInterestLocations}
                onChange={handleChange}
                label="Partner Interest Locations"
            />
            <CountryMultiSelect
                name="currentPartnerSchoolLocations"
                value={formState.currentPartnerSchoolLocations}
                onChange={handleChange}
                label="Current Partner School Locations"
            />
            <MultiSelect
                search
                name="activitiesInterestedIn"
                value={formState.activitiesInterestedIn}
                onChange={handleChange}
                options={activitiesOptions}
                label="Activities Interested In"
            />
            <MultiSelect
                search
                name="activitiesInvolvedIn"
                value={formState.activitiesInvolvedIn}
                onChange={handleChange}
                options={activitiesOptions}
                label="Activities Involved In"
            />
            <Select
                name="partnersFoundViaSchoolsOnline"
                label="Partners Found Via Schools Online"
                value={formState.partnersFoundViaSchoolsOnline}
                onChange={handleChange}
                options={partnersFoundViaSchoolsOnlineOptions}
            />
            <Select
                name="schoolsOnlineProvidedUsefulInformation"
                label="Schools Online Provided Useful Information"
                value={formState.schoolsOnlineProvidedUsefulInformation}
                onChange={handleChange}
                options={onlineProvidedUsefulInformationOptions}
            />
            <Select
                name="partnerSchoolSuggested"
                label="Partner School Suggested"
                value={formState.partnerSchoolSuggested}
                onChange={handleChange}
                options={partnerSchoolSuggestedOptions}
            />
            <Select
                name="interestedInInternationalSchoolAward"
                label="Interested In International School Award"
                value={formState.interestedInInternationalSchoolAward}
                onChange={handleChange}
                options={interestedInInternationalSchoolAwardOptions}
            />
        </>
    );
});

export default SchoolCreateEditStep3;
