import Select from '@components/form/Select';
import { forwardRef, useImperativeHandle } from 'react';
import useCreateEditSchoolStep4 from './hooks/useCreateEditSchoolStep4';

import {
    SchoolCreateEditFormState,
    SchoolCreateEditFormStepProps,
    SchoolCreateEditFormStepRefHandle,
} from './types/SchoolCreateEditFormStep';

const SchoolCreateEditStep4 = forwardRef<
    SchoolCreateEditFormStepRefHandle,
    SchoolCreateEditFormStepProps
>(({ formState, handleChange }, ref) => {
    const { sourceOptions } = useCreateEditSchoolStep4();

    useImperativeHandle(
        ref,
        (): SchoolCreateEditFormStepRefHandle => ({
            onSubmit(formState: SchoolCreateEditFormState) {
                // do nothing, it's handled in the main hook for the last step
            },
        }),
    );

    return (
        <>
            <Select
                name="source"
                label="Source"
                value={formState.source}
                onChange={handleChange}
                options={sourceOptions}
            />
        </>
    );
});

export default SchoolCreateEditStep4;
