import { combineReducers } from 'redux';

import fieldErrorsReducer from './fieldErrors';
import redirectsReducer from './redirects';
import darkModeReducer from './darkMode';
import authReducer from './auth';
import adminUsersReducer from './adminUsers';
import usersReducer from './users';
import searchedUsersReducer from './searchedUsers';
import searchedAdminUsersReducer from './searchedAdminUsers';
import schoolsReducer from './schools';
import schoolGroupsReducer from './schoolGroups';
import institutionsReducer from './institutions';
import institutionTypesReducer from './institutionTypes';
import activitiesReducer from './activities';
import activityGroupsReducer from './activityGroups';
import ccdbGroupsReducer from './ccdbGroups';
import activityHistoriesReducer from './activityHistories';
import participantDatReducer from './participantData';
import regionsReducer from './regions';

const rootReducer = combineReducers({
    fieldErrorsReducer,
    activitiesReducer,
    activityGroupsReducer,
    activityHistoriesReducer,
    authReducer,
    adminUsersReducer,
    ccdbGroupsReducer,
    usersReducer,
    searchedUsersReducer,
    searchedAdminUsersReducer,
    redirectsReducer,
    regionsReducer,
    participantDatReducer,
    darkModeReducer,
    institutionsReducer,
    institutionTypesReducer,
    schoolsReducer,
    schoolGroupsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
