import { RootState } from '@reducers/index';

export const selectCcdbGroups = (state: RootState) => state.ccdbGroupsReducer.ccdbGroups;
export const selectSingleCcdbGroup = (state: RootState, id: number) =>
    state.ccdbGroupsReducer.ccdbGroups[id];

export const selectCcdbGroupsIsFetching = (state: RootState) => state.ccdbGroupsReducer.isFetching;
export const selectCcdbGroupsIsPosting = (state: RootState) => state.ccdbGroupsReducer.isPosting;

export const selectCcdbGroupsPostSuccess = (state: RootState) =>
    state.ccdbGroupsReducer.postSuccess;

export const selectCcdbGroupsFetchError = (state: RootState) => state.ccdbGroupsReducer.fetchError;
export const selectCcdbGroupsPostError = (state: RootState) => state.ccdbGroupsReducer.postError;
