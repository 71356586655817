import React from 'react';
import InstitutionType from 'src/types/models/InstitutionType';
import InstitutionTypeCreateEditModal from '../shared/InstitutionTypeCreateEditModal';
import useEditInstitutionType from './hooks/useEditInstitutionTypes';

interface Props {
    institutionType: InstitutionType;
}

const InstitutionTypeEditModal: React.FC<Props> = ({ institutionType }) => {
    const { initialFormState, handleSubmit } = useEditInstitutionType(institutionType);

    return (
        <InstitutionTypeCreateEditModal
            onSubmit={handleSubmit}
            initialFormState={initialFormState}
            title="Edit Institution Type"
            redirectURL={`/institution-types/${institutionType.id}`}
        />
    );
};

export default InstitutionTypeEditModal;
