import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { ActivityGroup } from '../../../types/models/ActivityGroups';

export const updateActivityGroupRequest = createAction('updateActivityGroupRequest');
export const updateActivityGroupSuccess = createAction<ActivityGroup>('updateActivityGroupSuccess');
export const updateActivityGroupFailure = createAction('updateActivityGroupFailure');

export const updateActivityGroup =
    (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(updateActivityGroupRequest());

        try {
            const { data } = await api.put<PostBody, ActivityGroup>(
                `activitygroups/${id}?id=${id}`,
                postBody,
            );

            dispatch(updateActivityGroupSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateActivityGroupFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
}
