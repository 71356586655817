import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { CcdbGroup } from '../../../types/models/Ccdb';

export const restoreCcdbGroupRequest = createAction('restoreCcdbGroupRequest');
export const restoreCcdbGroupSuccess = createAction<CcdbGroup>('restoreCcdbGroupSuccess');
export const restoreCcdbGroupFailure = createAction('restoreCcdbGroupFailure');

export const restoreCcdbGroup = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(restoreCcdbGroupRequest());

    try {
        const { data } = await api.patch<object, CcdbGroup>(`CCDBGroups/${id}`, {});

        dispatch(restoreCcdbGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, restoreCcdbGroupFailure, e as APIError);
    }
};
