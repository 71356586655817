import { WithKey } from 'src/types/shared/helpers';

export function isEmpty(item: any): boolean {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

function isObjEmpty(obj: Record<string, unknown>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj<T>(arr: T[], field = 'id'): Record<string, T> {
    return arr.reduce<Record<string, T>>((acc, item) => {
        // todo
        //@ts-ignore
        acc[item[field]] = item;
        return acc;
    }, {});
}

export function checkIsFilenameImage(name: string) {
    const isImage = /heif|heic|jpg|jpeg|png|gif/.test(name.toLowerCase().trim());
    return isImage;
}

export function bytesToMB(bytes: number) {
    return bytes / 1024 / 1024;
}

export function truncateString(str: string, length: number, ending = '') {
    if (str.length <= length) return str;
    return str.substring(0, length - ending.length) + ending;
}

export const withKeys = <T, K extends string = 'key'>(
    objs: T[],
    keyName?: K,
    startingNumber = 0,
): WithKey<T, K>[] =>
    objs.map((obj, i) => ({ ...obj, [keyName ?? 'key']: i + startingNumber } as WithKey<T, K>));
