import { RootState } from '@reducers/index';

export const getSchoolGroups = (state: RootState) =>
    Object.values(state.schoolGroupsReducer.schoolGroups);
export const getSchoolGroup = (state: RootState, id: number) =>
    state.schoolGroupsReducer.schoolGroups[id];

export const getSchoolGroupsIsFetching = (state: RootState) => state.schoolGroupsReducer.isFetching;
export const getSchoolGroupsFetchError = (state: RootState) => state.schoolGroupsReducer.fetchError;
export const getSchoolGroupsPostError = (state: RootState) => state.schoolGroupsReducer.postError;

export const getSchoolGroupsIsPosting = (state: RootState) => state.schoolGroupsReducer.isPosting;
export const getSchoolGroupsPostSuccess = (state: RootState) =>
    state.schoolGroupsReducer.postSuccess;
