import { useParams } from 'react-router-dom';

import usePrevious from '@hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteActivityGroup } from '@actions/activityGroups';
import { selectActivitiesIsPosting } from '@selectors/activities';
import {
    selectActivityGroupsPostError,
    selectActivityGroupsPostSuccess,
} from '@selectors/activityGroups';
import useRedirect from '@hooks/useRedirect';

const useDeleteActivityGroup = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(selectActivitiesIsPosting);
    const error = useSelector(selectActivityGroupsPostError);
    const success = useSelector(selectActivityGroupsPostSuccess);
    const handleSubmit = () => {
        dispatch(deleteActivityGroup(+id));
    };

    const closeModal = useRedirect(`/activity-groups/${id}`);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteActivityGroup;
