import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import Institution from 'src/types/models/Institution';

export const updateInstitutionTypeRequest = createAction('updateInstitutionTypeRequest');
export const updateInstitutionTypeSuccess = createAction<Institution>(
    'updateInstitutionTypeSuccess',
);
export const updateInstitutionTypeFailure = createAction('updateInstitutionTypeFailure');

export const updateInstitutionType =
    (id: number, postbody: unknown) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateInstitutionTypeRequest());
        try {
            const { data } = await api.put<unknown, Institution>(
                `institutionTypes/${id}`,
                postbody,
            );

            dispatch(updateInstitutionTypeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateInstitutionTypeFailure, e as APIError);
        }
    };
