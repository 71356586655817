import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import School from '../../../types/models/School';

export const validateSchoolStep1Request = createAction('validateSchoolStep1Request');
export const validateSchoolStep1Success = createAction<School>('validateSchoolStep1Success');
export const validateSchoolStep1Failure = createAction('validateSchoolStep1Failure');

export const validateSchoolStep1 =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(validateSchoolStep1Request());
        try {
            const { data } = await api.post<PostBody, School>(
                `schools/Validate/CoreSchoolDetails`,
                postbody,
            );

            dispatch(validateSchoolStep1Success(data));
        } catch (e) {
            handleApiErrors(dispatch, validateSchoolStep1Failure, e as APIError);
        }
    };

interface PostBody {
    name: string;
    website: string | null;
    email: string | null;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    country: string;
}
