import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { api, handleApiErrors } from '@utils/api';
import { APIError } from '../../../types/shared/APIError';
import { ActivityHistory } from 'src/types/models/ActivityHistories';

export const applyActivitiesToUsersRequest = createAction('applyActivitiesToUsersRequest');
export const applyActivitiesToUsersSuccess = createAction<ActivityHistory>(
    'applyActivitiesToUsersSuccess',
);
export const applyActivitiesToUsersFailure = createAction('applyActivitiesToUsersFailure');

export const applyActivitiesToUsers =
    (ccdbGroupID: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(applyActivitiesToUsersRequest());

        try {
            const { data } = await api.post<PostBody, ActivityHistory>(
                `ccdbGroups/${ccdbGroupID}/ApplyActivities`,
                postBody,
            );
            dispatch(applyActivitiesToUsersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, applyActivitiesToUsersFailure, e as APIError);
        }
    };

interface PostBody {
    userIDs: number[];
    activityIDs: number[];
    activityLocation: string;
    activityDate: Date | null;
}
