import React from 'react';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from '@components/button/LinkButton';
import Title from '@components/typography/Title';
import SchoolResponse from 'src/types/models/School';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ButtonRow from '@components/button/ButtonRow';
import SchoolLogo from '@components/misc/SchoolLogo';
import { humanizeEnumName } from '@utils/enums';
import { getCountryName, getCountryNames } from '@utils/countries';
import useSchool from './hooks/useSchool';

interface Props {
    school: SchoolResponse;
}

const School: React.FC<Props> = ({ school }) => {
    const { activitiesInterestedInList, activitiesInvolvedInList } = useSchool(school);

    return (
        <>
            <CreateHeader>
                <Title>School</Title>
                <ButtonRow alignment="left">
                    <LinkButton source="secondary" href={`/schools/${school.id}/edit`}>
                        Edit
                    </LinkButton>
                    <LinkButton source="negative" href={`/schools/${school.id}/delete`}>
                        Delete
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{school.name}</p>
                    </ContentItem>
                    {!!school.schoolPhotographS3Key && (
                        <ContentItem label="">
                            <SchoolLogo s3Key={school.schoolPhotographS3Key} />
                        </ContentItem>
                    )}
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Website">
                        <p>{school.website || '-'}</p>
                    </ContentItem>
                    <ContentItem label="Email">
                        <p>{school.email || '-'}</p>
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Address">
                        {[
                            school.addressLine1,
                            school.addressLine2,
                            school.city,
                            getCountryName(school.country),
                        ]
                            .filter(x => x)
                            .map((x, i) => (
                                <p key={`${x}_{i}`}>{x}</p>
                            ))}
                    </ContentItem>
                    <ContentItem label="Postcode">
                        <p>{school.postcode || '-'}</p>
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="UK Region">
                        <p>{school.ukRegion || '-'}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Head Teacher">
                        <p>{school.headTeacher || '-'}</p>
                    </ContentItem>
                    <ContentItem label="School Group">
                        <p>{school.schoolGroup || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Type">
                        <p>{school.schoolTypes.map(humanizeEnumName).join(', ')}</p>
                    </ContentItem>
                    <ContentItem label="Gender">
                        <p>{humanizeEnumName(school.schoolGender)}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Provisions For Special Educational Needs">
                        <p>{humanizeEnumName(school.provisionsForSpecialEducationalNeeds)}</p>
                    </ContentItem>
                    <ContentItem label="Status">
                        <p>{humanizeEnumName(school.schoolStatus)}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Facebook ID">
                        <p>{school.facebookID || '-'}</p>
                    </ContentItem>
                    <ContentItem label="Twitter ID">
                        <p>{school.twitterID || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Has Video Conferencing?">
                        <p>{school.hasVideoConferencing ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label="Has SMS?">
                        <p>{school.hasSMS ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Has IT?">
                        <p>{school.hasIT ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label="Has Email?">
                        <p>{school.hasEmail ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="International Coordinator?">
                        <p>{school.internationalCoordinator || '-'}</p>
                    </ContentItem>
                    <ContentItem label="Number of Pupils">
                        <p>{school.numberOfPupils || ''}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Whole School Partnership Preferences">
                        <p>{humanizeEnumName(school.wholeSchoolPartnershipPreferences)}</p>
                    </ContentItem>
                    <ContentItem label="Partner School Interest Locations">
                        <p>{getCountryNames(school.partnerSchoolInterestLocations) || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="CurrentPartner School Locations">
                        <p>{getCountryNames(school.currentPartnerSchoolLocations) || '-'}</p>
                    </ContentItem>
                    <ContentItem label="Activities Interested In">
                        <p>{activitiesInterestedInList?.join(', ') || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Partners Found Via Schools Online">
                        <p>{humanizeEnumName(school.partnersFoundViaSchoolsOnline)}</p>
                    </ContentItem>
                    <ContentItem label="Activities Involved In">
                        <p>{activitiesInvolvedInList?.join(', ') || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Partner School Suggested">
                        <p>{humanizeEnumName(school.partnerSchoolSuggested)}</p>
                    </ContentItem>
                    <ContentItem label="Interested In International School Award">
                        <p>{humanizeEnumName(school.interestedInInternationalSchoolAward)}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Source">
                        <p>{humanizeEnumName(school.source)}</p>
                    </ContentItem>
                    <ContentItem label="Unique ID">
                        <p>{humanizeEnumName(school.uniqueID)}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="School Area">
                        <p>{humanizeEnumName(school.schoolArea)}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
        </>
    );
};

export default School;
