import School from './../../../types/models/School';
import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchSchoolRequest = createAction('fetchSchoolRequest');
export const fetchSchoolSuccess = createAction<School>('fetchSchoolSuccess');
export const fetchSchoolFailure = createAction('fetchSchoolFailure');

export const fetchSchool =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSchoolRequest());
        try {
            const { data } = await api.get<School>(`schools/${id}`);
            dispatch(fetchSchoolSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSchoolFailure, e as APIError);
        }
    };
