import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { ActivityGroup } from '../../../types/models/ActivityGroups';

export const fetchSingleActivityGroupByActivityIDRequest = createAction(
    'fetchSingleActivityGroupByActivityIDRequest',
);
export const fetchSingleActivityGroupByActivityIDSuccess = createAction<ActivityGroup>(
    'fetchSingleActivityGroupByActivityIDSuccess',
);
export const fetchSingleActivityGroupByActivityIDFailure = createAction(
    'fetchSingleActivityGroupByActivityIDFailure',
);

export const fetchSingleActivityGroupByActivityID =
    (activityID: number) => async (dispatch: AppDispatch) => {
        dispatch(fetchSingleActivityGroupByActivityIDRequest());

        try {
            const { data } = await api.get<ActivityGroup>(`activities/${activityID}/activitygroup`);

            dispatch(fetchSingleActivityGroupByActivityIDSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSingleActivityGroupByActivityIDFailure, e as APIError);
        }
    };
