import CreateHeader from '@components/layout/createHeader/CreateHeader';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';
import Title from '@components/typography/Title';

import { Activity as ActivityModel } from 'src/types/models/Activities';
import { ActivityGroup } from 'src/types/models/ActivityGroups';
import { Link } from 'react-router-dom';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';

interface Props {
    activity: ActivityModel;
    activityGroup: ActivityGroup;
}

const Activity = ({ activity, activityGroup }: Props) => {
    return (
        <>
            <CreateHeader>
                <Title>Activity - {activity.name}</Title>
            </CreateHeader>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{activity.name}</p>
                    </ContentItem>
                    <ContentItem label="Activity group">
                        <p>
                            <Link to={`/activity-groups/${activityGroup.id}`}>
                                {activityGroup.name}
                            </Link>
                        </p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Activity code">
                        <p>{activity.activityCode || '-'}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
            <ButtonRow alignment="left">
                <LinkButton source="secondary" href={`/activities/${activity.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton source="negative" href={`/activities/${activity.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        </>
    );
};

export default Activity;
