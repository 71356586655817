import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { UsersResponse } from 'src/types/models/User';
import { APITableParams } from 'src/types/shared/table';

export const fetchUsersForCcdbGroupRequest = createAction('fetchUsersForCcdbGroupRequest');
export const fetchUsersForCcdbGroupSuccess = createAction<UsersResponse>(
    'fetchUsersForCcdbGroupSuccess',
);
export const fetchUsersForCcdbGroupFailure = createAction('fetchUsersForCcdbGroupFailure');

export const fetchUsersForCcdbGroup =
    (ccdbGroupID: number, params: APITableParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchUsersForCcdbGroupRequest());
        try {
            const { data } = await api.get<UsersResponse>(`users/ccdbgroup/${ccdbGroupID}`, params);

            dispatch(fetchUsersForCcdbGroupSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchUsersForCcdbGroupFailure, e as APIError);
        }
    };
