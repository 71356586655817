import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const mergeSchoolsRequest = createAction('mergeSchoolsRequest');
export const mergeSchoolsSuccess = createAction('mergeSchoolsSuccess');
export const mergeSchoolsFailure = createAction('mergeSchoolsFailure');

export const mergeSchools =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(mergeSchoolsRequest());
        try {
            await api.patch<PostBody, unknown>(`schools/merge`, postbody);

            dispatch(mergeSchoolsSuccess());
        } catch (e) {
            handleApiErrors(dispatch, mergeSchoolsFailure, e as APIError);
        }
    };

interface PostBody {
    sourceSchoolID: number;
    duplicateSchoolIDs: number[];

    nameSourceID: number;
    websiteSourceID: number;
    emailSourceID: number;
    countrySourceID: number;
    addressLine1SourceID: number;
    addressLine2SourceID: number;
    citySourceID: number;
    postcodeSourceID: number;
    schoolAreaSourceID: number;

    schoolPhotographS3KeySourceID: number;
    headTeacherSourceID: number;
    schoolGroupSourceID: number;
    schoolTypesSourceID: number;
    schoolGenderSourceID: number;
    provisionsForSpecialEducationalNeedsSourceID: number;
    schoolStatusSourceID: number;
    numberOfPupilsSourceID: number;
    facebookIDSourceID: number;
    twitterIDSourceID: number;
    hasVideoConferencingSourceID: number;
    hasSMSSourceID: number;
    hasITSourceID: number;
    hasEmailSourceID: number;
    internationalCoordinatorSourceID: number;

    wholeSchoolPartnershipPreferencesSourceID: number;
    partnerSchoolInterestLocationsSourceID: number;
    currentPartnerSchoolLocationsSourceID: number;
    activitiesInterestedInSourceID: number;
    activitiesInvolvedInSourceID: number;
    partnersFoundViaSchoolsOnlineSourceID: number;
    schoolsOnlineProvidedUsefulInformationSourceID: number;
    partnerSchoolSuggestedSourceID: number;
    interestedInInternationalSchoolAwardSourceID: number;

    sourceSourceID: number;
}
