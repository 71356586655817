import useFetchUsersForCcdbGroup from '@pages/ccdbGroups/ccdbGroup/users/hooks/useFetchUsersForCcdbGroup';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';

import { TableColumns } from '../../../../types/shared/table';
import User from 'src/types/models/User';
import ApiFilterTable from '@components/table/ApiFilterTable';

const CcdbGroupUsers = () => {
    const { ccdbGroupID, isFetching, error, users, itemCount, tableRef, handleFetch } =
        useFetchUsersForCcdbGroup();

    return (
        <ContentBlock>
            <CreateHeader>
                Users
                <ButtonRow>
                    <LinkButton size="small" href={`/ccdb-groups/${ccdbGroupID}/add-users`}>
                        Add users
                    </LinkButton>
                    <LinkButton
                        size="small"
                        href={`/ccdb-groups/${ccdbGroupID}/remove-users`}
                        source="negative"
                    >
                        Remove users
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <ApiFilterTable
                ref={tableRef}
                isLoading={isFetching}
                error={error}
                rows={users}
                columns={columns}
                totalItems={itemCount}
                fetchData={handleFetch}
            />
        </ContentBlock>
    );
};

const columns: TableColumns<User> = [
    {
        key: 1,
        heading: 'Title',
        getValue: row => row.title || 'N/A',
        searchable: true,
    },
    {
        key: 2,
        heading: 'Name',
        getValue: user => `${user.firstName} ${user.lastName}`,
        searchable: true,
    },
    {
        key: 3,
        heading: 'Email',
        getValue: row => row.email,
        getSort: (a, b) => {
            const aEmail = a.email || '';
            const bEmail = b.email || '';
            return aEmail.localeCompare(bEmail);
        },
        searchable: true,
    },
    {
        key: 5,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/users/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

export default CcdbGroupUsers;
