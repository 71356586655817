import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ParticipantData from '@pages/participantData/ParticipantData';
import DefaultRedirect from './DefaultRedirect';

const ParticipantDataRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ParticipantData />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default ParticipantDataRoutes;
