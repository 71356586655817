import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const fetchActivitiesRequest = createAction('fetchActivitiesRequest');
export const fetchActivitiesSuccess = createAction<Activity[]>('fetchActivitiesSuccess');
export const fetchActivitiesFailure = createAction('fetchActivitiesFailure');

export const fetchActivities = () => async (dispatch: AppDispatch) => {
    dispatch(fetchActivitiesRequest());

    try {
        const { data } = await api.get<Activity[]>('activities');

        dispatch(fetchActivitiesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchActivitiesFailure, e as APIError);
    }
};
