import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../redux/store';

import { fetchSingleAdminUser } from '@actions/adminUsers/fetchSingleAdminUser';
import {
    getAdminUser,
    getAdminUsersError,
    // getAdminUsersError
} from '@selectors/adminUsers';

import AdminUser from './AdminUser';

import UpdateAdminPasswordModal from './UpdateAdminPasswordModal';
import DeleteAdminModal from './DeleteAdminModal';
import UpdateAdminModal from './UpdateAdminModal';
import DataCheck from '@components/common/DataCheck';
interface Props {
    showDeleteModal?: boolean;
    showEditModal?: boolean;
    showEditPasswordModal?: boolean;
}

const AdminUserContainer: React.FC<Props> = ({
    showDeleteModal = false,
    showEditModal = false,
    showEditPasswordModal = false,
}) => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string; action?: string }>();

    useEffect(() => {
        dispatch(fetchSingleAdminUser(+id));
    }, [id, dispatch]);

    const error = useSelector(getAdminUsersError);
    const admin = useAppSelector(state => getAdminUser(state, parseInt(id)));

    return (
        <>
            <DataCheck error={error} dataExists={!!admin} isFetching>
                <AdminUser user={admin} />
            </DataCheck>
            {!!admin && showDeleteModal && <DeleteAdminModal user={admin} />}
            {!!admin && showEditPasswordModal && <UpdateAdminPasswordModal user={admin} />}
            {!!admin && showEditModal && <UpdateAdminModal user={admin} />}
        </>
    );
};

export default AdminUserContainer;
