import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@hooks/usePrevious';
import {
    getInstitutionTypesIsPosting,
    getInstitutionTypesPostError,
    getInstitutionTypesPostSuccess,
} from '@selectors/institutionTypes';
import { useCallback, useEffect } from 'react';
import useForm from '@hooks/useForm';

import { InstitutionTypeCreateEditFormState } from '@pages/institutionTypes/shared/types/InstitutionTypeCreateEditFormStep';

interface Options {
    onSubmit: (formState: InstitutionTypeCreateEditFormState) => void;
    initialFormState: InstitutionTypeCreateEditFormState;
    redirectURL: string;
}

const useCreateEditInstitutionType = ({ onSubmit, initialFormState, redirectURL }: Options) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isPosting = useSelector(getInstitutionTypesIsPosting);
    const error = useSelector(getInstitutionTypesPostError);
    const success = useSelector(getInstitutionTypesPostSuccess);
    const [formState, handleChange] = useForm(initialFormState);

    const handleSubmit = () => {
        onSubmit(formState);
    };

    const closeModal = useCallback(() => {
        history.replace(redirectURL);
    }, [history, redirectURL]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [dispatch, prevSuccess, success, closeModal]);

    return {
        closeModal,
        handleSubmit,
        isPosting,
        error,
        formState,
        handleChange,
    };
};

export default useCreateEditInstitutionType;
