import { createInstitution } from '../../../../redux/actions/institutions/createInstitution';
import { useDispatch } from 'react-redux';
import { InstitutionCreateEditFormState } from '@pages/institutions/shared/types/InstitutionCreateEditFormStep';

const initialFormState: InstitutionCreateEditFormState = {
    name: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    institutionTypeID: null,
};

const useCreateInstitution = () => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: InstitutionCreateEditFormState) => {
        if (formState.institutionTypeID == null) return;
        dispatch(createInstitution(formState));
    };

    return {
        initialFormState,
        handleSubmit,
    };
};

export default useCreateInstitution;
