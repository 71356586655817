import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UserContainer from '@pages/users/user/UserContainer';
import Users from '@pages/users/users/Users';
import DefaultRedirect from './DefaultRedirect';

const UsersRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Users />
            </Route>
            <Route exact path={`${path}/create`}>
                <Users showCreateModal />
            </Route>
            <Route exact path={`${path}/import`}>
                <Users showImportModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <UserContainer />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <UserContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <UserContainer showDeleteModal />
            </Route>
            <Route exact path={`${path}/:id/activities/:activityID/delete`}>
                <UserContainer showDeleteActivityModal />
            </Route>
            <Route exact path={`${path}/:id/activities/:activityID/edit`}>
                <UserContainer showEditActivityModal />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default UsersRoutes;
