import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchSearchedUsersRequest,
    fetchSearchedUsersSuccess,
    fetchSearchedUsersFailure,
} from '@actions/users/fetchSearchedUsers';
import User, { UsersResponse } from 'src/types/models/User';

interface UsersState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    itemCount: number;
    users: User[];
}

const initialState: UsersState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    itemCount: 0,
    users: [],
};

export default createReducer(initialState, {
    [fetchSearchedUsersRequest.type]: handleFetchRequest,
    [fetchSearchedUsersSuccess.type]: handleFetchSearchedUsersSuccess,
    [fetchSearchedUsersFailure.type]: handleFetchError,
});

function handleFetchRequest(state: UsersState) {
    state.isFetching = true;
}

function handleFetchSearchedUsersSuccess(state: UsersState, action: PayloadAction<UsersResponse>) {
    state.users = action.payload.items;
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handleFetchError(state: UsersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
