import { ApiFilterTableHandle } from '@components/table/ApiFilterTable';
import { useCallback, useState } from 'react';
import School from 'src/types/models/School';

const useMergeSchoolsModal = (schoolsTableRef: React.RefObject<ApiFilterTableHandle>) => {
    const [selectedSchools, setSelectedSchools] = useState<School[]>([]);

    const [showMergeModal, setShowMergeModal] = useState(false);

    const selectSchool = (school: School) => {
        setSelectedSchools(prev => prev.concat(school));
    };

    const deselectSchool = (id: number) => {
        setSelectedSchools(prev => prev.filter(school => school.id !== id));
    };

    const toggleMergeModal = () => {
        setShowMergeModal(!showMergeModal);
    };

    const handleMergeSuccess = useCallback(() => {
        schoolsTableRef.current?.refreshData();

        setSelectedSchools([]);
        setShowMergeModal(false);
    }, [schoolsTableRef]);

    return {
        selectedSchools,
        selectSchool,
        deselectSchool,
        showMergeModal,
        toggleMergeModal,
        handleMergeSuccess,
    };
};

export default useMergeSchoolsModal;
