import Title from '@components/typography/Title';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import React, { useMemo } from 'react';
import useFetchSchools from './hooks/useFetchSchools';
import ApiFilterTable from '@components/table/ApiFilterTable';
import { TableColumns } from 'src/types/shared/table';
import MultiSelect from '@components/form/MultiSelect';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import School from 'src/types/models/School';
import SchoolCreateModal from './SchoolCreateModal';
import { formatRegionOptions, getCountryName } from '@utils/countries';
import { truncateString } from '@utils/generic';
import ActionButton from '@components/button/ActionButton';
import useMergeSchoolsModal from './hooks/useMergeSchoolsModal';
import SchoolsToMerge from './SchoolsToMerge';
import SchoolsMergeModal from './mergeSchools/SchoolsMergeModal';
import { humanizeEnumNames } from '@utils/enums';
import useFetchRegions from '@hooks/useFetchRegions';
import Select from '@components/form/Select';
import CountrySelect from '@components/form/CountrySelect';

interface Props {
    showCreateModal?: boolean;
}

const Schools: React.FC<Props> = ({ showCreateModal = false }) => {
    const {
        isFetching,
        fetchError,
        schools,
        handleFetch,
        formState,
        handleChange,
        tableRef,
        itemCount,
        schoolGroupOptions,
    } = useFetchSchools();

    const {
        selectedSchools,
        selectSchool,
        deselectSchool,
        showMergeModal,
        toggleMergeModal,
        handleMergeSuccess,
    } = useMergeSchoolsModal(tableRef);

    const columns = useColumns(selectSchool, selectedSchools);
    const { regions } = useFetchRegions();

    const regionOptions = formatRegionOptions(regions);

    return (
        <>
            <CreateHeader>
                <Title>Schools</Title>
                <ButtonRow>
                    <LinkButton source="positive" icon="plus" href="schools/create">
                        Create
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <SchoolsToMerge
                selectedSchools={selectedSchools}
                deselectSchool={deselectSchool}
                openModal={toggleMergeModal}
            />

            <div className="table-filters">
                <MultiSelect
                    search
                    name="schoolGroupIDs"
                    value={formState.schoolGroupIDs}
                    options={schoolGroupOptions}
                    onChange={handleChange}
                    label="School Groups"
                    placeholder="All"
                />

                <CountrySelect
                    name="countrySearchTerm"
                    value={formState.countrySearchTerm}
                    onChange={handleChange}
                    label="Country"
                    placeholder="All"
                />

                <Select
                    search
                    name="regionSearchTerm"
                    value={formState.regionSearchTerm}
                    options={regionOptions}
                    onChange={handleChange}
                    label="UK Region"
                    placeholder="All"
                />
            </div>

            <ApiFilterTable
                ref={tableRef}
                isLoading={isFetching}
                error={fetchError}
                rows={schools}
                columns={columns}
                totalItems={itemCount}
                fetchData={handleFetch}
            />
            {showCreateModal && <SchoolCreateModal />}
            {showMergeModal && (
                <SchoolsMergeModal
                    handleSuccess={handleMergeSuccess}
                    closeModal={toggleMergeModal}
                    schoolsToMerge={selectedSchools}
                />
            )}
        </>
    );
};

const useColumns = (selectSchool: (school: School) => void, selectedSchools: School[]) => {
    const selectedSchoolIDs = useMemo(() => selectedSchools.map(s => s.id), [selectedSchools]);

    const columns: TableColumns<School> = [
        {
            key: 0,
            heading: 'ID',
            getValue: row => row.id,
        },
        {
            key: 1,
            heading: 'Name',
            getValue: row => row.name,
        },
        {
            key: 2,
            heading: 'Unique ID',
            getValue: row => row.uniqueID,
        },
        {
            key: 3,
            heading: 'Type',
            getValue: row => humanizeEnumNames(row.schoolTypes),
        },
        {
            key: 4,
            heading: 'Address',
            getValue: row =>
                truncateString(
                    [row.addressLine1, row.addressLine2, row.city].filter(x => x).join(', '),
                    30,
                    '...',
                ),
        },
        {
            key: 5,
            heading: 'Country',
            getValue: row => getCountryName(row.country),
        },
        {
            key: 6,
            heading: 'UK Region',
            getValue: row => row.ukRegion || 'N/A',
        },
        {
            key: 8,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    <ActionButton
                        disabled={selectedSchoolIDs.includes(row.id)}
                        source="secondary"
                        onClick={() => selectSchool(row)}
                    >
                        Merge
                    </ActionButton>
                    <LinkButton size="small" href={`/schools/${row.id}`}>
                        View
                    </LinkButton>
                </ButtonRow>
            ),
        },
    ];

    return columns;
};

export default Schools;
