import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import SchoolGroup from 'src/types/models/SchoolGroup';

export const updateSchoolGroupRequest = createAction('updateSchoolRequest');
export const updateSchoolGroupSuccess = createAction<SchoolGroup>('updateSchoolSuccess');
export const updateSchoolGroupFailure = createAction('updateSchoolFailure');

export const updateSchoolGroup =
    (id: number, postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(updateSchoolGroupRequest());
        try {
            const { data } = await api.put<PostBody, SchoolGroup>(`schoolGroups/${id}`, postbody);

            dispatch(updateSchoolGroupSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateSchoolGroupFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
}
