import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import School, { SchoolSource } from '../../../types/models/School';

export const validateSchoolStep4Request = createAction('validateSchoolStep4Request');
export const validateSchoolStep4Success = createAction<School>('validateSchoolStep4Success');
export const validateSchoolStep4Failure = createAction('validateSchoolStep4Failure');

export const validateSchoolStep4 =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(validateSchoolStep4Request());
        try {
            const { data } = await api.post<PostBody, School>(
                `schools/Validate/PartnershipPreferences`,
                postbody,
            );

            dispatch(validateSchoolStep4Success(data));
        } catch (e) {
            handleApiErrors(dispatch, validateSchoolStep4Failure, e as APIError);
        }
    };

interface PostBody {
    source: SchoolSource | null;
    uniqueID: string | null;
}
