import useFetchActivityGroups from './hooks/useFetchActivityGroups';

import Title from '@components/typography/Title';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';

import { TableColumns } from '../../../types/shared/table';
import { ActivityGroup } from '../../../types/models/ActivityGroups';
import CreateActivityGroupModal from './CreateActivityGroupModal';
import Table from '@components/table/Table';

interface Props {
    showCreateModal?: boolean;
}

const ActivityGroups = ({ showCreateModal = false }: Props) => {
    const { activityGroups, isFetching, error } = useFetchActivityGroups();

    return (
        <>
            <CreateHeader>
                <Title>Activity Groups</Title>
                <LinkButton href="/activity-groups/create">Create</LinkButton>
            </CreateHeader>

            <Table columns={columns} isLoading={isFetching} error={error} rows={activityGroups} />

            {showCreateModal && <CreateActivityGroupModal />}
        </>
    );
};

const columns: TableColumns<ActivityGroup> = [
    {
        key: 0,
        heading: 'Name',
        getValue: row => row.name,
        searchable: true,
        getSort: (a, b) => a.name.localeCompare(b.name),
    },
    {
        key: 1,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/activity-groups/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

export default ActivityGroups;
