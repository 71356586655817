import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import Institutions from '@pages/institutions/institutions/Institutions';
import InstitutionContainer from '@pages/institutions/institution/InstitutionContainer';

const InstitutionsRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Institutions />
            </Route>
            <Route exact path={`${path}/create`}>
                <Institutions showCreateModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <InstitutionContainer />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <InstitutionContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <InstitutionContainer showDeleteModal />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default InstitutionsRoutes;
