import { updateInstitution } from '@actions/institutions';
import { InstitutionCreateEditFormState } from '@pages/institutions/shared/types/InstitutionCreateEditFormStep';
import { useDispatch } from 'react-redux';
import Institution from '../../../../types/models/Institution';

const useEditInstitution = (institution: Institution) => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: InstitutionCreateEditFormState) => {
        dispatch(updateInstitution(institution.id, formState));
    };

    return {
        initialFormState: getInitialState(institution),
        handleSubmit,
    };
};

const getInitialState = (institution: Institution) => {
    const formState: InstitutionCreateEditFormState = {
        name: institution.name,
        addressLine1: institution.addressLine1,
        addressLine2: institution.addressLine2 || '',
        city: institution.city,
        country: institution.country,
        institutionTypeID: institution.institutionTypeID,
    };
    return formState;
};

export default useEditInstitution;
