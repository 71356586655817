import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';

export const deleteActivityGroupRequest = createAction('deleteActivityGroupRequest');
export const deleteActivityGroupSuccess = createAction<number>('deleteActivityGroupSuccess');
export const deleteActivityGroupFailure = createAction('deleteActivityGroupFailure');

export const deleteActivityGroup = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteActivityGroupRequest());

    try {
        await api.delete(`activitygroups/${id}`);

        dispatch(deleteActivityGroupSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deleteActivityGroupFailure, e as APIError);
    }
};
