import { SchoolOption } from './../../types/models/School';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';
import {
    deleteSchoolFailure,
    deleteSchoolRequest,
    deleteSchoolSuccess,
    fetchSchoolsFailure,
    fetchSchoolsRequest,
    fetchSchoolsSuccess,
    fetchSchoolFailure,
    fetchSchoolRequest,
    fetchSchoolSuccess,
    fetchSchoolOptionsRequest,
    fetchSchoolOptionsSuccess,
    fetchSchoolOptionsFailure,
    createSchoolRequest,
    createSchoolSuccess,
    createSchoolFailure,
    validateSchoolStep1Request,
    validateSchoolStep1Success,
    validateSchoolStep1Failure,
    validateSchoolStep2Request,
    validateSchoolStep2Success,
    validateSchoolStep2Failure,
    validateSchoolStep3Request,
    validateSchoolStep3Success,
    validateSchoolStep3Failure,
    validateSchoolStep4Request,
    validateSchoolStep4Success,
    validateSchoolStep4Failure,
    updateSchoolFailure,
    updateSchoolRequest,
    updateSchoolSuccess,
    mergeSchoolsFailure,
    mergeSchoolsRequest,
    mergeSchoolsSuccess,
    FetchSchoolsResponse,
} from '@actions/schools';
import School from 'src/types/models/School';

interface SchoolsState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    fetchError: string | null;
    postError: string | null;
    schools: Record<number, School>;
    itemCount: number;
    options: SchoolOption[];
}

const initialState: SchoolsState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    fetchError: null,
    postError: null,
    schools: {},
    itemCount: 0,
    options: [],
};

export default createReducer(initialState, {
    [fetchSchoolsRequest.type]: handleFetchRequest,
    [fetchSchoolsSuccess.type]: handleFetchAllSuccess,
    [fetchSchoolsFailure.type]: handleFetchError,
    [fetchSchoolRequest.type]: handleFetchRequest,
    [fetchSchoolSuccess.type]: handleFetchSingleSuccess,
    [fetchSchoolFailure.type]: handleFetchError,
    [fetchSchoolOptionsRequest.type]: handleFetchRequest,
    [fetchSchoolOptionsSuccess.type]: handleFetchOptionsSuccess,
    [fetchSchoolOptionsFailure.type]: handleFetchError,
    [createSchoolRequest.type]: handlePostRequest,
    [createSchoolSuccess.type]: handlePostSuccess,
    [createSchoolFailure.type]: handlePostError,
    [validateSchoolStep1Request.type]: handlePostRequest,
    [validateSchoolStep1Success.type]: handleValidateSuccess,
    [validateSchoolStep1Failure.type]: handlePostError,
    [validateSchoolStep2Request.type]: handlePostRequest,
    [validateSchoolStep2Success.type]: handleValidateSuccess,
    [validateSchoolStep2Failure.type]: handlePostError,
    [validateSchoolStep3Request.type]: handlePostRequest,
    [validateSchoolStep3Success.type]: handleValidateSuccess,
    [validateSchoolStep3Failure.type]: handlePostError,
    [validateSchoolStep4Request.type]: handlePostRequest,
    [validateSchoolStep4Success.type]: handleValidateSuccess,
    [validateSchoolStep4Failure.type]: handlePostError,
    [updateSchoolRequest.type]: handlePostRequest,
    [updateSchoolSuccess.type]: handlePostSuccess,
    [updateSchoolFailure.type]: handlePostError,
    [mergeSchoolsRequest.type]: handlePostRequest,
    [mergeSchoolsSuccess.type]: handlePostSuccessNoUpdate,
    [mergeSchoolsFailure.type]: handlePostError,
    [deleteSchoolRequest.type]: handlePostRequest,
    [deleteSchoolFailure.type]: handlePostError,
    [deleteSchoolSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: SchoolsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: SchoolsState, action: PayloadAction<string>) {
    state.fetchError = action.payload;
    state.isFetching = false;
}

function handleFetchOptionsSuccess(state: SchoolsState, action: PayloadAction<SchoolOption[]>) {
    state.options = action.payload;
}

function handleFetchAllSuccess(state: SchoolsState, action: PayloadAction<FetchSchoolsResponse>) {
    state.schools = convertArrToObj(action.payload.items);
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handlePostRequest(state: SchoolsState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostError(state: SchoolsState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handlePostSuccess(state: SchoolsState, action: PayloadAction<School>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.schools[action.payload.id] = action.payload;
}

function handlePostSuccessNoUpdate(state: SchoolsState, action: PayloadAction) {
    state.isPosting = false;
    state.postSuccess = true;
}
function handleValidateSuccess(state: SchoolsState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFetchSingleSuccess(state: SchoolsState, action: PayloadAction<School>) {
    state.schools[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleDeleteSuccess(state: SchoolsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.schools[action.payload];
}
