import { useParams, useHistory } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useAppSelector } from '../../../../redux/store';

import {
    selectCcdbGroupsIsPosting,
    selectCcdbGroupsPostError,
    selectCcdbGroupsPostSuccess,
    selectSingleCcdbGroup,
} from '@selectors/ccdbGroups';

import { deleteCcdbGroup } from '@actions/ccdb';

const useDeleteSchoolGroup = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(selectCcdbGroupsIsPosting);
    const error = useSelector(selectCcdbGroupsPostError);
    const success = useSelector(selectCcdbGroupsPostSuccess);
    const ccdbGroup = useAppSelector(state => selectSingleCcdbGroup(state, +id));

    const handleSubmit = () => {
        dispatch(deleteCcdbGroup(+id));
    };

    const closeModal = useCallback(() => {
        history.replace('/ccdb-groups');
    }, [history]);

    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        ccdbGroup,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteSchoolGroup;
