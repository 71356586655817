import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@reducers/index';
import { selectActivityGroup, selectActivityGroupsFetchError } from '@selectors/activityGroups';
import { selectActivitiesIsFetching } from '@selectors/activities';
import { useEffect } from 'react';
import { fetchSingleActivityGroup } from '@actions/activityGroups';
import { useParams } from 'react-router-dom';

const useFetchActivityGroup = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const activityGroup = useSelector((state: RootState) => selectActivityGroup(state, +id));
    const isFetching = useSelector(selectActivitiesIsFetching);
    const error = useSelector(selectActivityGroupsFetchError);

    useEffect(() => {
        dispatch(fetchSingleActivityGroup(+id));
    }, [dispatch, id]);

    return { activityGroup, isFetching, error };
};

export default useFetchActivityGroup;
