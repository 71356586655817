import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { UsersResponse } from 'src/types/models/User';
import { APITableParams } from 'src/types/shared/table';

export const fetchAllUsersRequest = createAction('fetchAllUsersRequest');
export const fetchAllUsersSuccess = createAction<UsersResponse>('fetchAllUsersSuccess');
export const fetchAllUsersFailure = createAction('fetchAllUsersFailure');

export const fetchAllUsers =
    (params: APITableParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchAllUsersRequest());
        try {
            const { data } = await api.get<UsersResponse>('users', params);

            dispatch(fetchAllUsersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchAllUsersFailure, e as APIError);
        }
    };
