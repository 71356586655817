import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { ActivityGroup } from '../../../types/models/ActivityGroups';

export const restoreActivityGroupRequest = createAction('restoreActivityGroupRequest');
export const restoreActivityGroupSuccess = createAction<ActivityGroup>(
    'restoreActivityGroupSuccess',
);
export const restoreActivityGroupFailure = createAction('restoreActivityGroupFailure');

export const restoreActivityGroup = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(restoreActivityGroupRequest());

    try {
        const { data } = await api.patch<object, ActivityGroup>(`activitygroups/${id}`, {});

        dispatch(restoreActivityGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, restoreActivityGroupFailure, e as APIError);
    }
};
