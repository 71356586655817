import useCreateActivityGroup from '@pages/activityGroups/activityGroups/hooks/useCreateActivityGroup';

import FormField from '@components/form/FormField';
import TextInput from '@components/form/TextInput';
import Form from '@components/form/Form';
import Modal from '@components/modal/Modal';

const CreateActivityGroupModal = () => {
    const { form, handleChange, handleSubmit, isPosting, postError, closeModal } =
        useCreateActivityGroup();

    return (
        <Modal title="Create Activity Group">
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                error={postError}
                isPosting={isPosting}
            >
                <FormField name="name" label="Name">
                    <TextInput name="name" value={form.name} onChange={handleChange} />
                </FormField>
            </Form>
        </Modal>
    );
};

export default CreateActivityGroupModal;
