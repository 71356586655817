import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import {
    selectCcdbGroups,
    selectCcdbGroupsFetchError,
    selectCcdbGroupsIsFetching,
} from '@selectors/ccdbGroups';

import { fetchAllCcdbGroups } from '@actions/ccdb';

const useFetchCcdbGroups = () => {
    const dispatch = useDispatch();

    const isFetching = useSelector(selectCcdbGroupsIsFetching);
    const ccdbGroups = useSelector(selectCcdbGroups);
    const fetchError = useSelector(selectCcdbGroupsFetchError);

    const orderedCcdbGroups = useMemo(
        () =>
            Object.values(ccdbGroups).sort(
                (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
            ),
        [ccdbGroups],
    );

    useEffect(() => {
        dispatch(fetchAllCcdbGroups());
    }, [dispatch]);

    return {
        isFetching,
        orderedCcdbGroups,
        fetchError,
    };
};

export default useFetchCcdbGroups;
