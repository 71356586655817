import usePrevious from '@hooks/usePrevious';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { deleteInstitution } from '@actions/institutions';
import {
    getInstitution,
    getInstitutionsIsPosting,
    getInstitutionsPostSuccess,
    getInstitutionsPostError,
} from '@selectors/institutions';

const useDeleteInstitution = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(getInstitutionsIsPosting);
    const error = useSelector(getInstitutionsPostError);
    const success = useSelector(getInstitutionsPostSuccess);
    const institution = useAppSelector(state => getInstitution(state, +id));

    const handleSubmit = () => {
        dispatch(deleteInstitution(+id));
    };

    const closeModal = useCallback(() => {
        history.replace('/institutions');
    }, [history]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        institution,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteInstitution;
