import { Link } from 'react-router-dom';

import Logo from '@content/logos/logo.png';
import useDarkMode from '@hooks/useDarkMode';
import ToggleSwitch from '@components/form/ToggleSwitch';

const Header: React.FC = () => {
    const { darkMode, handleToggle } = useDarkMode();

    return (
        <header className="header">
            <div className="logo">
                <Link to="/" className="logo">
                    <img alt="company logo" src={Logo} />
                </Link>
            </div>
            <div className="dark-mode-switch">
                <ToggleSwitch
                    label="Dark mode"
                    name="darkmode"
                    onChange={handleToggle}
                    value={darkMode}
                    textColour="white"
                />
            </div>
        </header>
    );
};

export default Header;
