import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';

export const deleteCcdbGroupRequest = createAction('deleteCcdbGroupRequest');
export const deleteCcdbGroupSuccess = createAction<number>('deleteCcdbGroupSuccess');
export const deleteCcdbGroupFailure = createAction('deleteCcdbGroupFailure');

export const deleteCcdbGroup = (groupID: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteCcdbGroupRequest());

    try {
        await api.delete(`CCDBGroups/${groupID}`, {});

        dispatch(deleteCcdbGroupSuccess(groupID));
    } catch (e) {
        handleApiErrors(dispatch, deleteCcdbGroupFailure, e as APIError);
    }
};
