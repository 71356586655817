import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchSingleUser } from '@actions/users/fetchSingleUser';
import { getUser, getUsersError, getUsersIsFetching } from '@selectors/users';

import DeleteUserModal from './DeleteUserModal';
import UpdateUserModal from './UpdateUserModal';

import { RootState } from '@reducers/index';
import User from './User';
import UserActivities from '@pages/users/user/UserActivities';
import DataCheck from '@components/common/DataCheck';

interface Props {
    showDeleteModal?: boolean;
    showEditModal?: boolean;
    showDeleteActivityModal?: boolean;
    showEditActivityModal?: boolean;
}

const UserContainer: React.FC<Props> = ({
    showDeleteModal,
    showEditModal,
    showDeleteActivityModal,
    showEditActivityModal,
}) => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSingleUser(+id));
    }, [id, dispatch]);

    const error = useSelector(getUsersError);
    const isFetching = useSelector(getUsersIsFetching);
    const user = useSelector((state: RootState) => getUser(state, +id));

    return (
        <>
            <DataCheck error={error} isFetching={isFetching} dataExists={!!user}>
                <User user={user} />
                <br />
                <UserActivities
                    showDeleteActivityModal={showDeleteActivityModal}
                    showEditActivityModal={showEditActivityModal}
                />
            </DataCheck>
            {!!user && showDeleteModal && <DeleteUserModal user={user} />}
            {!!user && showEditModal && <UpdateUserModal user={user} />}
        </>
    );
};

export default UserContainer;
