import { fetchSingleActivityGroupByActivityID } from './../../../../redux/actions/activityGroups/fetchSingleActivityGroupByActivityID';
import { selectActivitiesFetchError } from '../../../../redux/selectors/activities';
import { selectActivityGroupsIsFetching } from '../../../../redux/selectors/activityGroups';
import { useAppSelector } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@reducers/index';
import { selectActivityGroup, selectActivityGroupsFetchError } from '@selectors/activityGroups';
import { selectActivitiesIsFetching, selectActivity } from '@selectors/activities';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchActivity } from '@actions/activities';

const useFetchActivity = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const activityID = +id;

    const activity = useAppSelector(state => selectActivity(state, activityID));
    const isFetchingActivity = useSelector(selectActivitiesIsFetching);
    const isFetchingGroup = useSelector(selectActivityGroupsIsFetching);

    const activityError = useSelector(selectActivitiesFetchError);
    const groupError = useSelector(selectActivityGroupsFetchError);

    const [cachedGroupID, setCachedGroupID] = useState<number>(0);
    useEffect(() => {
        if (activity?.activityGroupID) setCachedGroupID(activity.activityGroupID);
    }, [activity?.activityGroupID]);

    const activityGroup = useSelector((state: RootState) =>
        selectActivityGroup(state, cachedGroupID),
    );

    useEffect(() => {
        dispatch(fetchActivity(activityID));
        dispatch(fetchSingleActivityGroupByActivityID(activityID));
    }, [dispatch, activityID]);

    return {
        activity,
        activityGroup,
        isFetching: isFetchingActivity || isFetchingGroup,
        error: activityError || groupError,
    };
};

export default useFetchActivity;
