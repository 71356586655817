import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../../redux/store';
import useForm from '@hooks/useForm';
import usePrevious from '@hooks/usePrevious';
import { useEffect } from 'react';
import useRedirect from '@hooks/useRedirect';

import {
    selectActivitiesIsPosting,
    selectActivitiesPostError,
    selectActivitiesPostSuccess,
    selectUserActivity,
} from '@selectors/activities';
import { updateUserActivity } from '@actions/activities/updateUserActivity';

const useUpdateUserActivity = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { id, activityID } = useParams<{ id: string; activityID: string }>();

    const activity = useAppSelector(state => selectUserActivity(state, +activityID));
    const isPosting = useSelector(selectActivitiesIsPosting);
    const postSuccess = useSelector(selectActivitiesPostSuccess);
    const error = useSelector(selectActivitiesPostError);

    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm({
        activityLocation: activity?.activityLocation || '',
        activityDate: activity?.activityDate || '',
    });

    useEffect(() => {
        handleChange('activityDate', activity?.activityDate || '');
        handleChange('activityLocation', activity?.activityLocation || '');
        //eslint-disable-next-line
    }, [activity]);

    const handleSubmit = () => {
        dispatch(updateUserActivity(+activityID, form));
    };

    const closeModal = useRedirect(`/users/${id}`);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, history, id, closeModal]);

    return {
        form,
        handleChange,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useUpdateUserActivity;
