import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ActivityGroups from '@pages/activityGroups/activityGroups/ActivityGroups';
import ActivityGroupContainer from '@pages/activityGroups/activityGroup/ActivityGroupContainer';

import DefaultRedirect from './DefaultRedirect';

const ActivityGroupsRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ActivityGroups />
            </Route>

            <Route exact path={`${path}/create`}>
                <ActivityGroups showCreateModal />
            </Route>
            <Route exact path={`${path}/:id/edit`}>
                <ActivityGroupContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <ActivityGroupContainer showDeleteModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <ActivityGroupContainer />
            </Route>
            <Route exact path={`${path}/:id/create-activity`}>
                <ActivityGroupContainer showCreateActivityModal />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default ActivityGroupsRoutes;
