import {
    DisabilityReported,
    disabilityReportedFriendlyNames,
    Ethnicity,
    ethnicityFriendlyNames,
    UserGender,
    userGenderFriendlyNames,
    userSchoolAssociationFriendlyNames,
} from './../../../../types/models/User';

import User, { UserSchoolAssociation } from '../../../../types/models/User';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@hooks/usePrevious';
import useForm from '@hooks/useForm';

import { updateUser } from '@actions/users';
import { getUserIsPosting, getUserPostSuccess, getUsersError } from '@selectors/users';
import { useHistory } from 'react-router-dom';
import { fetchSchoolOptions } from '@actions/schools';
import { getSchoolOptions } from '@selectors/schools';
import { DropdownOption } from 'src/types/shared/DropdownOption';
import { selectCcdbGroups } from '@selectors/ccdbGroups';
import { fetchAllCcdbGroups } from '@actions/ccdb';
import { Nullable } from 'src/types/shared/helpers';
import { getInstitutionTypes } from '@selectors/institutionTypes';
import { getInstitutions } from '@selectors/institutions';
import { fetchInstitutionTypes } from '@actions/institutionTypes';
import { fetchInstitutions } from '@actions/institutions';

const schoolAssociationOptions: DropdownOption<UserSchoolAssociation>[] = Object.entries(
    userSchoolAssociationFriendlyNames,
).map(([value, label]) => ({ value: value as UserSchoolAssociation, label }));

const schoolPositionOptions: DropdownOption<string>[] = [
    { value: 'Class Teacher', label: 'Class Teacher' },
    { value: 'Teaching Assisstant', label: 'Teaching Assisstant' },
    { value: 'Head Teacher', label: 'Head Teacher' },
    { value: 'Deputy Head Teacher', label: 'Deputy Head Teacher' },
    { value: 'Assisstand Head Teacher', label: 'Assisstand Head Teacher' },
    { value: 'Head Of Year', label: 'Head Of Year' },
    { value: 'Policy Maker', label: 'Policy Maker' },
    { value: 'Other', label: 'Other' },
];

const userGenderOptions: DropdownOption<UserGender>[] = Object.entries(userGenderFriendlyNames).map(
    ([value, label]) => ({ value: value as UserGender, label }),
);

const ethnicityOptions: DropdownOption<Ethnicity>[] = Object.entries(ethnicityFriendlyNames).map(
    ([value, label]) => ({ value: value as Ethnicity, label }),
);

const disabilityReportedOptions: DropdownOption<DisabilityReported>[] = Object.entries(
    disabilityReportedFriendlyNames,
).map(([value, label]) => ({ value: value as DisabilityReported, label }));

export default function useUpdateUser(user: User) {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/users/${user.id}`);
    }, [history, user.id]);

    const [formState, handleChange] = useForm(getInitialState(user));

    const handleSubmit = useCallback(() => {
        const { schoolPositionDropdown, schoolPosition, ...rest } = formState;

        const postBody = {
            ...rest,
            schoolPosition:
                schoolPositionDropdown === 'Other' ? schoolPosition : schoolPositionDropdown,
        };
        dispatch(updateUser(user.id, postBody));
    }, [dispatch, user.id, formState]);

    const isPosting = useSelector(getUserIsPosting);
    const error = useSelector(getUsersError);
    const postSuccess = useSelector(getUserPostSuccess);
    const schoolOpts = useSelector(getSchoolOptions);
    const institutionTypes = useSelector(getInstitutionTypes);
    const institutions = useSelector(getInstitutions);

    const prevPostSuccess = usePrevious(postSuccess);

    const [searchTerm, setSearchTerm] = useState('');
    const prevSearchTerm = usePrevious(searchTerm);
    const searchTimeout = useRef<Nullable<number>>(null);

    const onSearchTermChanged = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        if (searchTerm !== prevSearchTerm) {
            window.clearTimeout(searchTimeout.current || 0);
            searchTimeout.current = window.setTimeout(() => {
                dispatch(
                    fetchSchoolOptions({ page: null, limit: null, searchTerm: newSearchTerm }),
                );
            }, 1000);
        }
    };
    const institutionTypeOptions = useMemo(() => {
        return institutionTypes
            .map(it => ({ label: it.name, value: it.id }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [institutionTypes]);

    useEffect(() => {
        if (user.institutionID) {
            const institution = institutions.find(i => i.id === user.institutionID);
            if (institution) {
                handleChange('institutionTypeID', institution.institutionTypeID);
            }
        }
    }, [user, institutions, handleChange]);

    // To do remove and replace with paginated select box
    const institutionOptions = useMemo(() => {
        if (!formState.institutionTypeID) return [];

        return institutions
            .filter(i => i.institutionTypeID === formState.institutionTypeID)
            .map(it => ({ label: `${it.name} (${it.country})`, value: it.id }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [institutions, formState.institutionTypeID]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    useEffect(() => {
        dispatch(fetchSchoolOptions({ page: null, limit: null, searchTerm: '' }));
        dispatch(fetchAllCcdbGroups());
        dispatch(fetchInstitutionTypes({ page: 1, limit: 10000, searchTerm: null }));

        dispatch(
            fetchInstitutions({
                page: 1,
                pageSize: 10000,
                institutionTypeIDs: [],
                searchTerm: null,
            }),
        );
    }, [dispatch]);

    const schoolOptions = useMemo(() => {
        return schoolOpts.map(({ id, name }) => ({ label: name, value: id }));
    }, [schoolOpts]);

    const ccdbGroups = useSelector(selectCcdbGroups);

    const ccdbGroupOptions = Object.values(ccdbGroups).map(({ id, name }) => ({
        label: name,
        value: name,
    }));

    return {
        ccdbGroupOptions,
        closeModal,
        disabilityReportedOptions,
        error,
        ethnicityOptions,
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        institutionOptions,
        onSearchTermChanged,
        schoolAssociationOptions,
        schoolOptions,
        schoolPositionOptions,
        userGenderOptions,
        institutionTypeOptions,
    };
}

const getInitialState = (user: User) => {
    const initialState: FormState = {
        agreedTo3rdPartyResearchDataSharing: user.agreedTo3rdPartyResearchDataSharing,
        agreedTo3rdPartySchoolSupportDataSharing: user.agreedTo3rdPartySchoolSupportDataSharing,
        ccdbGroupNames: user.ccdbGroupNames,
        consentedToPersonalDataCollection: user.consentedToPersonalDataCollection,
        country: user.country,
        disabilityReported: user.disabilityReported,
        email: user.email || '',
        ethnicity: user.ethnicity,
        firstName: user.firstName || '',
        gender: user.gender,
        institutionID: user.institutionID || null,
        institutionTypeID: null,
        lastName: user.lastName || '',
        phoneNumber: user.phoneNumber,
        schoolAssociation: user.schoolAssociation,
        schoolEmail: user.schoolEmail || '',
        schoolID: user.schoolID,
        schoolPosition: user.schoolPosition || '',
        schoolPositionDropdown: null,
        signedUpForBritishCouncilMail: user.signedUpForBritishCouncilMail,
        signedUpForSchoolsOnlineNews: user.signedUpForSchoolsOnlineNews,
        title: user.title || '',
    };
    return initialState;
};

export interface FormState {
    agreedTo3rdPartyResearchDataSharing: boolean;
    agreedTo3rdPartySchoolSupportDataSharing: boolean;
    ccdbGroupNames: string[];
    consentedToPersonalDataCollection: boolean;
    country: string;
    disabilityReported: Nullable<DisabilityReported>;
    email: string;
    ethnicity: Nullable<Ethnicity>;
    firstName: string;
    gender: Nullable<UserGender>;
    institutionID: Nullable<number>;
    institutionTypeID: Nullable<number>;
    lastName: string;
    phoneNumber: string;
    schoolAssociation: Nullable<UserSchoolAssociation>;
    schoolEmail: string;
    schoolID: Nullable<number>;
    schoolPosition: string;
    schoolPositionDropdown: Nullable<string>;
    signedUpForBritishCouncilMail: boolean;
    signedUpForSchoolsOnlineNews: boolean;
    title: string;
}
