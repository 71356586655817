import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { ActivityGroup } from '../../../types/models/ActivityGroups';

export const createActivityGroupRequest = createAction('createActivityGroupRequest');
export const createActivityGroupSuccess = createAction<ActivityGroup>('createActivityGroupSuccess');
export const createActivityGroupFailure = createAction('createActivityGroupFailure');

export const createActivityGroup = (postBody: PostBody) => async (dispatch: AppDispatch) => {
    dispatch(createActivityGroupRequest());

    try {
        const { data } = await api.post<PostBody, ActivityGroup>('activitygroups', postBody);

        dispatch(createActivityGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createActivityGroupFailure, e as APIError);
    }
};

interface PostBody {
    name: string;
}
