import { RootState } from '@reducers/index';

export const selectActivityHistoriesIsFetching = (state: RootState) =>
    state.activityHistoriesReducer.isFetching;
export const selectActivityHistoriesFetchError = (state: RootState) =>
    state.activityHistoriesReducer.fetchError;

export const selectActivityHistoriesIsPosting = (state: RootState) =>
    state.activityHistoriesReducer.isPosting;
export const selectActivityHistoriesPostError = (state: RootState) =>
    state.activityHistoriesReducer.postError;
export const selectActivityHistoriesPostSuccess = (state: RootState) =>
    state.activityHistoriesReducer.postSuccess;

export const selectActivityHistories = (state: RootState) =>
    state.activityHistoriesReducer.activityHistories;
