import useUpdateCcdbGroup from '@pages/ccdbGroups/ccdbGroup/_hooks/useUpdateCcdbGroup';

import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import TextInput from '@components/form/TextInput';
import Select from '@components/form/Select';

const UpdateCcdbGroupModal = () => {
    const {
        form,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        postError,
        userOptions,
        onSearchTermChanged,
    } = useUpdateCcdbGroup();

    return (
        <Modal title="Update CCDB Group">
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                isPosting={isPosting}
                error={postError}
            >
                <TextInput
                    name="name"
                    label="Name"
                    required
                    value={form.name}
                    onChange={handleChange}
                />
                <Select
                    search
                    label="Owner Admin User"
                    name="ownerAdminUserID"
                    options={userOptions}
                    value={form.ownerAdminUserID}
                    onChange={handleChange}
                    onSearchChange={onSearchTermChanged}
                />
            </Form>
        </Modal>
    );
};

export default UpdateCcdbGroupModal;
