import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatRegionOptions } from '@utils/countries';

import {
    selectRegions,
    selectRegionsFetchError,
    selectRegionsIsFetching,
} from '@selectors/regions';
import { fetchRegions } from '@actions/regions/fetchRegions';

const useFetchRegions = () => {
    const dispatch = useDispatch();

    const isFetching = useSelector(selectRegionsIsFetching);
    const regions = useSelector(selectRegions);
    const fetchError = useSelector(selectRegionsFetchError);
    const regionOptions = useMemo(() => formatRegionOptions(regions), [regions]);

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);

    return {
        isFetching,
        regions,
        fetchError,
        regionOptions,
    };
};

export default useFetchRegions;
