import React from 'react';

import Title from '@components/typography/Title';

import ButtonRow from '@components/button/ButtonRow';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from '@components/button/LinkButton';
import UserTypes, {
    disabilityReportedFriendlyNames,
    userSchoolAssociationFriendlyNames,
} from '../../../types/models/User';
import { getCountryName } from '@utils/countries';
import { outputUsersFullName } from '@utils/users';
import { humanizeEnumName } from '@utils/enums';

const User: React.FC<UserProps> = ({ user }) => {
    if (!user) return null;

    return (
        <>
            <Title>User - {outputUsersFullName(user.firstName, user.lastName)}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Title">
                        <p>{user.title || '-'}</p>
                    </ContentItem>
                    <ContentItem label="Name">
                        <p>{outputUsersFullName(user.firstName, user.lastName)}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Email">
                        <p>
                            {user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : '-'}
                        </p>
                    </ContentItem>
                    <ContentItem label="Phone Number">
                        <p>{user.phoneNumber || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Country">
                        <p>{getCountryName(user.country) || user.country || '-'}</p>
                    </ContentItem>
                    <ContentItem label="School Association">
                        <p>
                            {user.schoolAssociation
                                ? userSchoolAssociationFriendlyNames[user.schoolAssociation]
                                : '-'}
                        </p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="School">
                        <p>{user.school || '-'}</p>
                    </ContentItem>
                    <ContentItem label="CCDB Group">
                        <p>{user.ccdbGroupNames.join(', ') || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="School Email">
                        <p>{user.schoolEmail || '-'}</p>
                    </ContentItem>
                    <ContentItem label="School Position">
                        <p>{user.schoolPosition || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Institution">
                        <p>{user?.institutionName || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Consented To Personal Data Collection">
                        <p>{user.consentedToPersonalDataCollection ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label="Signed Up For Schools Online News">
                        <p>{user.signedUpForSchoolsOnlineNews ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Signed Up For British Council Mail">
                        <p>{user.signedUpForBritishCouncilMail ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label="Agreed To 3rd Party Research Data Sharing">
                        <p>{user.agreedTo3rdPartyResearchDataSharing ? 'Yes' : 'No'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Agreed To 3rd Party School Support Data Sharing">
                        <p>{user.agreedTo3rdPartySchoolSupportDataSharing ? 'Yes' : 'No'}</p>
                    </ContentItem>
                    <ContentItem label="Gender">
                        <p>{user.gender || '-'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Ethnicity">
                        <p>{humanizeEnumName(user.ethnicity) || '-'}</p>
                    </ContentItem>
                    <ContentItem label="Disability">
                        <p>
                            {typeof user.disabilityReported == 'string'
                                ? disabilityReportedFriendlyNames[user.disabilityReported]
                                : 'Not reported'}
                        </p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Unique ID">
                        <p>{user.uniqueID || '-'}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <LinkButton source="secondary" href={`/users/${user.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton source="negative" href={`/users/${user.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        </>
    );
};

interface UserProps {
    user?: UserTypes;
}
export default User;
