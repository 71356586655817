import { updateSchool } from '@actions/schools';
import { SchoolCreateEditFormState } from '@pages/schools/shared/types/SchoolCreateEditFormStep';
import { useDispatch } from 'react-redux';
import School from './../../../../types/models/School';

const useEditSchool = (school: School) => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: SchoolCreateEditFormState) => {
        dispatch(updateSchool(school.id, formState));
    };

    return {
        initialFormState: getInitialState(school),
        handleSubmit,
    };
};

const getInitialState = (school: School) => {
    const formState: SchoolCreateEditFormState = {
        name: school.name,
        website: school.website || '',
        email: school.email || '',
        addressLine1: school.addressLine1,
        addressLine2: school.addressLine2 || '',
        city: school.city,
        country: school.country,
        postcode: school.postcode || '',
        schoolArea: school.schoolArea,
        schoolPhotographS3Key: school.schoolPhotographS3Key || '',
        headTeacher: school.headTeacher || '',
        schoolGroup: school.schoolGroup,
        schoolTypes: school.schoolTypes,
        schoolGender: school.schoolGender,
        provisionsForSpecialEducationalNeeds: school.provisionsForSpecialEducationalNeeds,
        schoolStatus: school.schoolStatus,
        numberOfPupils: String(school.numberOfPupils) || '', //TODO: Ask Oli about a better way to force this to be a string
        facebookID: school.facebookID || '',
        twitterID: school.twitterID || '',
        hasVideoConferencing: school.hasVideoConferencing || false,
        hasSMS: school.hasSMS || false,
        hasIT: school.hasIT || false,
        hasEmail: school.hasEmail || false,
        internationalCoordinator: school.internationalCoordinator || '',
        wholeSchoolPartnershipPreferences: school.wholeSchoolPartnershipPreferences,
        partnerSchoolInterestLocations: school.partnerSchoolInterestLocations || [],
        currentPartnerSchoolLocations: school.currentPartnerSchoolLocations || [],
        activitiesInterestedIn: school.activitiesInterestedIn || [],
        activitiesInvolvedIn: school.activitiesInvolvedIn || [],
        partnersFoundViaSchoolsOnline: school.partnersFoundViaSchoolsOnline,
        schoolsOnlineProvidedUsefulInformation: school.schoolsOnlineProvidedUsefulInformation,
        partnerSchoolSuggested: school.partnerSchoolSuggested,
        interestedInInternationalSchoolAward: school.interestedInInternationalSchoolAward,
        source: school.source,
        ukRegion: school.ukRegion,
    };
    return formState;
};

export default useEditSchool;
