import { RootState } from '@reducers/index';

export const selectActivityGroupsObj = (state: RootState) =>
    state.activityGroupsReducer.activityGroups;

export const selectActivityGroup = (state: RootState, id: number) =>
    state.activityGroupsReducer.activityGroups[id];

export const selectActivityGroupsIsFetching = (state: RootState) =>
    state.activityGroupsReducer.isFetching;
export const selectActivityGroupsFetchError = (state: RootState) =>
    state.activityGroupsReducer.fetchError;

export const selectActivityGroupsIsPosting = (state: RootState) =>
    state.activityGroupsReducer.isPosting;
export const selectActivityGroupsPostSuccess = (state: RootState) =>
    state.activityGroupsReducer.postSuccess;
export const selectActivityGroupsPostError = (state: RootState) =>
    state.activityGroupsReducer.postError;
