import { RootState } from '@reducers/index';

export const getInstitutionTypes = (state: RootState) =>
    Object.values(state.institutionTypesReducer.institutionTypes);
export const getInstitutionType = (state: RootState, id: number) =>
    state.institutionTypesReducer.institutionTypes[id];

export const getInstitutionTypesIsFetching = (state: RootState) =>
    state.institutionTypesReducer.isFetching;
export const getInstitutionTypesFetchError = (state: RootState) =>
    state.institutionTypesReducer.fetchError;
export const getInstitutionTypesPostError = (state: RootState) =>
    state.institutionTypesReducer.postError;

export const getInstitutionTypesIsPosting = (state: RootState) =>
    state.institutionTypesReducer.isPosting;
export const getInstitutionTypesPostSuccess = (state: RootState) =>
    state.institutionTypesReducer.postSuccess;
export const getInstitutionTypesItemCount = (state: RootState) =>
    state.institutionTypesReducer.itemCount;
