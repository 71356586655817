import Form from '@components/form/Form';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';
import useCreateCcdbGroup from '@pages/ccdbGroups/ccdbGroups/hooks/useCreateCcdbGroup';
import Select from '@components/form/Select';
const CreateCcdbGroupModal = () => {
    const {
        closeModal,
        handleSubmit,
        isPosting,
        postError,
        form,
        handleChange,
        adminUserOptions,
        onSearchTermChanged,
    } = useCreateCcdbGroup();

    return (
        <Modal title="Create CCDB Group" size="small">
            <Form
                onCancel={closeModal}
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={postError}
            >
                <TextInput
                    name="name"
                    label="Name"
                    required
                    value={form.name}
                    onChange={handleChange}
                />
                <Select
                    search
                    label="Owner Admin User"
                    name="ownerAdminUserID"
                    options={adminUserOptions}
                    value={form.ownerAdminUserID}
                    onChange={handleChange}
                    onSearchChange={onSearchTermChanged}
                />
            </Form>
        </Modal>
    );
};

export default CreateCcdbGroupModal;
