import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { ActivityHistory } from '../../types/models/ActivityHistories';
import {
    fetchActivityHistoriesForCcdbFailure,
    fetchActivityHistoriesForCcdbRequest,
    fetchActivityHistoriesForCcdbSuccess,
    applyActivitiesToUsersFailure,
    applyActivitiesToUsersRequest,
    applyActivitiesToUsersSuccess,
    undoActivityHistoryFailure,
    undoActivityHistoryRequest,
    undoActivityHistorySuccess,
} from '@actions/activityHistories';
import { convertArrToObj } from '@utils/generic';
interface ActivityHistoriesState {
    isFetching: boolean;
    fetchError: string | null;
    activityHistories: Record<number, ActivityHistory>;
    isPosting: boolean;
    postSuccess: boolean;
    postError: string | null;
}

const initialState: ActivityHistoriesState = {
    isFetching: false,
    fetchError: null,
    activityHistories: {},
    isPosting: false,
    postSuccess: false,
    postError: null,
};

export default createReducer(initialState, {
    [fetchActivityHistoriesForCcdbRequest.type]: handleFetchRequest,
    [fetchActivityHistoriesForCcdbSuccess.type]: handleFetchHistoriesSuccess,
    [fetchActivityHistoriesForCcdbFailure.type]: handleFetchFailure,
    [applyActivitiesToUsersRequest.type]: handlePostRequest,
    [applyActivitiesToUsersSuccess.type]: handlePostSuccess,
    [applyActivitiesToUsersFailure.type]: handlePostFailure,
    [undoActivityHistoryRequest.type]: handlePostRequest,
    [undoActivityHistorySuccess.type]: handleDeleteSuccess,
    [undoActivityHistoryFailure.type]: handlePostFailure,
});

function handleFetchRequest(state: ActivityHistoriesState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchHistoriesSuccess(
    state: ActivityHistoriesState,
    action: PayloadAction<ActivityHistory[]>,
) {
    state.isFetching = false;
    state.activityHistories = convertArrToObj(action.payload);
}

function handleFetchFailure(state: ActivityHistoriesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handlePostRequest(state: ActivityHistoriesState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostSuccess(state: ActivityHistoriesState, action: PayloadAction<ActivityHistory>) {
    state.postSuccess = true;
    state.isPosting = false;
    state.activityHistories[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: ActivityHistoriesState, action: PayloadAction<number>) {
    state.postSuccess = true;
    state.isPosting = false;
    delete state.activityHistories[action.payload];
}

function handlePostFailure(state: ActivityHistoriesState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}
