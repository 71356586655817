import Form from '@components/form/Form';
import Modal from '@components/modal/Modal';
import React from 'react';
import useCreateEditInstitutionType from './hooks/useCreateEditInstitutionType';
import { InstitutionTypeCreateEditFormState } from './types/InstitutionTypeCreateEditFormStep';
import TextInput from '@components/form/TextInput';

interface Props {
    onSubmit: (formState: InstitutionTypeCreateEditFormState) => void;
    initialFormState: InstitutionTypeCreateEditFormState;
    title: string;
    redirectURL: string;
}

const InstitutionTypeCreateEditModal: React.FC<Props> = ({
    onSubmit,
    initialFormState,
    title,
    redirectURL,
}) => {
    const { closeModal, isPosting, error, handleSubmit, handleChange, formState } =
        useCreateEditInstitutionType({
            onSubmit,
            initialFormState,
            redirectURL,
        });

    return (
        <Modal title={title} size="small">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <TextInput
                    name="name"
                    label="Name"
                    required
                    value={formState.name}
                    onChange={handleChange}
                />
            </Form>
        </Modal>
    );
};

export default InstitutionTypeCreateEditModal;
