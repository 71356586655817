import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { convertArrToObj } from '@utils/generic';
import { Activity, UserActivities } from '../../types/models/Activities';

import {
    createActivityFailure,
    createActivityRequest,
    createActivitySuccess,
    deleteActivityFailure,
    deleteActivityRequest,
    deleteActivitySuccess,
    fetchActivitiesFailure,
    fetchActivitiesForGroupFailure,
    fetchActivitiesForGroupRequest,
    fetchActivitiesForGroupSuccess,
    fetchActivitiesRequest,
    fetchActivitiesSuccess,
    fetchActivityFailure,
    fetchActivityRequest,
    fetchActivitySuccess,
    fetchUserActivitiesFailure,
    fetchUserActivitiesRequest,
    fetchUserActivitiesSuccess,
    restoreActivityFailure,
    restoreActivityRequest,
    restoreActivitySuccess,
    updateActivityFailure,
    updateActivityRequest,
    updateActivitySuccess,
} from '@actions/activities';
import {
    deleteUserActivityFailure,
    deleteUserActivityRequest,
    deleteUserActivitySuccess,
} from '@actions/activities/deleteUserActivity';
import {
    updateUserActivityFailure,
    updateUserActivityRequest,
    updateUserActivitySuccess,
} from '@actions/activities/updateUserActivity';

interface ActivitiesState {
    isFetching: boolean;
    activities: Record<number, Activity>;
    userActivities: Record<number, UserActivities>;
    isPosting: boolean;
    postSuccess: boolean;
    fetchError: string | null;
    postError: string | null;
}

const initialState: ActivitiesState = {
    isFetching: false,
    activities: {},
    userActivities: {},
    isPosting: false,
    postSuccess: false,
    fetchError: null,
    postError: null,
};

export default createReducer(initialState, {
    [fetchActivitiesRequest.type]: handleFetchRequest,
    [fetchActivitiesSuccess.type]: handleFetchSuccess,
    [fetchActivitiesFailure.type]: handleFetchFailure,
    [fetchActivityRequest.type]: handleFetchRequest,
    [fetchActivitySuccess.type]: handleFetchSingleSuccess,
    [fetchActivityFailure.type]: handleFetchFailure,
    [createActivityRequest.type]: handlePostRequest,
    [createActivitySuccess.type]: handlePostSuccess,
    [createActivityFailure.type]: handlePostFailure,
    [updateActivityRequest.type]: handlePostRequest,
    [updateActivitySuccess.type]: handlePostSuccess,
    [updateActivityFailure.type]: handlePostFailure,
    [deleteActivityRequest.type]: handlePostRequest,
    [deleteActivitySuccess.type]: handleDeleteSuccess,
    [deleteActivityFailure.type]: handlePostFailure,
    [restoreActivityRequest.type]: handlePostRequest,
    [restoreActivitySuccess.type]: handlePostSuccess,
    [restoreActivityFailure.type]: handlePostFailure,
    [fetchActivitiesForGroupRequest.type]: handleFetchRequest,
    [fetchActivitiesForGroupSuccess.type]: handleFetchSuccess,
    [fetchActivitiesForGroupFailure.type]: handleFetchFailure,
    [fetchUserActivitiesRequest.type]: handleFetchRequest,
    [fetchUserActivitiesSuccess.type]: handleFetchUserActivitiesSuccess,
    [fetchUserActivitiesFailure.type]: handleFetchFailure,
    [deleteUserActivityRequest.type]: handlePostRequest,
    [deleteUserActivitySuccess.type]: handleDeleteUserActivitySuccess,
    [deleteUserActivityFailure.type]: handlePostFailure,
    [updateUserActivityRequest.type]: handlePostRequest,
    [updateUserActivitySuccess.type]: handleUpdateUserActivitySuccess,
    [updateUserActivityFailure.type]: handlePostFailure,
});

function handleFetchRequest(state: ActivitiesState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchSuccess(state: ActivitiesState, action: PayloadAction<Activity[]>) {
    state.isFetching = false;
    state.activities = convertArrToObj(action.payload);
}

function handleFetchUserActivitiesSuccess(
    state: ActivitiesState,
    action: PayloadAction<UserActivities[]>,
) {
    state.isFetching = false;
    state.userActivities = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state: ActivitiesState, action: PayloadAction<Activity>) {
    state.isFetching = false;
    state.activities[action.payload.id] = action.payload;
}

function handlePostRequest(state: ActivitiesState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostSuccess(state: ActivitiesState, action: PayloadAction<Activity>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.activities[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: ActivitiesState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.activities[action.payload];
}

function handleFetchFailure(state: ActivitiesState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.fetchError = action.payload;
}
function handlePostFailure(state: ActivitiesState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleDeleteUserActivitySuccess(state: ActivitiesState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.userActivities[action.payload];
}

function handleUpdateUserActivitySuccess(
    state: ActivitiesState,
    action: PayloadAction<UserActivities>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.userActivities[action.payload.id] = action.payload;
}
