import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const updateUserActivityRequest = createAction('updateUserActivityRequest');
export const updateUserActivitySuccess = createAction<Activity>('updateUserActivitySuccess');
export const updateUserActivityFailure = createAction('updateUserActivityFailure');

export const updateUserActivity =
    (id: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(updateUserActivityRequest());

        try {
            const { data } = await api.put<PostBody, Activity>(`UserActivities/${id}`, postBody);

            dispatch(updateUserActivitySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateUserActivityFailure, e as APIError);
        }
    };

interface PostBody {
    activityLocation: string;
    activityDate: string;
}
