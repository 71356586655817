import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';

export const addUsersToCcdbGroupRequest = createAction('addUsersToCcdbGroupRequest');
export const addUsersToCcdbGroupSuccess = createAction<Response>('addUsersToCcdbGroupSuccess');
export const addUsersToCcdbGroupFailure = createAction('addUsersToCcdbGroupFailure');

export const addUsersToCcdbGroup =
    (groupID: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(addUsersToCcdbGroupRequest());

        try {
            const { data } = await api.post<PostBody, Response>(
                `CCDBGroups/${groupID}/AddUsers`,
                postBody,
            );

            dispatch(addUsersToCcdbGroupSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, addUsersToCcdbGroupFailure, e as APIError);
        }
    };

interface PostBody {
    userIDs: number[];
}

interface Response {
    any: any;
}
