import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { api, handleApiErrors } from '@utils/api';
import { APIError } from '../../../types/shared/APIError';

export const undoActivityHistoryRequest = createAction('undoActivityHistoryRequest');
export const undoActivityHistorySuccess = createAction<number>('undoActivityHistorySuccess');
export const undoActivityHistoryFailure = createAction('undoActivityHistoryFailure');

export const undoActivityHistory = (historyID: number) => async (dispatch: AppDispatch) => {
    dispatch(undoActivityHistoryRequest());

    try {
        await api.delete(`UserActivityHistories/${historyID}`);
        dispatch(undoActivityHistorySuccess(historyID));
    } catch (e) {
        handleApiErrors(dispatch, undoActivityHistoryFailure, e as APIError);
    }
};
