import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActivities, selectActivitiesIsFetching } from '@selectors/activities';
import { selectActivityGroupsFetchError } from '@selectors/activityGroups';

import { fetchActivitiesForGroup } from '@actions/activities';
import { useParams } from 'react-router-dom';

const useFetchActivitiesForGroup = () => {
    const dispatch = useDispatch();

    const { id } = useParams<{ id: string }>();
    const activityGroupID = +id;

    const activities = useSelector(selectActivities);
    const isFetching = useSelector(selectActivitiesIsFetching);
    const error = useSelector(selectActivityGroupsFetchError);

    useEffect(() => {
        dispatch(fetchActivitiesForGroup(activityGroupID));
    }, [dispatch, activityGroupID]);

    return {
        activities,
        isFetching,
        error,
        activityGroupID,
    };
};

export default useFetchActivitiesForGroup;
