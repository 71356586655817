import { DropdownOption } from 'src/types/shared/DropdownOption';

export const convertEnumToDropdownOption = (enumObj: { [key: string]: string }) => {
    const options: DropdownOption<string>[] = Object.entries(enumObj).map(([key, value]) => ({
        value,
        label: humanizeEnumName(key),
    }));
    return options;
};

export const humanizeEnumName = (name: string | null) => {
    if (!name) return '-';
    return name.replace(/([^A-Z])([A-Z]+)/g, '$1 $2');
};

export const humanizeEnumNames = (names: string[] | null) => {
    if (!names) return '-';
    if (!names.length) return '';

    return names.map(humanizeEnumName).join(', ');
};
