import { InstitutionTypeCreateEditFormState } from '@pages/institutionTypes/shared/types/InstitutionTypeCreateEditFormStep';
import { createInstitutionType } from '../../../../redux/actions/institutionTypes/createInstitutionType';
import { useDispatch } from 'react-redux';

const initialFormState: InstitutionTypeCreateEditFormState = {
    name: '',
};

const useCreateInstitutionType = () => {
    const dispatch = useDispatch();

    const handleSubmit = (formState: InstitutionTypeCreateEditFormState) => {
        dispatch(createInstitutionType(formState));
    };

    return {
        initialFormState,
        handleSubmit,
    };
};

export default useCreateInstitutionType;
