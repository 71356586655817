import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';

import { APIError } from '../../../types/shared/APIError';
import { CcdbGroup } from '../../../types/models/Ccdb';
import { AppDispatch } from '../../store';

export const fetchSingleCcdbGroupRequest = createAction('fetchSingleCcdbGroupRequest');
export const fetchSingleCcdbGroupSuccess = createAction<CcdbGroup>('fetchSingleCcdbGroupSuccess');
export const fetchSingleCcdbGroupFailure = createAction('fetchSingleCcdbGroupFailure');

export const fetchSingleCcdbGroup = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchSingleCcdbGroupRequest());

    try {
        const { data } = await api.get<CcdbGroup>(`CCDBGroups/${id}`);

        dispatch(fetchSingleCcdbGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleCcdbGroupFailure, e as APIError);
    }
};
