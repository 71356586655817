import usePrevious from '@hooks/usePrevious';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { deleteSchool } from '@actions/schools';
import {
    getSchool,
    getSchoolsIsPosting,
    getSchoolsPostSuccess,
    getSchoolsPostError,
} from '@selectors/schools';

const useDeleteSchool = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const isPosting = useSelector(getSchoolsIsPosting);
    const error = useSelector(getSchoolsPostError);
    const success = useSelector(getSchoolsPostSuccess);
    const school = useAppSelector(state => getSchool(state, +id));

    const handleSubmit = () => {
        dispatch(deleteSchool(+id));
    };

    const closeModal = useCallback(() => {
        history.replace('/schools');
    }, [history]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [closeModal, prevSuccess, success]);

    return {
        school,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    };
};

export default useDeleteSchool;
