import ConfirmModal from '@components/modal/ConfirmModal';
import useUndoActivityHistory from './_hooks/useUndoActivityHistory';

const UndoActivityHistoryModal = () => {
    const { handleSubmit, isPosting, closeModal, error } = useUndoActivityHistory();

    return (
        <ConfirmModal
            title="Undo Activity History"
            description="Are you sure you want to undo this history?"
            isPosting={isPosting}
            error={error}
            closeModal={closeModal}
            handleSubmit={handleSubmit}
        />
    );
};

export default UndoActivityHistoryModal;
