import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';
import Form from '@components/form/Form';
import Modal from '@components/modal/Modal';
import React from 'react';
import useCreateEditSchool from './hooks/useCreateEditSchool';
import { SchoolCreateEditFormState } from './types/SchoolCreateEditFormStep';

interface Props {
    onSubmit: (formState: SchoolCreateEditFormState) => void;
    initialFormState: SchoolCreateEditFormState;
    title: string;
    redirectURL: string;
}

const SchoolCreateEditModal: React.FC<Props> = ({
    onSubmit,
    initialFormState,
    title,
    redirectURL,
}) => {
    const {
        closeModal,
        isPosting,
        error,
        formState,
        handleChange,
        handleSubmit,
        isFirstStep,
        CurrentStepForm,
        stepFormRef,
        isLastStep,
        handlePrev,
    } = useCreateEditSchool({ onSubmit, initialFormState, redirectURL });

    return (
        <Modal title={title} size="small">
            <Form
                onCancel={closeModal}
                onSubmit={handleSubmit}
                isPosting={isPosting}
                error={error}
                omitButtons
            >
                <CurrentStepForm
                    ref={stepFormRef}
                    formState={formState}
                    handleChange={handleChange}
                />
                <ButtonRow alignment="right">
                    <ActionButton source="secondary" onClick={closeModal}>
                        Cancel
                    </ActionButton>
                    {!isFirstStep && (
                        <ActionButton disabled={isPosting} source="negative" onClick={handlePrev}>
                            Prev
                        </ActionButton>
                    )}
                    <ActionButton
                        disabled={isPosting}
                        isPosting={isPosting}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        {isLastStep ? 'Submit' : 'Next'}
                    </ActionButton>
                </ButtonRow>
            </Form>
        </Modal>
    );
};

export default SchoolCreateEditModal;
