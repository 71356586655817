import { useCallback, useEffect, useMemo } from 'react';
import useForm from '@hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';

import { getUsers } from '@selectors/users';
import {
    selectCcdbGroupsIsPosting,
    selectCcdbGroupsPostError,
    selectCcdbGroupsPostSuccess,
} from '@selectors/ccdbGroups';

import { removeUsersFromCcdbGroup } from '@actions/ccdb';

import { DropdownOption } from '../../../../../types/shared/DropdownOption';

const useRemoveUsersFromCcdbGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const groupID = +id;

    const [form, handleChange] = useForm<{ userIDs: number[] }>({ userIDs: [] });

    const users = useSelector(getUsers);
    const isPosting = useSelector(selectCcdbGroupsIsPosting);
    const postSuccess = useSelector(selectCcdbGroupsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const error = useSelector(selectCcdbGroupsPostError);

    const userOptions = useMemo(() => {
        return Object.values(users).reduce((acc: DropdownOption<number>[], user) => {
            if (user.ccdbGroupIds.includes(groupID)) {
                acc.push({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                });
            }

            return acc;
        }, []);
    }, [users, groupID]);

    const handleSubmit = () => {
        dispatch(removeUsersFromCcdbGroup(+id, form));
    };

    const closeModal = useCallback(() => {
        history.push(`/ccdb-groups/${id}`);
    }, [history, id]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [dispatch, history, id, postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        userOptions,
        handleSubmit,
        isPosting,
        closeModal,
        error,
    };
};

export default useRemoveUsersFromCcdbGroup;
