import { selectActivitiesObj } from '@selectors/activities';
import { selectActivityGroupsObj } from '@selectors/activityGroups';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import School from 'src/types/models/School';
import { DropdownOption } from 'src/types/shared/DropdownOption';

const useSchoolsMergeActivityOptions = (
    schools: School[],
    extractActivityIDs: (school: School) => number[] | null,
) => {
    const activityIDsFunc = useRef(extractActivityIDs);

    const activitiesObj = useSelector(selectActivitiesObj);
    const activityGroupsObj = useSelector(selectActivityGroupsObj);

    const options: DropdownOption<number>[] = useMemo(() => {
        return schools.reduce((acc: DropdownOption<number>[], school) => {
            const activityIDs = activityIDsFunc.current(school) || [];

            const label =
                activityIDs.length === 0
                    ? '-- Empty --'
                    : activityIDs
                          .map(aID => activitiesObj[aID])
                          .map(a => `${activityGroupsObj[a?.activityGroupID]?.name} > ${a.name}`)
                          .join(', ');

            if (acc.every(opt => opt.label !== label)) acc.push({ label, value: school.id });
            return acc;
        }, []);
    }, [schools, activitiesObj, activityGroupsObj]);

    return options;
};

export default useSchoolsMergeActivityOptions;
