import DataCheck from '@components/common/DataCheck';
import School from './School';
import useFetchSchool from './hooks/useFetchSchool';
import SchoolEditModal from './SchoolEditModal';
import SchoolDeleteModal from './SchoolDeleteModal';

interface Props {
    showEditModal?: boolean;
    showDeleteModal?: boolean;
}

const SchoolContainer: React.FC<Props> = ({ showEditModal = false, showDeleteModal = false }) => {
    const { school, isFetching, fetchError } = useFetchSchool();

    return (
        <>
            <DataCheck dataExists={!!school} isFetching={isFetching} error={fetchError}>
                <School school={school} />
            </DataCheck>
            {!!school && showEditModal && <SchoolEditModal school={school} />}
            {showDeleteModal && <SchoolDeleteModal />}
        </>
    );
};

export default SchoolContainer;
