import React from 'react';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from '@components/button/LinkButton';
import Title from '@components/typography/Title';
import InstitutionTypeResponse from 'src/types/models/InstitutionType';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ButtonRow from '@components/button/ButtonRow';
import dayjs from 'dayjs';

interface Props {
    institutionType: InstitutionTypeResponse;
}

const InstitutionType: React.FC<Props> = ({ institutionType }) => {
    return (
        <>
            <CreateHeader>
                <Title>Institution Type</Title>
                <ButtonRow alignment="left">
                    <LinkButton
                        source="secondary"
                        href={`/institution-types/${institutionType.id}/edit`}
                    >
                        Edit
                    </LinkButton>
                    <LinkButton
                        source="negative"
                        href={`/institution-types/${institutionType.id}/delete`}
                    >
                        Delete
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="ID">
                        <p>{institutionType.id}</p>
                    </ContentItem>
                    <ContentItem label="Name">
                        <p>{institutionType.name}</p>
                    </ContentItem>
                    <ContentItem label="Created On">
                        <p>{dayjs(institutionType.createdOn).format('DD/MM/YYYY')}</p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
        </>
    );
};

export default InstitutionType;
