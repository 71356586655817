import { useAppSelector } from '../../../../redux/store';
import {
    getInstitutionsIsFetching,
    getInstitutionsFetchError,
    getInstitutions,
    getInstitutionsItemCount,
} from '@selectors/institutions';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from '@hooks/useForm';
import useApiFilterTableRef from '@hooks/useApiFilterTableRef';
import {
    getInstitutionTypes,
    getInstitutionTypesFetchError,
    getInstitutionTypesIsFetching,
} from '@selectors/institutionTypes';
import { fetchInstitutionTypes } from '@actions/institutionTypes';
import { fetchInstitutions } from '@actions/institutions';

interface FromState {
    institutionTypeID: number[];
    countrySearchTerm: string;
    regionSearchTerm: string;
}

const useFetchInstitutions = () => {
    const dispatch = useDispatch();

    const tableRef = useApiFilterTableRef();

    const isFetchingInstitutions = useSelector(getInstitutionsIsFetching);
    const fetchInstitutionsError = useSelector(getInstitutionsFetchError);
    const institutions = useAppSelector(getInstitutions);
    const institutionTypes = useSelector(getInstitutionTypes);
    const itemCount = useSelector(getInstitutionsItemCount);

    const isFetchingTypes = useSelector(getInstitutionTypesIsFetching);
    const fetchTypesError = useSelector(getInstitutionTypesFetchError);

    const [formState, handleChange] = useForm<FromState>({
        institutionTypeID: [],
        countrySearchTerm: '',
        regionSearchTerm: '',
    });

    const handleFetch = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            const params = {
                institutionTypeIDs: formState.institutionTypeID,
                page,
                pageSize,
                searchTerm,
            };

            dispatch(fetchInstitutions(params));
        },
        [dispatch, formState],
    );

    const institutionTypeOptions = useMemo(
        () => institutionTypes.map(({ name, id }) => ({ label: name, value: id })),
        [institutionTypes],
    );

    useEffect(() => {
        tableRef.current?.refreshData();
        dispatch(fetchInstitutionTypes());
    }, [formState, tableRef, dispatch]);

    return {
        isFetching: isFetchingInstitutions || isFetchingTypes,
        fetchError: fetchInstitutionsError || fetchTypesError,
        institutionTypeOptions,
        institutions,
        handleFetch,
        formState,
        handleChange,
        tableRef,
        itemCount,
    };
};

export default useFetchInstitutions;
