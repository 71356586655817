import dayjs from 'dayjs';

import useFetchActivityHistoriesForCcdb from '@pages/ccdbGroups/ccdbGroup/activityHistories/_hooks/useFetchActivityHistoriesForCcdb';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Table from '@components/table/Table';

import { TableColumns } from '../../../../types/shared/table';
import { ActivityHistory } from '../../../../types/models/ActivityHistories';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';

const CcdbGroupActivityHistories = () => {
    const { isFetching, fetchError, activityHistories, groupID } =
        useFetchActivityHistoriesForCcdb();

    return (
        <ContentBlock>
            <CreateHeader>
                Activity Histories
                <ButtonRow>
                    <LinkButton size="small" href={`/ccdb-groups/${groupID}/apply-activities`}>
                        Apply Activities
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>

            <Table
                columns={columns}
                rows={activityHistories}
                isLoading={isFetching}
                error={fetchError}
            />
        </ContentBlock>
    );
};

const columns: TableColumns<ActivityHistory> = [
    {
        key: 1,
        heading: 'Activity Name(s)',
        getValue: row => row.activityNames.join(', '),
    },
    {
        key: 2,
        heading: 'User Count',
        getValue: activityHistory => activityHistory.userCount,
    },
    {
        key: 3,
        heading: 'Location',
        getValue: activityHistory => activityHistory.activityLocation,
    },
    {
        key: 4,
        heading: 'Date',
        getValue: activityHistory => dayjs(activityHistory.activityDate).format('DD/MM/YYYY'),
    },
    {
        key: 5,
        heading: 'Processed By',
        getValue: activityHistory => activityHistory.adminProcessedByName,
    },
    {
        key: 5,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton
                    source="negative"
                    href={`/ccdb-groups/${row.ccdbGroupID}/undo-activity-history/${row.id}`}
                >
                    Remove History
                </LinkButton>
            </ButtonRow>
        ),
    },
];

export default CcdbGroupActivityHistories;
