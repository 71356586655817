import Modal from '@components/modal/Modal';
import useUpdateUserActivity from '@pages/users/user/hooks/useUpdateUserActivity';
import Form from '@components/form/Form';
import DatePicker from '@components/form/DatePicker';
import TextInput from '@components/form/TextInput';

const EditUserActivityModal = () => {
    const {
        form: { activityDate, activityLocation },
        handleChange,
        handleSubmit,
        closeModal,
        isPosting,
        error,
    } = useUpdateUserActivity();

    return (
        <Modal title="Update Activity" style={{ overflow: 'unset' }}>
            <Form onSubmit={handleSubmit} onCancel={closeModal} isPosting={isPosting} error={error}>
                <DatePicker
                    label="Activity Date"
                    name="activityDate"
                    value={new Date(activityDate) || new Date()}
                    onChange={handleChange}
                />

                <TextInput
                    label="Activity Location"
                    name="activityLocation"
                    value={activityLocation}
                    onChange={handleChange}
                />
            </Form>
        </Modal>
    );
};

export default EditUserActivityModal;
