import React from 'react';
import SchoolCreateEditModal from '../shared/SchoolCreateEditModal';
import useCreateSchool from './hooks/useCreateSchool';

const SchoolCreateModal: React.FC = () => {
    const { handleSubmit, initialFormState } = useCreateSchool();

    return (
        <SchoolCreateEditModal
            onSubmit={handleSubmit}
            initialFormState={initialFormState}
            title="Create School"
            redirectURL="/schools"
        />
    );
};

export default SchoolCreateModal;
