import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectActivityHistories,
    selectActivityHistoriesFetchError,
    selectActivityHistoriesIsFetching,
} from '@selectors/activityHistories';
import { useEffect } from 'react';
import { fetchActivityHistoriesForCcdb } from '@actions/activityHistories/fetchActivityHistoriesForCcdb';

const useFetchActivityHistoriesForCcdb = () => {
    const dispatch = useDispatch();

    const { id } = useParams<{ id: string }>();
    const groupID = +id;

    const activityHistories = useSelector(selectActivityHistories);
    const isFetching = useSelector(selectActivityHistoriesIsFetching);
    const fetchError = useSelector(selectActivityHistoriesFetchError);

    const filteredActivityHistories = Object.values(activityHistories)
        .filter(ah => ah.ccdbGroupID && ah.ccdbGroupID === groupID)
        .sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());

    useEffect(() => {
        dispatch(fetchActivityHistoriesForCcdb(groupID));
    }, [dispatch, groupID]);

    return { isFetching, fetchError, activityHistories: filteredActivityHistories, groupID };
};

export default useFetchActivityHistoriesForCcdb;
