import ConfirmModal from '@components/modal/ConfirmModal';
import React from 'react';
import { ActivityGroup } from 'src/types/models/ActivityGroups';
import useDeleteActivity from './hooks/useDeleteActivity';

interface Props {
    activityGroup: ActivityGroup;
}

const DeleteActivityModal: React.FC<Props> = ({ activityGroup }) => {
    const { isPosting, handleSubmit, closeModal, error } = useDeleteActivity(activityGroup);

    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete Activity"
            description="Are you sure you want to delete this activity?"
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            error={error}
        />
    );
};

export default DeleteActivityModal;
