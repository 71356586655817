import ActionButton from '@components/button/ActionButton';
import React, { useState, useRef, useEffect, useMemo, SyntheticEvent, MouseEvent } from 'react';
import { useEventListener } from '../../hooks/useEventListener';

import useFieldValidation from '../../hooks/useFieldValidation';
import usePrevious from '../../hooks/usePrevious';
import { DropdownOption } from '../../types/shared/DropdownOption';
import { FormInputProps } from '../../types/shared/FormInputProps';

import FormField from './FormField';

// MultiComboSelect is a multi select dropdown
// pass 'required' prop if it's required
// pass 'search' prop if you want to enable the search
// pass 'disabled' flag if you want to disable the dropdown
// pass a 'placeholder' string if you want to customize the placeholder
// field errors will be output below automatically
// options should be in this form '[{ value: 1, label: "opt 1" }, { value: 2, label: "opt 2" }]'
// value should be an array of selected values i.e '[1, 2]'
// pass an empty array as the default value
const MultiComboSelect: React.FC<MultiComboSelectProps> = ({
    name,
    disabled = false,
    value = [],
    options = [],
    onChange,
    label = '',
    placeholder = 'Select...',
    required,
    customValidate,
}) => {
    const [error, showError] = useFieldValidation({
        name,
        required,
        customValidate,
        value,
    });

    const [createdOptions, setCreatedOptions] = useState<DropdownOption<string>[]>([]);

    const [searchTerm, setSearch] = useState('');
    const node = useRef<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);
    const prevIsOpen = usePrevious(isOpen);

    useEffect(() => {
        if (!isOpen && prevIsOpen) {
            showError();
        }
    }, [isOpen, showError, prevIsOpen]);

    useEventListener('mousedown', handleClick);

    const allOptions = useMemo(() => {
        const allItems = options.concat(createdOptions);
        return allItems.sort((a, b) => a.label.localeCompare(b.label));
    }, [options, createdOptions]);

    const filteredOptions = useMemo(_getFilteredOptions, [searchTerm, allOptions]);
    const selected = useMemo(_getSelected, [value, allOptions]);

    return (
        <FormField name={name} label={label} required={required} error={error}>
            <div ref={node}>
                <div onClick={() => !disabled && setIsOpen(!isOpen)}>
                    <div
                        className={`form-select multi-select ${disabled ? 'disabled' : ''} ${
                            error ? 'error' : ''
                        }`}
                        ref={node}
                    >
                        <div className="selected">
                            {selected.map(opt => (
                                <div
                                    key={opt.value}
                                    className="option"
                                    onClick={() => isOpen && setIsOpen(false)}
                                >
                                    <p>{opt.label}</p>
                                    {!disabled && (
                                        <button
                                            className="remove"
                                            onClick={e => handleDeselect(e, opt.value)}
                                        >
                                            <i className="fal fa-times" />
                                        </button>
                                    )}
                                </div>
                            ))}

                            {!selected.length && <p className="placeholder">{placeholder}</p>}
                        </div>

                        {selected.length && !disabled && (
                            <button className="remove" onClick={e => handleClearAll(e)}>
                                <i className="fal fa-times" />
                            </button>
                        )}

                        <i className="arrow fal fa-angle-down" />
                    </div>
                </div>

                {isOpen && (
                    <div className="form-select-options">
                        <div
                            className="search combo-select-search"
                            onClick={e => e.stopPropagation()}
                        >
                            <input
                                className="form-input combo-select-search-input"
                                type="text"
                                placeholder="Search or create..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyDown={_handleAddOptionEnter}
                            />
                            <div className="negative combo-select-add">
                                <ActionButton
                                    type="button"
                                    source="positive"
                                    icon="plus"
                                    onClick={_handleAddOption}
                                />
                            </div>
                        </div>
                        <div className="options-list">
                            {!filteredOptions.length && <p>There are no options to display</p>}
                            {filteredOptions.map(opt => (
                                <p
                                    key={opt.value}
                                    className={`option ${
                                        value.includes(opt.value) ? 'active' : ''
                                    }`}
                                    onClick={(e: MouseEvent) => {
                                        handleSelect(e, opt.value);
                                    }}
                                >
                                    {opt.label}
                                </p>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </FormField>
    );

    function _handleAddOptionEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            _addOption();
        }
    }

    function _handleAddOption(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        _addOption();
    }

    function _addOption() {
        const text = searchTerm.trim();

        if (allOptions.every(opt => opt.value !== text)) {
            setCreatedOptions(prev => prev.concat({ label: text, value: text }));
        }

        handleChange(value.concat(text));
        setSearch('');
    }

    function _getSelected(): DropdownOption<string>[] {
        const selectedOptions = value
            .filter(val => !allOptions.find(({ value }) => value === val))
            .map(val => ({ label: val.toString(), value: val }));

        return allOptions.filter(opt => value.includes(opt.value)).concat(selectedOptions);
    }

    function _getFilteredOptions() {
        if (!searchTerm) return allOptions;

        const formattedTerm = searchTerm.replace(/[^A-Z0-9]/gi, '').toLowerCase();
        return allOptions.filter(opt =>
            opt.label
                .replace(/[^A-Z0-9]/gi, '')
                .toLowerCase()
                .includes(formattedTerm),
        );
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        setSearch(e.target.value);
    }

    function handleClick(e: Event) {
        // inside click
        if (node?.current?.contains(e.target as Node)) {
            return;
        }

        // outside click
        setIsOpen(false);
    }
    function handleDeselect(e: MouseEvent, clicked: string) {
        e.preventDefault();
        e.stopPropagation();

        const newVal = value.filter(item => item !== clicked);
        handleChange(newVal);
        showError();
    }

    function handleSelect(e: MouseEvent, clicked: string) {
        e.preventDefault();

        const newVal = value.includes(clicked)
            ? value.filter(item => item !== clicked)
            : value.concat(clicked);

        handleChange(newVal);
    }

    function handleClearAll(e: SyntheticEvent) {
        e.preventDefault();
        e.stopPropagation();
        showError();

        setIsOpen(false);

        const newVal: string[] = [];
        handleChange(newVal);
    }

    function handleChange(newVal: string[]) {
        onChange(name, newVal);
    }
};

interface MultiComboSelectProps extends FormInputProps<string[]> {
    options: DropdownOption<string>[];
    label?: string;
    placeholder?: string;
}

export default MultiComboSelect;
