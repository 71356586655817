import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';

import { api, handleApiErrors, LoginResponse } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

// Login
export const postLoginRequest = createAction('postLoginRequest');
export const postLoginSuccess = createAction('postLoginSuccess');
export const postLoginFailure = createAction<string>('postLoginFailure');

export const postLogin =
    (postBody: LoginRequestModel) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postLoginRequest());

        try {
            const { data } = await api.post<LoginRequestModel, LoginResponse>(
                'auth/login',
                postBody,
            );

            localStorage.setItem('jwt', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);

            dispatch(postLoginSuccess());
        } catch (e) {
            handleApiErrors(dispatch, postLoginFailure, e as APIError);
        }
    };

interface LoginRequestModel {
    email: string;
    password: string;
}
