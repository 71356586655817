import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import FormField from '@components/form/FormField';
import TextInput from '@components/form/TextInput';
import useEditActivityGroup from './hooks/useEditActivityGroup';
import { ActivityGroup } from 'src/types/models/ActivityGroups';

interface Props {
    activityGroup: ActivityGroup;
}

const EditActivityGroupModal: React.FC<Props> = ({ activityGroup }) => {
    const { form, handleSubmit, closeModal, handleChange, isPosting, postError } =
        useEditActivityGroup(activityGroup);

    return (
        <Modal title="Edit Activity Group">
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                error={postError}
                isPosting={isPosting}
            >
                <FormField name="name" label="Name">
                    <TextInput name="name" value={form.name} onChange={handleChange} />
                </FormField>
            </Form>
        </Modal>
    );
};

export default EditActivityGroupModal;
