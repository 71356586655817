import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { updateSchoolGroup } from '@actions/schoolGroups';
import usePrevious from '@hooks/usePrevious';
import {
    getSchoolGroup,
    getSchoolGroupsIsPosting,
    getSchoolGroupsPostError,
    getSchoolGroupsPostSuccess,
} from '@selectors/schoolGroups';
import { useCallback, useEffect } from 'react';
import useForm from '@hooks/useForm';
import { useAppSelector } from './../../../../redux/store';

const useEditSchoolGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const schoolGroup = useAppSelector(state => getSchoolGroup(state, +id));
    const isPosting = useSelector(getSchoolGroupsIsPosting);
    const error = useSelector(getSchoolGroupsPostError);
    const success = useSelector(getSchoolGroupsPostSuccess);

    const [formState, handleChange] = useForm({ name: schoolGroup?.name });

    const closeModal = useCallback(() => {
        history.replace(`/school-groups`);
    }, [history]);

    const handleSubmit = () => {
        dispatch(updateSchoolGroup(+id, formState));
    };

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [prevSuccess, success, closeModal]);

    return { closeModal, handleSubmit, isPosting, error, formState, handleChange, schoolGroup };
};

export default useEditSchoolGroup;
