import { Switch } from 'react-router-dom';

import AdminUsersRoutes from './AdminUsersRoutes';
import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import UsersRoutes from './UsersRoutes';
import SchoolRoutes from './SchoolsRoutes';
import SchoolGroupsRoutes from './SchoolGroupsRoutes';
import ActivityGroupsRoutes from './ActivityGroupsRoutes';
import ActivitiesRoutes from './ActivitiesRoutes';
import DefaultRedirect from './DefaultRedirect';
import CcdbRoutes from '@routes/CcdbRoutes';
import ParticipantDataRoutes from './ParticipantDataRoutes';
import InstitutionsRoutes from './InstitutionsRoutes';
import InstitutionTypesRoutes from './InstitutionsTypesRoutes';

const Routes = () => (
    <Switch>
        <SubRouter path="/auth">
            <AuthRoutes />
        </SubRouter>
        <SubRouter path="/users" auth>
            <UsersRoutes />
        </SubRouter>
        <SubRouter path="/admin-users" auth>
            <AdminUsersRoutes />
        </SubRouter>
        <SubRouter path="/participant-data">
            <ParticipantDataRoutes />
        </SubRouter>
        <SubRouter path="/schools" auth>
            <SchoolRoutes />
        </SubRouter>
        <SubRouter path="/school-groups" auth>
            <SchoolGroupsRoutes />
        </SubRouter>
        <SubRouter path="/institutions" auth>
            <InstitutionsRoutes />
        </SubRouter>
        <SubRouter path="/institution-types" auth>
            <InstitutionTypesRoutes />
        </SubRouter>
        <SubRouter path="/activity-groups" auth>
            <ActivityGroupsRoutes />
        </SubRouter>
        <SubRouter path="/activities" auth>
            <ActivitiesRoutes />
        </SubRouter>
        <SubRouter path="/ccdb-groups" auth>
            <CcdbRoutes />
        </SubRouter>
        <DefaultRedirect to="/admin-users" />
    </Switch>
);

export default Routes;
