import dayjs from 'dayjs';

import useFetchUserActivities from '@pages/users/user/hooks/useFetchUserActivities';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from '@components/typography/Title';
import Table from '@components/table/Table';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';

import { TableColumns } from '../../../types/shared/table';
import { UserActivities as IUserActivities } from '../../../types/models/Activities';

import DeleteUserActivityModal from '@pages/users/user/DeleteUserActivityModal';
import EditUserActivityModal from '@pages/users/user/EditUserActivityModal';

const UserActivities = ({ showDeleteActivityModal, showEditActivityModal }: Props) => {
    const { isFetching, error, activities } = useFetchUserActivities();

    return (
        <>
            <ContentBlock>
                <CreateHeader>
                    <Title>Activities</Title>
                </CreateHeader>

                <Table isLoading={isFetching} error={error} columns={columns} rows={activities} />
            </ContentBlock>

            {showDeleteActivityModal && <DeleteUserActivityModal />}
            {showEditActivityModal && <EditUserActivityModal />}
        </>
    );
};

const columns: TableColumns<IUserActivities> = [
    {
        key: 1,
        heading: 'Name',
        getValue: activity => activity.activity,
    },
    {
        key: 2,
        heading: 'Activity Group',
        getValue: activity => activity.activityGroup || 'N/A',
    },
    {
        key: 3,
        heading: 'Activity Location',
        getValue: activity => activity.activityLocation,
    },
    {
        key: 4,
        heading: 'Date',
        getValue: activity => dayjs(activity.activityDate).format('DD/MM/YYYY'),
    },
    {
        key: 5,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton
                    source="secondary"
                    href={`/users/${row.userID}/activities/${row.id}/edit`}
                >
                    Edit
                </LinkButton>
                <LinkButton
                    source="negative"
                    href={`/users/${row.userID}/activities/${row.id}/delete`}
                >
                    Delete
                </LinkButton>
            </ButtonRow>
        ),
    },
];

interface Props {
    showDeleteActivityModal?: boolean;
    showEditActivityModal?: boolean;
}

export default UserActivities;
