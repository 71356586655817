import { useMemo } from 'react';
import { defaultCountryNames, extraCountryNames, allCountryNames } from '@utils/countries';

export default function useCountryOptions() {
    const countryOptions = useMemo(() => getOptions(allCountryNames, ['GB']), []);
    const defaultCountryOptions = useMemo(() => getOptions(defaultCountryNames, ['GB']), []);
    const ukSubCountryOptions = useMemo(() => getOptions(extraCountryNames, []), []);

    return { countryOptions, defaultCountryOptions, ukSubCountryOptions };
}

const getOptions = (options: Record<string, string>, defaultCountryCodes: string[]) => {
    return Object.entries(options)
        .map(([countryCode, countryName]) => ({ label: countryName, value: countryCode }))
        .sort((a, b) => {
            const defaultOrderIndexA = defaultCountryCodes.findIndex(x => x === a.value);
            const defaultOrderIndexB = defaultCountryCodes.findIndex(x => x === b.value);

            if (defaultOrderIndexA === -1 && defaultOrderIndexB === -1) {
                return a.label.localeCompare(b.label);
            }

            if (defaultOrderIndexA >= 0 && defaultOrderIndexB >= 0) {
                return defaultOrderIndexA - defaultOrderIndexB;
            }

            return defaultOrderIndexB - defaultOrderIndexA;
        });
};
