import { FormInputProps } from '../../types/shared/FormInputProps';
import useCountryOptions from '@hooks/useCountryOptions';
import MultiSelect from './MultiSelect';
import { useMemo } from 'react';

interface Props extends FormInputProps<string[]> {
    label?: string;
    placeholder?: string;
    subPlaceholder?: string;
    alwaysShowSub?: boolean;
}

const CountryMultiSelect: React.FC<Props> = ({
    name,
    label,
    value = [],
    onChange,
    required,
    placeholder,
    subPlaceholder,
    alwaysShowSub,
}) => {
    const { defaultCountryOptions, ukSubCountryOptions } = useCountryOptions();
    const ukOptionValues = useMemo(
        () => ukSubCountryOptions.map(opt => opt.value),
        [ukSubCountryOptions],
    );

    const hasSubValues = value.some(x => ukOptionValues.includes(x));
    const showSub = value.includes('GB') || hasSubValues;

    return (
        <>
            <MultiSelect
                search
                label={label}
                name={name}
                value={
                    hasSubValues
                        ? value.filter(x => x !== 'GB' && !ukOptionValues.includes(x)).concat('GB')
                        : value
                }
                options={defaultCountryOptions}
                onChange={(_, val) => {
                    if (val.includes('GB') && hasSubValues) {
                        const subVals = value.filter(x => ukOptionValues.includes(x));
                        const newVal = val.filter(x => x !== 'GB').concat(subVals);

                        onChange(name, newVal);
                    } else {
                        onChange(name, val);
                    }
                }}
                required={required}
                placeholder={placeholder}
            />

            {(alwaysShowSub || showSub) && (
                <MultiSelect
                    label="UK Country"
                    name={`${name}---ukSubCountries`}
                    value={
                        value?.includes('GB') ? [] : value.filter(x => ukOptionValues.includes(x))
                    }
                    options={ukSubCountryOptions}
                    placeholder={subPlaceholder}
                    onChange={(_, val) => {
                        const defaultCountryVals = value.filter(x => !ukOptionValues.includes(x));

                        const newVal = val.length
                            ? defaultCountryVals.filter(x => x !== 'GB').concat(val)
                            : defaultCountryVals.concat('GB');

                        onChange(name, newVal);
                    }}
                />
            )}
        </>
    );
};

export default CountryMultiSelect;
