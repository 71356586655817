import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    fetchSearchedAdminUsersRequest,
    fetchSearchedAdminUsersSuccess,
    fetchSearchedAdminUsersFailure,
} from '@actions/adminUsers/fetchSearchedAdminUsers';
import AdminUser, { AdminUsersResponse } from 'src/types/models/AdminUser';

interface AdminUsersState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    itemCount: number;
    adminUsers: AdminUser[];
}

const initialState: AdminUsersState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    itemCount: 0,
    adminUsers: [],
};

export default createReducer(initialState, {
    [fetchSearchedAdminUsersRequest.type]: handleFetchRequest,
    [fetchSearchedAdminUsersSuccess.type]: handleFetchSearchedAdminUsersSuccess,
    [fetchSearchedAdminUsersFailure.type]: handleFetchError,
});

function handleFetchRequest(state: AdminUsersState) {
    state.isFetching = true;
}

function handleFetchSearchedAdminUsersSuccess(
    state: AdminUsersState,
    action: PayloadAction<AdminUsersResponse>,
) {
    state.adminUsers = action.payload.items;
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handleFetchError(state: AdminUsersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
