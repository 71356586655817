import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CcdbGroups from '@pages/ccdbGroups/ccdbGroups/CcdbGroups';
import CcdbGroupContainer from '@pages/ccdbGroups/ccdbGroup/CcdbGroupContainer';

import DefaultRedirect from './DefaultRedirect';

const CcdbRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <CcdbGroups />
            </Route>
            <Route exact path={`${path}/create`}>
                <CcdbGroups showCreateModal />
            </Route>
            <Route path={`${path}/:id/edit`}>
                <CcdbGroupContainer showEditModal />
            </Route>
            <Route exact path={`${path}/:id/delete`}>
                <CcdbGroupContainer showDeleteModal />
            </Route>
            <Route exact path={`${path}/:id/add-users`}>
                <CcdbGroupContainer showAddUserModal />
            </Route>
            <Route exact path={`${path}/:id/remove-users`}>
                <CcdbGroupContainer showRemoveUserModal />
            </Route>
            <Route exact path={`${path}/:id/apply-activities`}>
                <CcdbGroupContainer showApplyActivitiesModal />
            </Route>
            <Route exact path={`${path}/:id/undo-activity-history/:historyID`}>
                <CcdbGroupContainer showUndoActivityHistoryModal />
            </Route>
            <Route path={`${path}/:id`}>
                <CcdbGroupContainer />
            </Route>
            <DefaultRedirect to={path} />
        </Switch>
    );
};

export default CcdbRoutes;
