import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteSchoolGroupRequest = createAction('deleteSchoolGroupRequest');
export const deleteSchoolGroupSuccess = createAction<number>('deleteSchoolGroupSuccess');
export const deleteSchoolGroupFailure = createAction('deleteSchoolGroupFailure');

export const deleteSchoolGroup =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(deleteSchoolGroupRequest());

        try {
            await api.delete(`schoolGroups/${id}`);
            dispatch(deleteSchoolGroupSuccess(id));
        } catch (e) {
            handleApiErrors(dispatch, deleteSchoolGroupFailure, e as APIError);
        }
    };
