import { MegeSchoolStepsProps } from './shared/MegeSchoolStepsProps';
import Select from '@components/form/Select';
import useSchoolMergeOptions from './hooks/useSchoolMergeOptions';
import { humanizeEnumName } from '@utils/enums';
import useSchoolsMergeActivityOptions from './hooks/useSchoolsMergeActivityOptions';
import { getCountryNames } from '@utils/countries';

const SchoolsMergeStep3: React.FC<MegeSchoolStepsProps> = ({
    schools,
    formState,
    handleChange,
}) => {
    return (
        <>
            <Select
                required
                omitRemove
                label="Whole School Partnership Preferences"
                name="wholeSchoolPartnershipPreferencesSourceID"
                value={formState.wholeSchoolPartnershipPreferencesSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    humanizeEnumName(s.wholeSchoolPartnershipPreferences),
                )}
            />
            <Select
                required
                omitRemove
                label="Partner Interest Locations"
                name="partnerSchoolInterestLocationsSourceID"
                value={formState.partnerSchoolInterestLocationsSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    getCountryNames(s.partnerSchoolInterestLocations),
                )}
            />
            <Select
                required
                omitRemove
                label="Current Partner School Locations"
                name="currentPartnerSchoolLocationsSourceID"
                value={formState.currentPartnerSchoolLocationsSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    getCountryNames(s.currentPartnerSchoolLocations),
                )}
            />
            <Select
                required
                omitRemove
                label="Activities Interested In"
                name="activitiesInterestedInSourceID"
                value={formState.activitiesInterestedInSourceID}
                onChange={handleChange}
                options={useSchoolsMergeActivityOptions(schools, s => s.activitiesInterestedIn)}
            />
            <Select
                required
                omitRemove
                label="Activities Involved In"
                name="activitiesInvolvedInSourceID"
                value={formState.activitiesInvolvedInSourceID}
                onChange={handleChange}
                options={useSchoolsMergeActivityOptions(schools, s => s.activitiesInvolvedIn)}
            />
            <Select
                required
                omitRemove
                label="Partners Found Via Schools Online"
                name="partnersFoundViaSchoolsOnlineSourceID"
                value={formState.partnersFoundViaSchoolsOnlineSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    humanizeEnumName(s.partnersFoundViaSchoolsOnline),
                )}
            />
            <Select
                required
                omitRemove
                label="Schools Online Provided Useful Information"
                name="schoolsOnlineProvidedUsefulInformation"
                value={formState.schoolsOnlineProvidedUsefulInformationSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    humanizeEnumName(s.schoolsOnlineProvidedUsefulInformation),
                )}
            />
            <Select
                required
                omitRemove
                label="Partner School Suggested"
                name="partnerSchoolSuggestedSourceID"
                value={formState.partnerSchoolSuggestedSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    humanizeEnumName(s.partnerSchoolSuggested),
                )}
            />
            <Select
                required
                omitRemove
                label="Interested In International School Award"
                name="interestedInInternationalSchoolAwardSourceID"
                value={formState.interestedInInternationalSchoolAwardSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    humanizeEnumName(s.interestedInInternationalSchoolAward),
                )}
            />
        </>
    );
};

export default SchoolsMergeStep3;
