import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { UsersResponse } from 'src/types/models/User';
import { APITableParams } from 'src/types/shared/table';

export const fetchSearchedUsersRequest = createAction('fetchSearchedUsersRequest');
export const fetchSearchedUsersSuccess = createAction<UsersResponse>('fetchSearchedUsersSuccess');
export const fetchSearchedUsersFailure = createAction('fetchSearchedUsersFailure');

export const fetchSearchedUsers =
    (params: APITableParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSearchedUsersRequest());
        try {
            const { data } = await api.get<UsersResponse>('users', params);

            dispatch(fetchSearchedUsersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSearchedUsersFailure, e as APIError);
        }
    };
