import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';

import { APIError } from '../../../types/shared/APIError';
import { CcdbGroup } from '../../../types/models/Ccdb';
import { AppDispatch } from '../../store';

export const fetchAllCcdbGroupsRequest = createAction('fetchAllCcdbGroupsRequest');
export const fetchAllCcdbGroupsSuccess = createAction<CcdbGroup[]>('fetchAllCcdbGroupsSuccess');
export const fetchAllCcdbGroupsFailure = createAction('fetchAllCcdbGroupsFailure');

export const fetchAllCcdbGroups = () => async (dispatch: AppDispatch) => {
    dispatch(fetchAllCcdbGroupsRequest());

    try {
        const { data } = await api.get<CcdbGroup[]>('CCDBGroups');

        dispatch(fetchAllCcdbGroupsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllCcdbGroupsFailure, e as APIError);
    }
};
