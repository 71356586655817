import FormRow from '@components/form/FormRow';
import Select from '@components/form/Select';
import TextInput from '@components/form/TextInput';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import SubTitle from '@components/typography/SubTitle';
import { ParticipantFilterFormState } from './_hooks/useFetchParticipantData';
import useParticipantDataFilterOptions from './_hooks/useParticipantDataFilterOptions';
import ActionButton from '@components/button/ActionButton';
import CountrySelect from '@components/form/CountrySelect';

const ParticipantDataFilters = ({ filters, handleChange, resetForm }: Props) => {
    const { activityOptions, activityGroupOptions, ccdbGroupOptions } =
        useParticipantDataFilterOptions();

    return (
        <ContentBlock>
            <SubTitle tag="h3">Participant Data Filters</SubTitle>

            <div>
                <FormRow>
                    <Select
                        name="activityID"
                        label="Activity"
                        value={filters.activityID}
                        onChange={handleChange}
                        options={activityOptions}
                    />

                    <Select
                        name="activityGroupID"
                        label="Activity Group"
                        value={filters.activityGroupID}
                        onChange={handleChange}
                        options={activityGroupOptions}
                    />
                    <Select
                        name="ccdbGroupID"
                        label="CCDB Group"
                        value={filters.ccdbGroupID}
                        onChange={handleChange}
                        options={ccdbGroupOptions}
                    />
                </FormRow>

                <FormRow>
                    <TextInput
                        name="name"
                        label="Name"
                        value={filters.name}
                        onChange={handleChange}
                    />
                    <TextInput
                        name="phoneNumber"
                        label="Phone Number"
                        value={filters.phoneNumber}
                        onChange={handleChange}
                    />
                    <TextInput
                        name="email"
                        label="Email"
                        value={filters.email}
                        onChange={handleChange}
                    />
                </FormRow>

                <FormRow>
                    <TextInput
                        name="username"
                        label="Username"
                        value={filters.username}
                        onChange={handleChange}
                    />
                    <CountrySelect
                        alwaysShowSub
                        name="country"
                        label="Country"
                        value={filters.country}
                        onChange={handleChange}
                    />
                </FormRow>

                <ActionButton source="secondary" type="button" onClick={resetForm}>
                    Reset Filters
                </ActionButton>
            </div>
        </ContentBlock>
    );
};

interface Props {
    filters: ParticipantFilterFormState;
    handleChange: <T>(name: keyof ParticipantFilterFormState, value: T) => void;
    resetForm: () => void;
}

export default ParticipantDataFilters;
