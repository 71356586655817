import useFetchActivityGroup from './hooks/useFetchActivityGroup';

import CreateActivityModal from './CreateActivityModal';

import EditActivityGroupModal from './EditActivityGroupModal';
import DeleteActivityGroupModal from './DeleteActivityGroupModal';
import DataCheck from '@components/common/DataCheck';
import ActivityGroup from './ActivityGroup';

interface Props {
    showCreateActivityModal?: boolean;
    showDeleteModal?: boolean;
    showEditModal?: boolean;
}

const ActivityGroupContainer = ({
    showCreateActivityModal,
    showDeleteModal,
    showEditModal,
}: Props) => {
    const { activityGroup, isFetching, error } = useFetchActivityGroup();

    return (
        <>
            <DataCheck isFetching={isFetching} error={error} dataExists={!!activityGroup}>
                <ActivityGroup activityGroup={activityGroup} />
            </DataCheck>

            {showCreateActivityModal && <CreateActivityModal />}
            {activityGroup && showEditModal && (
                <EditActivityGroupModal activityGroup={activityGroup} />
            )}
            {showDeleteModal && <DeleteActivityGroupModal />}
        </>
    );
};
export default ActivityGroupContainer;
