import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createCcdbGroup } from '@actions/ccdb';
import {
    selectCcdbGroupsIsPosting,
    selectCcdbGroupsPostError,
    selectCcdbGroupsPostSuccess,
} from '@selectors/ccdbGroups';
import usePrevious from '@hooks/usePrevious';
import useForm from '@hooks/useForm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropdownOption } from 'src/types/shared/DropdownOption';
import { getSearchedAdminUsers } from '@selectors/searchedAdminUsers';
import { fetchSearchedAdminUsers } from '@actions/adminUsers/fetchSearchedAdminUsers';

const useCreateCcdbGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [searchTerm, setSearchTerm] = useState('');
    const prevSearchTerm = usePrevious(searchTerm);
    const searchTimeout = useRef<number | null>(null);

    const isPosting = useSelector(selectCcdbGroupsIsPosting);
    const postError = useSelector(selectCcdbGroupsPostError);
    const postSuccess = useSelector(selectCcdbGroupsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm<{ name: string; ownerAdminUserID: number | null }>({
        name: '',
        ownerAdminUserID: null,
    });

    const onSearchTermChanged = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        if (searchTerm !== prevSearchTerm) {
            window.clearTimeout(searchTimeout.current || 0);
            searchTimeout.current = window.setTimeout(() => {
                dispatch(
                    fetchSearchedAdminUsers({ page: null, limit: null, searchTerm: newSearchTerm }),
                );
            }, 1000);
        }
    };

    useEffect(() => {
        dispatch(fetchSearchedAdminUsers({ page: null, limit: null, searchTerm: '' }));
    }, [dispatch]);

    const adminUsers = useSelector(getSearchedAdminUsers);
    const adminUserOptions = useMemo(() => {
        return Object.values(adminUsers).reduce((acc: DropdownOption<number>[], adminUser) => {
            acc.push({
                label: `${adminUser.firstName} ${adminUser.lastName}`,
                value: adminUser.id,
            });

            return acc;
        }, []);
    }, [adminUsers]);

    const closeModal = useCallback(() => {
        history.replace(`/ccdb-groups`);
    }, [history]);

    const handleSubmit = () => {
        dispatch(createCcdbGroup(form));
    };

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        closeModal,
        handleSubmit,
        isPosting,
        postError,
        form,
        handleChange,
        adminUserOptions,
        onSearchTermChanged,
    };
};

export default useCreateCcdbGroup;
