import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { api, handleApiErrors } from '@utils/api';
import { ActivityHistory } from '../../../types/models/ActivityHistories';
import { APIError } from '../../../types/shared/APIError';

export const fetchActivityHistoriesForCcdbRequest = createAction(
    'fetchActivityHistoriesForCcdbRequest',
);
export const fetchActivityHistoriesForCcdbSuccess = createAction<ActivityHistory[]>(
    'fetchActivityHistoriesForCcdbSuccess',
);
export const fetchActivityHistoriesForCcdbFailure = createAction(
    'fetchActivityHistoriesForCcdbFailure',
);

export const fetchActivityHistoriesForCcdb = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchActivityHistoriesForCcdbRequest());

    try {
        const { data } = await api.get<ActivityHistory[]>(`UserActivityHistories/CCDBGroup/${id}`);

        dispatch(fetchActivityHistoriesForCcdbSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchActivityHistoriesForCcdbFailure, e as APIError);
    }
};
