import { MegeSchoolStepsProps } from './shared/MegeSchoolStepsProps';
import Select from '@components/form/Select';
import useSchoolMergeOptions from './hooks/useSchoolMergeOptions';
import { humanizeEnumName } from '@utils/enums';

const SchoolsMergeStep2: React.FC<MegeSchoolStepsProps> = ({
    schools,
    formState,
    handleChange,
}) => {
    return (
        <>
            <Select
                allowImages
                omitRemove
                required
                label="School Photograph"
                name="schoolPhotographS3KeySourceID"
                value={formState.schoolPhotographS3KeySourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.schoolPhotographS3Key)}
            />
            <Select
                required
                omitRemove
                label="Head Teacher"
                name="headTeacherSourceID"
                value={formState.headTeacherSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.headTeacher)}
            />
            <Select
                required
                omitRemove
                label="School Group"
                name="schoolGroupSourceID"
                value={formState.schoolGroupSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.schoolGroup)}
            />
            <Select
                required
                omitRemove
                label="School Type"
                name="schoolTypesSourceID"
                value={formState.schoolTypesSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    s.schoolTypes.map(st => humanizeEnumName(st)).join(', '),
                )}
            />
            <Select
                required
                omitRemove
                label="School Gender"
                name="schoolGenderSourceID"
                value={formState.schoolGenderSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => humanizeEnumName(s.schoolGender))}
            />
            <Select
                required
                omitRemove
                label="Provisions For Special Educational Needs"
                name="provisionsForSpecialEducationalNeedsSourceID"
                value={formState.provisionsForSpecialEducationalNeedsSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    humanizeEnumName(s.provisionsForSpecialEducationalNeeds),
                )}
            />
            <Select
                required
                omitRemove
                label="School Status"
                name="schoolStatusSourceID"
                value={formState.schoolStatusSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => humanizeEnumName(s.schoolStatus))}
            />
            <Select
                required
                omitRemove
                label="Number of Pupils"
                name="numberOfPupilsSourceID"
                value={formState.numberOfPupilsSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.numberOfPupils)}
            />
            <Select
                required
                omitRemove
                label="Facebook ID"
                name="facebookIDSourceID"
                value={formState.facebookIDSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.facebookID)}
            />
            <Select
                required
                omitRemove
                label="Twitter ID"
                name="twitterIDSourceID"
                value={formState.twitterIDSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.twitterID)}
            />
            <Select
                required
                omitRemove
                label="International Coordinator"
                name="internationalCoordinatorSourceID"
                value={formState.internationalCoordinatorSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => s.internationalCoordinator)}
            />
            <Select
                required
                omitRemove
                label="Has video confrencing?"
                name="hasVideoConferencingSourceID"
                value={formState.hasVideoConferencingSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s =>
                    s.hasVideoConferencing ? 'Yes' : 'No',
                )}
            />
            <Select
                required
                omitRemove
                label="Has SMS?"
                name="hasSMSSourceID"
                value={formState.hasSMSSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => (s.hasSMS ? 'Yes' : 'No'))}
            />
            <Select
                required
                omitRemove
                label="Has IT?"
                name="hasITSourceID"
                value={formState.hasITSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => (s.hasIT ? 'Yes' : 'No'))}
            />
            <Select
                required
                omitRemove
                label="Has Email?"
                name="hasEmailSourceID"
                value={formState.hasEmailSourceID}
                onChange={handleChange}
                options={useSchoolMergeOptions(schools, s => (s.hasEmail ? 'Yes' : 'No'))}
            />
        </>
    );
};

export default SchoolsMergeStep2;
