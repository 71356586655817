import { validateSchoolStep1 } from './../../../../redux/actions/schools/validateSchoolStep1';
import { useDispatch } from 'react-redux';

import { SchoolCreateEditFormState } from '../types/SchoolCreateEditFormStep';
import { convertEnumToDropdownOption } from '@utils/enums';
import { SchoolArea } from '../../../../types/models/School';

const useCreateEditSchoolStep1 = () => {
    const dispatch = useDispatch();
    const areaOptions = convertEnumToDropdownOption(SchoolArea);

    const handleSubmit = (formState: SchoolCreateEditFormState) => {
        const {
            name,
            website,
            email,
            addressLine1,
            addressLine2,
            city,
            country,
            schoolArea,
            ukRegion,
        } = formState;

        const postBody = {
            name,
            website,
            email,
            addressLine1,
            addressLine2,
            city,
            country,
            schoolArea,
            ukRegion,
        };
        dispatch(validateSchoolStep1(postBody));
    };

    return {
        handleSubmit,
        areaOptions,
    };
};

export default useCreateEditSchoolStep1;
