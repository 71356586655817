import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectIsFetchingParticipantData,
    selectPageCount,
    selectPageSize,
    selectParticipantData,
    selectParticipantDataError,
} from '@selectors/participantData';

import { fetchParticipantData } from '@actions/participant/fetchParticipantData';
import useForm from '@hooks/useForm';
import useApiFilterTableRef from '@hooks/useApiFilterTableRef';
import usePrevious from '@hooks/usePrevious';

export interface ParticipantFilterFormState {
    activityID: number | null;
    activityGroupID: number | null;
    ccdbGroupID: number | null;
    name: string;
    phoneNumber: string;
    email: string;
    username: string;
    country: string;
}

const initialFormState: ParticipantFilterFormState = {
    activityID: null,
    activityGroupID: null,
    ccdbGroupID: null,
    name: '',
    phoneNumber: '',
    email: '',
    username: '',
    country: '',
};

const useFetchParticipantData = () => {
    const dispatch = useDispatch();

    const tableRef = useApiFilterTableRef();

    const isFetching = useSelector(selectIsFetchingParticipantData);
    const error = useSelector(selectParticipantDataError);

    const unfilteredParticipantData = useSelector(selectParticipantData);

    // Including this as each child in a list should have a unique "key" prop for api filter table
    const participantData = useMemo(
        () =>
            (unfilteredParticipantData ?? []).map((x: { id: any }, key: any) => ({
                ...x,
                id: key,
            })),
        [unfilteredParticipantData],
    );

    const pageSizeState = useSelector(selectPageSize);
    const pageCountState = useSelector(selectPageCount);

    const totalItems = calculateTotalItems(pageCountState, pageSizeState);

    const [form, handleChange, resetData] = useForm(initialFormState);
    const prevForm = usePrevious(form);

    const resetForm = () => {
        resetData(initialFormState);
    };

    useEffect(() => {
        if (JSON.stringify(form) !== JSON.stringify(prevForm)) {
            tableRef.current?.refreshData();
        }
    }, [form, prevForm, tableRef]);

    const handleFetchParticipantData = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            const params = { page, pageSize, searchTerm, ...form };

            dispatch(fetchParticipantData(params));
        },
        [dispatch, form],
    );

    return {
        isFetching,
        participantData,
        error,
        form,
        handleChange,
        resetForm,
        tableRef,
        totalItems,
        handleFetchParticipantData,
    };
};

function calculateTotalItems(pageCount: number | null, pageSize: number | null) {
    if (!pageCount || !pageSize) {
        return 0;
    }

    return pageCount * pageSize;
}

export default useFetchParticipantData;
