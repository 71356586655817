import Modal from '@components/modal/Modal';
import useCreateUser from './hooks/useCreateUser';
import Form from '@components/form/Form';
import FormRow from '@components/form/FormRow';
import TextInput from '@components/form/TextInput';
import Checkbox from '@components/form/Checkbox';
import Select from '@components/form/Select';
import { UserSchoolAssociation } from '../../../types/models/User';
import MultiComboSelect from '@components/form/MultiComboSelect';
import CountrySelect from '@components/form/CountrySelect';

const CreateUserModal: React.FC = () => {
    const {
        ccdbGroupOptions,
        closeModal,
        disabilityReportedOptions,
        error,
        ethnicityOptions,
        formState,
        handleChange,
        handleSubmit,
        institutionOptions,
        isPosting,
        onSearchTermChanged,
        schoolAssociationOptions,
        schoolOptions,
        schoolPositionOptions,
        userGenderOptions,
        institutionTypeOptions,
    } = useCreateUser();

    return (
        <Modal title="Create User">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal} error={error}>
                <FormRow>
                    <TextInput
                        type="Email"
                        name="email"
                        value={formState.email}
                        onChange={handleChange}
                        label="Email address"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="title"
                        value={formState.title}
                        onChange={handleChange}
                        label="Title"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="firstName"
                        value={formState.firstName}
                        onChange={handleChange}
                        label="First name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="lastName"
                        value={formState.lastName}
                        onChange={handleChange}
                        label="Last Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="phoneNumber"
                        value={formState.phoneNumber}
                        onChange={handleChange}
                        label="Phone Number"
                    />
                </FormRow>
                <FormRow>
                    <CountrySelect
                        required
                        name="country"
                        label="Country"
                        value={formState.country}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="gender"
                        value={formState.gender}
                        onChange={handleChange}
                        label="Gender"
                        options={userGenderOptions}
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="ethnicity"
                        value={formState.ethnicity}
                        onChange={handleChange}
                        label="Ethnicity"
                        options={ethnicityOptions}
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="disabilityReported"
                        value={formState.disabilityReported}
                        onChange={handleChange}
                        label="Disability Reported"
                        options={disabilityReportedOptions}
                    />
                </FormRow>
                <FormRow>
                    <MultiComboSelect
                        name="ccdbGroupNames"
                        value={formState.ccdbGroupNames}
                        onChange={handleChange}
                        label="CCDB Group"
                        options={ccdbGroupOptions}
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="schoolAssociation"
                        options={schoolAssociationOptions}
                        value={formState.schoolAssociation}
                        onChange={handleChange}
                        label="Works With a School?"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="institutionTypeID"
                        options={institutionTypeOptions}
                        value={formState.institutionTypeID}
                        onChange={handleChange}
                        label="Institution Type"
                        search
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="institutionID"
                        options={institutionOptions}
                        value={formState.institutionID}
                        onChange={handleChange}
                        label="Institution"
                        search
                    />
                </FormRow>
                {formState.schoolAssociation === UserSchoolAssociation.WorksAtSchool && (
                    <>
                        <FormRow>
                            <Select
                                search
                                required
                                name="schoolID"
                                value={formState.schoolID}
                                onChange={handleChange}
                                label="School"
                                options={schoolOptions}
                                onSearchChange={onSearchTermChanged}
                            />
                        </FormRow>

                        <FormRow>
                            <TextInput
                                required
                                name="schoolEmail"
                                value={formState.schoolEmail}
                                onChange={handleChange}
                                label="School Email"
                            />
                        </FormRow>
                    </>
                )}
                <FormRow>
                    <Select
                        name="schoolPositionDropdown"
                        value={formState.schoolPositionDropdown}
                        onChange={handleChange}
                        label="School Position"
                        options={schoolPositionOptions}
                    />
                </FormRow>
                {formState.schoolPositionDropdown === 'Other' && (
                    <FormRow>
                        <TextInput
                            name="schoolPosition"
                            value={formState.schoolPosition}
                            onChange={handleChange}
                            label=" "
                            placeholder="Enter school position..."
                        />
                    </FormRow>
                )}
                <FormRow>
                    <Checkbox
                        name="consentedToPersonalDataCollection"
                        value={formState.consentedToPersonalDataCollection}
                        onChange={handleChange}
                        label="Consented To Personal Data Collection?"
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="signedUpForSchoolsOnlineNews"
                        value={formState.signedUpForSchoolsOnlineNews}
                        onChange={handleChange}
                        label="Signed Up For School Online News?"
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="signedUpForBritishCouncilMail"
                        value={formState.signedUpForBritishCouncilMail}
                        onChange={handleChange}
                        label="Signed Up For British Coucil Mail?"
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="agreedTo3rdPartyResearchDataSharing"
                        value={formState.agreedTo3rdPartyResearchDataSharing}
                        onChange={handleChange}
                        label="Agreed To 3rd Party Research Data Sharing?"
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="agreedTo3rdPartySchoolSupportDataSharing"
                        value={formState.agreedTo3rdPartySchoolSupportDataSharing}
                        onChange={handleChange}
                        label="Agreed To 3rd Party School Support Data Sharing?"
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};
export default CreateUserModal;
