import useFetchCcdbGroups from '@pages/ccdbGroups/ccdbGroups/hooks/useFetchCcdbGroups';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from '@components/typography/Title';
import LinkButton from '@components/button/LinkButton';
import Table from '@components/table/Table';
import ButtonRow from '@components/button/ButtonRow';

import CreateCcdbGroupModal from '@pages/ccdbGroups/ccdbGroups/CreateCcdbGroupModal';

import { TableColumns } from '../../../types/shared/table';
import { CcdbGroup } from '../../../types/models/Ccdb';
import dayjs from 'dayjs';

interface Props {
    showCreateModal?: boolean;
}

const CcdbGroups = ({ showCreateModal = false }: Props) => {
    const { isFetching, fetchError, orderedCcdbGroups } = useFetchCcdbGroups();

    return (
        <>
            <CreateHeader>
                <Title>CCDB Groups</Title>
                <LinkButton href="/ccdb-groups/create">Create</LinkButton>
            </CreateHeader>

            <Table
                isLoading={isFetching}
                error={fetchError}
                columns={columns}
                rows={orderedCcdbGroups}
            />

            {showCreateModal && <CreateCcdbGroupModal />}
        </>
    );
};

const columns: TableColumns<CcdbGroup> = [
    {
        key: 0,
        heading: 'Name',
        getValue: row => row.name,
        searchable: true,
    },
    {
        key: 1,
        heading: 'Admin Owner',
        getValue: row => row.ownerUserName || '-',
    },
    {
        key: 2,
        heading: 'Created On',
        getValue: row => dayjs(row.createdOn).format('DD/MM/YYYY hh:mm'),
    },
    {
        key: 3,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/ccdb-groups/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

export default CcdbGroups;
