import { CountryCode } from 'countries-and-timezones';

export default interface School {
    ukRegion: string;
    id: number;
    name: string;
    website: string | null;
    email: string | null;
    country: CountryCode;
    addressLine1: string;
    addressLine2: string | null;
    city: string;
    postcode: string | null;
    schoolArea: SchoolArea | null;
    isDeleted: boolean;
    createdOn: string;
    schoolPhotographS3Key: string | null;
    headTeacher: string | null;
    schoolGroup: string;
    schoolTypes: SchoolType[];
    schoolGender: SchoolGender | null;
    provisionsForSpecialEducationalNeeds: SchoolSENProvisioning | null;
    schoolStatus: SchoolStatus | null;
    numberOfPupils: string | null;
    facebookID: string | null;
    twitterID: string | null;
    hasVideoConferencing: boolean | null;
    hasSMS: boolean | null;
    hasIT: boolean | null;
    hasEmail: boolean | null;
    internationalCoordinator: string | null;
    wholeSchoolPartnershipPreferences: WholeSchoolPartnershipPreferences | null;
    partnerSchoolInterestLocations: CountryCode[] | null;
    currentPartnerSchoolLocations: CountryCode[] | null;
    activitiesInterestedIn: number[];
    activitiesInvolvedIn: number[];
    partnersFoundViaSchoolsOnline: SchoolPartnersFoundViaSchoolsOnline | null;
    schoolsOnlineProvidedUsefulInformation: SchoolsOnlineProvidedUsefulInformation | null;
    partnerSchoolSuggested: PartnerSchoolSuggested | null;
    interestedInInternationalSchoolAward: InterestedInInternationalSchoolAward | null;
    source: SchoolSource | null;
    uniqueID: string | null;
}

export interface SchoolOption {
    name: string;
    id: number;
}

export enum SchoolArea {
    SemiUrban = 'SemiUrban',
    Urban = 'Urban',
    Rural = 'Rural',
}

export enum SchoolType {
    Nursery = 'Nursery',
    Primary = 'Primary',
    Secondary = 'Secondary',
    Combined = 'Combined',
    VocationalOrTechnicalCollege = 'VocationalOrTechnicalCollege',
    University = 'University',
}

export enum SchoolGender {
    Boys = 'Boys',
    Girls = 'Girls',
    Mixed = 'Mixed',
}

export enum SchoolSENProvisioning {
    AllPupils = 'AllPupils',
    SomePupils = 'SomePupils',
    NoPupils = 'NoPupils',
}

export enum SchoolStatus {
    NA = 'NA',
    GovernmentOrState = 'GovernmentOrState',
    SemiPrivate = 'SemiPrivate',
    PrivateOrIndependent = 'PrivateOrIndependent',
}

export enum WholeSchoolPartnershipPreferences {
    Possibly = 'Possibly',
    Yes = 'Yes',
    Partial = 'Partial',
    No = 'No',
}

export enum SchoolPartnersFoundViaSchoolsOnline {
    NA = 'NA',
    No = 'No',
    Yes = 'Yes',
}

export enum SchoolsOnlineProvidedUsefulInformation {
    NA = 'NA',
    No = 'No',
    Yes = 'Yes',
}

export enum PartnerSchoolSuggested {
    NA = 'NA',
    No = 'No',
    Yes = 'Yes',
}

export enum InterestedInInternationalSchoolAward {
    NA = 'NA',
    No = 'No',
    Yes = 'Yes',
}

export enum SchoolSource {
    Grants = 'Grants',
    DepartmentOfEducation = 'DepartmentOfEducation',
    ScottishGovernment = 'ScottishGovernment',
    Edubase = 'Edubase',
}
