import ConfirmModal from '@components/modal/ConfirmModal';
import useDeleteInstitutionType from './hooks/useDeleteInstitutionTypes';

const InstitutionTypeDeleteModal = () => {
    const { handleSubmit, closeModal, isPosting, error, institutionType } =
        useDeleteInstitutionType();

    if (!institutionType) return <></>;
    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete Institution Type"
            description="Are you sure you want to delete this institution type?"
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            error={error}
        />
    );
};

export default InstitutionTypeDeleteModal;
