import { ActivityGroup } from 'src/types/models/ActivityGroups';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '@hooks/useForm';
import usePrevious from '@hooks/usePrevious';

import {
    selectActivityGroupsIsPosting,
    selectActivityGroupsPostError,
    selectActivityGroupsPostSuccess,
} from '@selectors/activityGroups';
import { updateActivityGroup } from '@actions/activityGroups';
import useRedirect from '@hooks/useRedirect';

const useEditActivityGroup = (activityGroup: ActivityGroup) => {
    const dispatch = useDispatch();

    const isPosting = useSelector(selectActivityGroupsIsPosting);
    const postSuccess = useSelector(selectActivityGroupsPostSuccess);
    const postError = useSelector(selectActivityGroupsPostError);
    const prevPostSuccess = usePrevious(postSuccess);

    const initialForm = { name: activityGroup.name };

    const [form, handleChange] = useForm(initialForm);

    const closeModal = useRedirect(`/activity-groups/${activityGroup.id}`);

    const handleSubmit = () => {
        dispatch(updateActivityGroup(activityGroup.id, form));
    };

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleSubmit,
        closeModal,
        handleChange,
        isPosting,
        postError,
    };
};

export default useEditActivityGroup;
