import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../redux/store';
import {
    getInstitutionTypesIsFetching,
    getInstitutionTypesFetchError,
    getInstitutionType,
} from '@selectors/institutionTypes';
import { fetchInstitutionType } from '@actions/institutionTypes';

const useFetchInstitutionType = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const institutionType = useAppSelector(state => getInstitutionType(state, +id));
    const isFetching = useSelector(getInstitutionTypesIsFetching);
    const fetchError = useSelector(getInstitutionTypesFetchError);

    useEffect(() => {
        dispatch(fetchInstitutionType(+id));
    }, [dispatch, id]);

    return {
        institutionType,
        isFetching,
        fetchError,
    };
};

export default useFetchInstitutionType;
