import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import User, { UserSchoolAssociation } from 'src/types/models/User';
import { APIError } from 'src/types/shared/APIError';

export const createUserRequest = createAction('createUserRequest');
export const createUserSuccess = createAction<User>('createUserSuccess');
export const createUserFailure = createAction('createUserFailure');

export const createUser =
    (postbody: CreateUserRequestModel) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(createUserRequest());
        try {
            const { data }: { data: User } = await api.post(`users`, postbody);

            dispatch(createUserSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createUserFailure, e as APIError);
        }
    };

export interface CreateUserRequestModel {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    country: string;
    schoolAssociation: UserSchoolAssociation | null;
    schoolID: number | null;
    ccdbGroupNames: string[];
    schoolEmail: string;
    schoolPosition: string | null;
    consentedToPersonalDataCollection: boolean;
    signedUpForSchoolsOnlineNews: boolean;
    signedUpForBritishCouncilMail: boolean;
    agreedTo3rdPartyResearchDataSharing: boolean;
    agreedTo3rdPartySchoolSupportDataSharing: boolean;
}
