import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const fetchActivityRequest = createAction('fetchActivityRequest');
export const fetchActivitySuccess = createAction<Activity>('fetchActivitySuccess');
export const fetchActivityFailure = createAction('fetchActivityFailure');

export const fetchActivity = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchActivityRequest());

    try {
        const { data } = await api.get<Activity>(`activities/${id}`);

        dispatch(fetchActivitySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchActivityFailure, e as APIError);
    }
};
