import { RootState } from '@reducers/index';

export const getInstitutions = (state: RootState) =>
    Object.values(state.institutionsReducer.institutions);
export const getInstitution = (state: RootState, id: number) =>
    state.institutionsReducer.institutions[id];

export const getInstitutionsIsFetching = (state: RootState) => state.institutionsReducer.isFetching;
export const getInstitutionsFetchError = (state: RootState) => state.institutionsReducer.fetchError;
export const getInstitutionsPostError = (state: RootState) => state.institutionsReducer.postError;

export const getInstitutionsIsPosting = (state: RootState) => state.institutionsReducer.isPosting;
export const getInstitutionsPostSuccess = (state: RootState) =>
    state.institutionsReducer.postSuccess;
export const getInstitutionsItemCount = (state: RootState) => state.institutionsReducer.itemCount;
export const getInstitutionOptions = (state: RootState) => state.institutionsReducer.options;
