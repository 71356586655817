import { RootState } from '@reducers/index';

export const selectActivities = (state: RootState) =>
    Object.values(state.activitiesReducer.activities);
export const selectActivity = (state: RootState, id: number) =>
    state.activitiesReducer.activities[id];

export const selectActivitiesObj = (state: RootState) => state.activitiesReducer.activities;

export const selectUserActivities = (state: RootState) =>
    Object.values(state.activitiesReducer.userActivities);
export const selectUserActivity = (state: RootState, id: number) =>
    state.activitiesReducer.userActivities[id];

export const selectActivitiesIsFetching = (state: RootState) => state.activitiesReducer.isFetching;
export const selectActivitiesFetchError = (state: RootState) => state.activitiesReducer.fetchError;

export const selectActivitiesIsPosting = (state: RootState) => state.activitiesReducer.isPosting;
export const selectActivitiesPostSuccess = (state: RootState) =>
    state.activitiesReducer.postSuccess;
export const selectActivitiesPostError = (state: RootState) => state.activitiesReducer.postError;
