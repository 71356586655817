import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { CcdbGroup } from '../../../types/models/Ccdb';

export const updateCcdbGroupRequest = createAction('updateCcdbGroupRequest');
export const updateCcdbGroupSuccess = createAction<CcdbGroup>('updateCcdbGroupSuccess');
export const updateCcdbGroupFailure = createAction('updateCcdbGroupFailure');

export const updateCcdbGroup =
    (groupID: number, postBody: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(updateCcdbGroupRequest());

        try {
            const { data } = await api.put<PostBody, CcdbGroup>(`CCDBGroups/${groupID}`, postBody);

            dispatch(updateCcdbGroupSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, updateCcdbGroupFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
    ownerAdminUserID: number | null;
}
