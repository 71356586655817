import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { AdminUsersResponse } from 'src/types/models/AdminUser';
import { APITableParams } from 'src/types/shared/table';

export const fetchSearchedAdminUsersRequest = createAction('fetchSearchedAdminUsersRequest');
export const fetchSearchedAdminUsersSuccess = createAction<AdminUsersResponse>(
    'fetchSearchedAdminUsersSuccess',
);
export const fetchSearchedAdminUsersFailure = createAction('fetchSearchedAdminUsersFailure');

export const fetchSearchedAdminUsers =
    (params: APITableParams) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSearchedAdminUsersRequest());
        try {
            const { data } = await api.get<AdminUsersResponse>('adminUsers/filtered', params);

            dispatch(fetchSearchedAdminUsersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSearchedAdminUsersFailure, e as APIError);
        }
    };
