import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { ActivityGroup } from '../../../types/models/ActivityGroups';

export const fetchActivityGroupsRequest = createAction('fetchActivityGroupsRequest');
export const fetchActivityGroupsSuccess = createAction<ActivityGroup[]>(
    'fetchActivityGroupsSuccess',
);
export const fetchActivityGroupsFailure = createAction('fetchActivityGroupsFailure');

export const fetchActivityGroups = () => async (dispatch: AppDispatch) => {
    dispatch(fetchActivityGroupsRequest());

    try {
        const { data } = await api.get<ActivityGroup[]>('activitygroups');

        dispatch(fetchActivityGroupsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchActivityGroupsFailure, e as APIError);
    }
};
