import dayjs from 'dayjs';
import ApiFilterTable, {
    ApiFilterTableHandle,
    FetchDataFunc,
} from '@components/table/ApiFilterTable';
import { ParticipantData } from 'src/types/models/ParticipantData';
import { Column } from 'src/types/shared/table';
import { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { withKeys } from '@utils/generic';

const ParticipantDataTable = ({
    participantData,
    isFetching,
    error,
    tableRef,
    totalItems,
    handleFetch,
}: Props) => {
    return (
        <ApiFilterTable
            ref={tableRef}
            columns={columns}
            rows={participantData}
            isLoading={isFetching}
            error={error}
            totalItems={totalItems}
            fetchData={handleFetch}
        />
    );
};

interface Props {
    tableRef: RefObject<ApiFilterTableHandle>;
    participantData: ParticipantData[];
    isFetching: boolean;
    error: string | null;
    totalItems: number;
    handleFetch: FetchDataFunc;
}

const columns: Column<any>[] = withKeys<Omit<Column<ParticipantData>, 'key'>>([
    {
        heading: 'Activity Name',
        getValue: row => <Link to={`/activities/${row.activityID}`}>{row.activityName}</Link>,
        getSort: (a, b) => a.activityName.localeCompare(b.activityName),
    },
    {
        heading: 'Activity Group',
        getValue: row => (
            <Link to={`/activity-groups/${row.activityGroupID}`}>{row.activityGroupName}</Link>
        ),
        getSort: (a, b) => a.activityGroupName.localeCompare(b.activityGroupName),
    },
    {
        heading: 'Location',
        getValue: row => row.activityLocation,
        getSort: (a, b) => a.activityLocation.localeCompare(b.activityLocation),
    },
    {
        heading: 'Date of Activity',
        getValue: row => dayjs(row.activityDate).format('DD/MM/YYYY hh:mm'),
    },
    {
        heading: 'Username',
        getValue: row => <Link to={`/users/${row.userID}`}>{row.username}</Link>,
        getSort: (a, b) => a.username.localeCompare(b.username),
    },
    {
        heading: 'Full Name',
        getValue: row => row.fullName,
        getSort: (a, b) => a.fullName.localeCompare(b.fullName),
    },
    {
        heading: 'Email',
        getValue: row => row.email,
        getSort: (a, b) => a.email.localeCompare(b.email),
    },
    {
        heading: 'School',
        getValue: row => row.schoolName,
        getSort: (a, b) => a.schoolName.localeCompare(b.schoolName),
    },
    {
        heading: 'Date Submitted',
        getValue: row => dayjs(row.dateSubmitted).format('DD/MM/YYYY hh:mm'),
    },
]);

export default ParticipantDataTable;
