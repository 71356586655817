import ConfirmModal from '@components/modal/ConfirmModal';
import { Redirect } from 'react-router-dom';
import useDeleteCcdbGroup from '@pages/ccdbGroups/ccdbGroups/hooks/useDeleteCcdbGroup';

const DeleteCcdbGroupModal = () => {
    const { handleSubmit, closeModal, isPosting, error, ccdbGroup } = useDeleteCcdbGroup();

    if (!ccdbGroup) return <Redirect to="/ccdb-groups" />;

    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete school"
            description="Are you sure you want to delete this school group?"
            handleSubmit={handleSubmit}
            error={error}
            closeModal={closeModal}
        />
    );
};

export default DeleteCcdbGroupModal;
