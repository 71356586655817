import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePrevious from '@hooks/usePrevious';
import useForm from '@hooks/useForm';

import { updateAdminUserPassword } from '@actions/adminUsers';
import {
    getAdminUserIsPosting,
    getAdminUserPostSuccess,
    getAdminUsersError,
} from '@selectors/adminUsers';

export default function useUpdateAdminPassword(userID: number) {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/admin-users/${userID}`);
    }, [history, userID]);

    const [formState, handleChange] = useForm({ password: '', confirmPassword: '' });

    const handleSubmit = useCallback(() => {
        dispatch(updateAdminUserPassword(userID, formState));
    }, [dispatch, userID, formState]);

    const isPosting = useSelector(getAdminUserIsPosting);
    const error = useSelector(getAdminUsersError);
    const postSuccess = useSelector(getAdminUserPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const validateConfirmPassword = useCallback(() => {
        if (formState.password !== formState.confirmPassword) {
            return 'Passwords do not match.';
        }
    }, [formState.password, formState.confirmPassword]);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        closeModal,
        handleSubmit,
        formState,
        handleChange,
        validateConfirmPassword,
        isPosting,
        error,
    };
}
