import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import FormRow from '@components/form/FormRow';
import ClientSingleFileUpload from '@components/form/ClientSingleFileUpload';
import useImportUsers from './hooks/useImportUsers';
import { APITableRef } from '@hooks/useApiFilterTableRef';
import Description from '@components/typography/Description';

const ImportUsersModal: React.FC<ImportUsersModalProps> = ({ tableRef }) => {
    const {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
        handleCancel,
        showConfirmation,
        formError,
        showDownloadErrors,
    } = useImportUsers(tableRef);

    return (
        <Modal
            title={
                showDownloadErrors
                    ? 'Import completed with errors'
                    : showConfirmation
                    ? 'Confirm Import'
                    : 'Import Users'
            }
        >
            {showConfirmation && (
                <Description>
                    {showDownloadErrors
                        ? 'Import complete! Download the error summary below'
                        : formError
                        ? `There were some errors uploading CSV '${formState.File?.name}'`
                        : `Are you sure you want to upload the file '${formState.File?.name}'?`}
                </Description>
            )}

            <Form
                onSubmit={handleSubmit}
                isPosting={isPosting}
                onCancel={handleCancel}
                error={error}
                submitText={
                    showDownloadErrors ? 'Download' : formError ? 'Continue with errors' : 'Submit'
                }
            >
                <div style={{ display: showConfirmation ? 'none' : '' }}>
                    <FormRow>
                        <ClientSingleFileUpload
                            required
                            name="File"
                            accept=".csv"
                            value={formState.File}
                            onChange={handleChange}
                            position="left"
                            label="Upload CSV"
                        />
                    </FormRow>
                </div>
            </Form>
        </Modal>
    );
};

interface ImportUsersModalProps {
    tableRef: APITableRef;
}

export default ImportUsersModal;
