import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from '@actions/auth';

import NavGroup from './NavGroup';
import NavItem from './NavItem';
import { clearJwtAndRefreshToken } from '@utils/jwt';

const Nav: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    if (path.startsWith('/auth')) return null;

    return (
        <nav className="navigation custom-scroll">
            <ul className="nav-list">
                <NavGroup text="User management">
                    <NavItem to="/admin-users" text="Admins" icon="users-cog" />
                    <NavItem to="/users" text="Users" icon="users" />
                </NavGroup>

                <NavGroup text="Participant Data">
                    <NavItem to="/participant-data" text="Participant Data" icon="chart-bar" />
                </NavGroup>

                <NavGroup text="Schools">
                    <NavItem to="/school-groups" text="School Groups" icon="object-group" />
                    <NavItem to="/schools" text="Schools" icon="school" />
                </NavGroup>

                <NavGroup text="Institutions">
                    <NavItem to="/institution-types" text="Institution Types" icon="file" />
                    <NavItem to="/institutions" text="Institutions" icon="university" />
                </NavGroup>

                <NavGroup text="Activities">
                    <NavItem to="/activity-groups" text="Activity Groups" icon="object-group" />
                </NavGroup>

                <NavGroup text="CCDB Groups">
                    <NavItem to="/ccdb-groups" text="CCDB Groups" icon="object-group" />
                </NavGroup>

                <NavGroup text="Settings">
                    <NavItem
                        to="/auth/login"
                        text="Logout"
                        icon="sign-out"
                        onClick={() => {
                            clearJwtAndRefreshToken();
                            dispatch(logout());
                        }}
                    />
                </NavGroup>
            </ul>
        </nav>
    );
};

export default Nav;
