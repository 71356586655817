import Title from '@components/typography/Title';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import React from 'react';
import useFetchSchoolGroups from './hooks/useFetchSchoolGroups';
import Table from '@components/table/Table';
import { TableColumns } from 'src/types/shared/table';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import SchoolGroup from 'src/types/models/SchoolGroup';
import SchoolGroupEditModal from './SchoolGroupEditModal';
import SchoolGroupDeleteModal from './SchoolGroupDeleteModal';
import SchoolGroupCreateModal from './SchoolGroupCreateModal';

interface Props {
    showEditModal?: boolean;
    showDeleteModal?: boolean;
    showCreateModal?: boolean;
}

const SchoolGroups: React.FC<Props> = ({
    showEditModal = false,
    showDeleteModal = false,
    showCreateModal = false,
}) => {
    const { isFetching, fetchError, schoolGroups } = useFetchSchoolGroups();

    return (
        <>
            <CreateHeader>
                <Title>School Groups</Title>
                <LinkButton href="/school-groups/create">Create</LinkButton>
            </CreateHeader>
            <Table
                isLoading={isFetching}
                error={fetchError}
                rows={schoolGroups}
                columns={columns}
            />

            {showEditModal && <SchoolGroupEditModal />}
            {showDeleteModal && <SchoolGroupDeleteModal />}
            {showCreateModal && <SchoolGroupCreateModal />}
        </>
    );
};

const columns: TableColumns<SchoolGroup> = [
    {
        key: 0,
        heading: 'Name',
        getValue: row => row.name,
        searchable: true,
        getSort: (a, b) => a.name.localeCompare(b.name),
    },
    {
        key: 1,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton source="secondary" href={`/school-groups/${row.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton source="negative" href={`/school-groups/${row.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        ),
    },
];

export default SchoolGroups;
