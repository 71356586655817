import Form from '@components/form/Form';
import Modal from '@components/modal/Modal';
import React from 'react';
import useCreateEditInstitution from './hooks/useCreateEditInstitution';
import { InstitutionCreateEditFormState } from './types/InstitutionCreateEditFormStep';
import TextInput from '@components/form/TextInput';
import CountrySelect from '@components/form/CountrySelect';
import ComboSelect from '@components/form/ComboSelect';
import Select from '@components/form/Select';

interface Props {
    onSubmit: (formState: InstitutionCreateEditFormState) => void;
    initialFormState: InstitutionCreateEditFormState;
    title: string;
    redirectURL: string;
}

const InstitutionCreateEditModal: React.FC<Props> = ({
    onSubmit,
    initialFormState,
    title,
    redirectURL,
}) => {
    const {
        closeModal,
        isPosting,
        error,
        handleSubmit,
        formState,
        handleChange,
        institutionTypeOptions,
    } = useCreateEditInstitution({
        onSubmit,
        initialFormState,
        redirectURL,
    });

    return (
        <Modal title={title} size="small">
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting} error={error}>
                <TextInput
                    required
                    label="Name"
                    name="name"
                    value={formState.name}
                    onChange={handleChange}
                />
                <CountrySelect
                    name="country"
                    value={formState.country}
                    onChange={handleChange}
                    label="Country"
                    required
                />
                <TextInput
                    required
                    label="Address Line 1"
                    name="addressLine1"
                    value={formState.addressLine1}
                    onChange={handleChange}
                />
                <TextInput
                    label="Address Line 2"
                    name="addressLine2"
                    value={formState.addressLine2}
                    onChange={handleChange}
                />

                <TextInput
                    required
                    label="City"
                    name="city"
                    value={formState.city}
                    onChange={handleChange}
                />
                <Select
                    required
                    label="Institution Type"
                    name="institutionTypeID"
                    value={formState.institutionTypeID}
                    onChange={handleChange}
                    options={institutionTypeOptions}
                />
            </Form>
        </Modal>
    );
};

export default InstitutionCreateEditModal;
