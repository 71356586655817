import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSchoolGroup } from './../../../../redux/actions/schoolGroups/createSchoolGroup';

import usePrevious from '@hooks/usePrevious';
import {
    getSchoolGroupsIsPosting,
    getSchoolGroupsPostError,
    getSchoolGroupsPostSuccess,
} from '@selectors/schoolGroups';
import { useCallback, useEffect } from 'react';
import useForm from '@hooks/useForm';

const useCreateSchoolGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isPosting = useSelector(getSchoolGroupsIsPosting);
    const error = useSelector(getSchoolGroupsPostError);
    const success = useSelector(getSchoolGroupsPostSuccess);

    const [formState, handleChange] = useForm({ name: '' });

    const closeModal = useCallback(() => {
        history.replace(`/school-groups`);
    }, [history]);

    const handleSubmit = () => {
        dispatch(createSchoolGroup(formState));
    };

    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (!prevSuccess && success) {
            closeModal();
        }
    }, [prevSuccess, success, closeModal]);

    return { closeModal, handleSubmit, isPosting, error, formState, handleChange };
};

export default useCreateSchoolGroup;
