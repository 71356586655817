import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const createActivityRequest = createAction('createActivityRequest');
export const createActivitySuccess = createAction<Activity>('createActivitySuccess');
export const createActivityFailure = createAction('createActivityFailure');

export const createActivity = (postBody: PostBody) => async (dispatch: AppDispatch) => {
    dispatch(createActivityRequest());

    try {
        const { data } = await api.post<PostBody, Activity>('activities', postBody);

        dispatch(createActivitySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createActivityFailure, e as APIError);
    }
};

interface PostBody {
    name: string;
    activityGroupID: number;
    activityCode: string | null;
}
