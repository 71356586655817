import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';

export const deleteActivityRequest = createAction('deleteActivityRequest');
export const deleteActivitySuccess = createAction<number>('deleteActivitySuccess');
export const deleteActivityFailure = createAction('deleteActivityFailure');

export const deleteActivity = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(deleteActivityRequest());

    try {
        await api.delete(`activities/${id}`);

        dispatch(deleteActivitySuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deleteActivityFailure, e as APIError);
    }
};
