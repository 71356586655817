const SubTitle = ({ tag, children, className = '', alignment = '', ...props }: Props) => {
    const Tag = tag;

    return (
        <Tag className={`sub-title ${className} ${alignment}`} {...props}>
            {children}
        </Tag>
    );
};

interface Props {
    children: React.ReactNode;
    tag: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    className?: string;
    alignment?: string;
}
export default SubTitle;
