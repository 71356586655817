import InstitutionType from '../../../types/models/InstitutionType';
import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchInstitutionTypeRequest = createAction('fetchInstitutionTypeRequest');
export const fetchInstitutionTypeSuccess = createAction<InstitutionType>(
    'fetchInstitutionTypeSuccess',
);
export const fetchInstitutionTypeFailure = createAction('fetchInstitutionTypeFailure');

export const fetchInstitutionType =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchInstitutionTypeRequest());
        try {
            const { data } = await api.get<InstitutionType>(`institutionTypes/${id}`);
            dispatch(fetchInstitutionTypeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchInstitutionTypeFailure, e as APIError);
        }
    };
