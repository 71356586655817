import { Activity } from 'src/types/models/Activities';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '@hooks/useForm';
import usePrevious from '@hooks/usePrevious';

import {
    selectActivitiesIsPosting,
    selectActivitiesPostError,
    selectActivitiesPostSuccess,
} from '@selectors/activities';
import { updateActivity } from '@actions/activities';
import useRedirect from '@hooks/useRedirect';

const useEditActivity = (activity: Activity) => {
    const dispatch = useDispatch();

    const isPosting = useSelector(selectActivitiesIsPosting);
    const postSuccess = useSelector(selectActivitiesPostSuccess);
    const postError = useSelector(selectActivitiesPostError);
    const prevPostSuccess = usePrevious(postSuccess);

    const initialForm = { name: activity.name, activityCode: activity.activityCode || '' };

    const [form, handleChange] = useForm(initialForm);

    const closeModal = useRedirect(`/activities/${activity.id}`);

    const handleSubmit = () => {
        dispatch(updateActivity(activity.id, { ...form, activityCode: form.activityCode || null }));
    };

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleSubmit,
        closeModal,
        handleChange,
        isPosting,
        postError,
    };
};

export default useEditActivity;
