import useCreateActivity from '@pages/activityGroups/activityGroup/hooks/useCreateActivity';

import Modal from '@components/modal/Modal';
import Form from '@components/form/Form';
import FormField from '@components/form/FormField';
import TextInput from '@components/form/TextInput';

const CreateActivityModal = () => {
    const { form, handleChange, handleSubmit, isPosting, postError, closeModal } =
        useCreateActivity();

    return (
        <Modal title="Create Activity">
            <Form
                onSubmit={handleSubmit}
                onCancel={closeModal}
                isPosting={isPosting}
                error={postError}
            >
                <FormField name="name" label="Name">
                    <TextInput name={'name'} value={form.name} onChange={handleChange} />
                </FormField>
                <FormField name="activityCode" label="Activity Code">
                    <TextInput
                        name={'activityCode'}
                        value={form.activityCode}
                        onChange={handleChange}
                    />
                </FormField>
            </Form>
        </Modal>
    );
};

export default CreateActivityModal;
