import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from '@components/typography/Title';

import ParticipantDataFilters from './ParticipantDataFilters';
import ParticipantDataTable from './ParticipantDataTable';
import useFetchParticipantData from './_hooks/useFetchParticipantData';

const ParticipantData = () => {
    const {
        isFetching,
        participantData,
        error,
        form,
        handleChange,
        resetForm,
        tableRef,
        totalItems,
        handleFetchParticipantData,
    } = useFetchParticipantData();

    return (
        <>
            <CreateHeader>
                <Title>Participant Data</Title>
            </CreateHeader>

            <ParticipantDataFilters
                filters={form}
                handleChange={handleChange}
                resetForm={resetForm}
            />

            <ParticipantDataTable
                tableRef={tableRef}
                participantData={participantData}
                isFetching={isFetching}
                error={error}
                totalItems={totalItems}
                handleFetch={handleFetchParticipantData}
            />
        </>
    );
};

export default ParticipantData;
