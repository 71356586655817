import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { SchoolCreateEditFormState } from '../types/SchoolCreateEditFormStep';
import { validateSchoolStep3 } from '@actions/schools';
import {
    InterestedInInternationalSchoolAward,
    PartnerSchoolSuggested,
    SchoolPartnersFoundViaSchoolsOnline,
    SchoolsOnlineProvidedUsefulInformation,
    WholeSchoolPartnershipPreferences,
} from './../../../../types/models/School';
import { convertEnumToDropdownOption } from '@utils/enums';
import { selectActivitiesObj } from '@selectors/activities';
import { Activity } from 'src/types/models/Activities';
import { fetchActivities } from '@actions/activities';
import { fetchActivityGroups } from '@actions/activityGroups';
import { selectActivityGroupsObj } from '@selectors/activityGroups';

const wholeSchoolPartnershipPreferenceOptions = convertEnumToDropdownOption(
    WholeSchoolPartnershipPreferences,
);
const partnersFoundViaSchoolsOnlineOptions = convertEnumToDropdownOption(
    SchoolPartnersFoundViaSchoolsOnline,
);
const onlineProvidedUsefulInformationOptions = convertEnumToDropdownOption(
    SchoolsOnlineProvidedUsefulInformation,
);
const partnerSchoolSuggestedOptions = convertEnumToDropdownOption(PartnerSchoolSuggested);
const interestedInInternationalSchoolAwardOptions = convertEnumToDropdownOption(
    InterestedInInternationalSchoolAward,
);

const useCreateEditSchoolStep3 = () => {
    const dispatch = useDispatch();

    const activitiesObj = useSelector(selectActivitiesObj);
    const activityGroupsObj = useSelector(selectActivityGroupsObj);
    const activities: Activity[] = useMemo(() => Object.values(activitiesObj), [activitiesObj]);

    const activitiesOptions = activities
        .map(a => ({
            label: `${activityGroupsObj[a.activityGroupID]?.name} > ${a.name}`,
            value: a.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const handleSubmit = (formState: SchoolCreateEditFormState) => {
        const {
            wholeSchoolPartnershipPreferences,
            partnerSchoolInterestLocations,
            currentPartnerSchoolLocations,
            activitiesInterestedIn,
            activitiesInvolvedIn,
            partnersFoundViaSchoolsOnline,
            schoolsOnlineProvidedUsefulInformation,
            partnerSchoolSuggested,
            interestedInInternationalSchoolAward,
        } = formState;

        const postBody = {
            wholeSchoolPartnershipPreferences,
            partnerSchoolInterestLocations,
            currentPartnerSchoolLocations,
            activitiesInterestedIn,
            activitiesInvolvedIn,
            partnersFoundViaSchoolsOnline,
            schoolsOnlineProvidedUsefulInformation,
            partnerSchoolSuggested,
            interestedInInternationalSchoolAward,
        };
        dispatch(validateSchoolStep3(postBody));
    };

    useEffect(() => {
        dispatch(fetchActivityGroups());
        dispatch(fetchActivities());
    }, [dispatch]);

    return {
        handleSubmit,
        wholeSchoolPartnershipPreferenceOptions,
        partnersFoundViaSchoolsOnlineOptions,
        onlineProvidedUsefulInformationOptions,
        partnerSchoolSuggestedOptions,
        interestedInInternationalSchoolAwardOptions,
        activities,
        activitiesOptions,
        activityGroups: activityGroupsObj,
    };
};

export default useCreateEditSchoolStep3;
