import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { ActivityGroup } from '../../../types/models/ActivityGroups';

export const fetchSingleActivityGroupRequest = createAction('fetchSingleActivityGroupRequest');
export const fetchSingleActivityGroupSuccess = createAction<ActivityGroup>(
    'fetchSingleActivityGroupSuccess',
);
export const fetchSingleActivityGroupFailure = createAction('fetchSingleActivityGroupFailure');

export const fetchSingleActivityGroup = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchSingleActivityGroupRequest());

    try {
        const { data } = await api.get<ActivityGroup>(`activitygroups/${id}`);

        dispatch(fetchSingleActivityGroupSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleActivityGroupFailure, e as APIError);
    }
};
