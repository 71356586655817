import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import School, {
    InterestedInInternationalSchoolAward,
    PartnerSchoolSuggested,
    SchoolPartnersFoundViaSchoolsOnline,
    SchoolsOnlineProvidedUsefulInformation,
    WholeSchoolPartnershipPreferences,
} from '../../../types/models/School';

export const validateSchoolStep3Request = createAction('validateSchoolStep3Request');
export const validateSchoolStep3Success = createAction<School>('validateSchoolStep3Success');
export const validateSchoolStep3Failure = createAction('validateSchoolStep3Failure');

export const validateSchoolStep3 =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(validateSchoolStep3Request());
        try {
            const { data } = await api.post<PostBody, School>(
                `schools/Validate/PartnershipPreferences`,
                postbody,
            );

            dispatch(validateSchoolStep3Success(data));
        } catch (e) {
            handleApiErrors(dispatch, validateSchoolStep3Failure, e as APIError);
        }
    };

interface PostBody {
    wholeSchoolPartnershipPreferences: WholeSchoolPartnershipPreferences | null;
    partnerSchoolInterestLocations: string[];
    currentPartnerSchoolLocations: string[];
    activitiesInterestedIn: number[];
    activitiesInvolvedIn: number[];
    partnersFoundViaSchoolsOnline: SchoolPartnersFoundViaSchoolsOnline | null;
    schoolsOnlineProvidedUsefulInformation: SchoolsOnlineProvidedUsefulInformation | null;
    partnerSchoolSuggested: PartnerSchoolSuggested | null;
    interestedInInternationalSchoolAward: InterestedInInternationalSchoolAward | null;
}
