import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useForm from '@hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';

import { getSearchedUsers } from '@selectors/searchedUsers';
import { fetchSearchedUsers } from '@actions/users/fetchSearchedUsers';

import {
    selectCcdbGroupsIsPosting,
    selectCcdbGroupsPostError,
    selectCcdbGroupsPostSuccess,
} from '@selectors/ccdbGroups';

import { addUsersToCcdbGroup } from '@actions/ccdb';

import { DropdownOption } from '../../../../../types/shared/DropdownOption';

const useAddUsersToCcdbGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const groupID = +id;

    const [searchTerm, setSearchTerm] = useState('');
    const prevSearchTerm = usePrevious(searchTerm);
    const searchTimeout = useRef<number | null>(null);

    const [form, handleChange] = useForm<{ userIDs: number[] }>({ userIDs: [] });

    const isPosting = useSelector(selectCcdbGroupsIsPosting);
    const postSuccess = useSelector(selectCcdbGroupsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);
    const error = useSelector(selectCcdbGroupsPostError);

    const onSearchTermChanged = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        if (searchTerm !== prevSearchTerm) {
            window.clearTimeout(searchTimeout.current || 0);
            searchTimeout.current = window.setTimeout(() => {
                dispatch(
                    fetchSearchedUsers({ page: null, limit: null, searchTerm: newSearchTerm }),
                );
            }, 1000);
        }
    };

    useEffect(() => {
        dispatch(fetchSearchedUsers({ page: null, limit: null, searchTerm: '' }));
    }, [dispatch]);

    const users = useSelector(getSearchedUsers);
    const userOptions = useMemo(() => {
        return Object.values(users).reduce((acc: DropdownOption<number>[], user) => {
            if (!user.ccdbGroupIds.includes(groupID)) {
                acc.push({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                });
            }

            return acc;
        }, []);
    }, [users, groupID]);

    const handleSubmit = () => {
        dispatch(addUsersToCcdbGroup(+id, form));
    };

    const closeModal = useCallback(() => {
        history.push(`/ccdb-groups/${id}`);
    }, [history, id]);

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [dispatch, history, id, postSuccess, prevPostSuccess, closeModal]);

    return {
        form,
        handleChange,
        userOptions,
        handleSubmit,
        isPosting,
        closeModal,
        error,
        onSearchTermChanged,
    };
};

export default useAddUsersToCcdbGroup;
