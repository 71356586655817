import React from 'react';
import Institution from 'src/types/models/Institution';
import InstitutionCreateEditModal from '../shared/InstitutionCreateEditModal';
import useEditInstitution from './hooks/useEditInstitution';

interface Props {
    institution: Institution;
}

const InstitutionEditModal: React.FC<Props> = ({ institution }) => {
    const { initialFormState, handleSubmit } = useEditInstitution(institution);

    return (
        <InstitutionCreateEditModal
            onSubmit={handleSubmit}
            initialFormState={initialFormState}
            title="Edit Institution"
            redirectURL={`/institutions/${institution.id}`}
        />
    );
};

export default InstitutionEditModal;
