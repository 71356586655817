import { undoActivityHistory } from './../../../../../redux/actions/activityHistories/undoActivityHistory';
import useRedirect from '@hooks/useRedirect';
import {
    selectActivityHistoriesIsPosting,
    selectActivityHistoriesPostError,
    selectActivityHistoriesPostSuccess,
} from '@selectors/activityHistories';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';

import { useEffect } from 'react';

const useUndoActivityHistory = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id, historyID } = useParams<{ id: string; historyID: string }>();
    const ccdbGroupID = +id;

    const isPosting = useSelector(selectActivityHistoriesIsPosting);
    const error = useSelector(selectActivityHistoriesPostError);
    const success = useSelector(selectActivityHistoriesPostSuccess);
    const prevSuccess = usePrevious(success);

    const handleSubmit = () => {
        dispatch(undoActivityHistory(+historyID));
    };

    const closeModal = useRedirect(`/ccdb-groups/${ccdbGroupID}`);

    useEffect(() => {
        if (success && !prevSuccess) {
            closeModal();
        }
    }, [dispatch, history, id, success, prevSuccess, closeModal]);

    return {
        handleSubmit,
        isPosting,
        closeModal,
        error,
    };
};

export default useUndoActivityHistory;
