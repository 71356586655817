import { RootState } from '@reducers/index';

export const getAdminUsers = (state: RootState) => state.adminUsersReducer.adminUsers;
export const getAdminUser = (state: RootState, id: number) =>
    state.adminUsersReducer.adminUsers[id];

export const getAdminUsersIsFetching = (state: RootState) => state.adminUsersReducer.isFetching;
export const getAdminUsersError = (state: RootState) => state.adminUsersReducer.fetchError;
export const getAdminUsersPostError = (state: RootState) => state.adminUsersReducer.postError;

export const getAdminUserIsPosting = (state: RootState) => state.adminUsersReducer.isPosting;
export const getAdminUserPostSuccess = (state: RootState) => state.adminUsersReducer.postSuccess;
