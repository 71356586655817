import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';
import {
    deleteSchoolGroupFailure,
    deleteSchoolGroupRequest,
    deleteSchoolGroupSuccess,
    fetchSchoolGroupsFailure,
    fetchSchoolGroupsRequest,
    fetchSchoolGroupsSuccess,
    createSchoolGroupFailure,
    createSchoolGroupRequest,
    createSchoolGroupSuccess,
    updateSchoolGroupFailure,
    updateSchoolGroupRequest,
    updateSchoolGroupSuccess,
} from '@actions/schoolGroups';
import SchoolGroup from 'src/types/models/SchoolGroup';

interface SchoolGroupsState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    fetchError: string | null;
    postError: string | null;
    schoolGroups: Record<number, SchoolGroup>;
}

const initialState: SchoolGroupsState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    fetchError: null,
    postError: null,
    schoolGroups: {},
};

export default createReducer(initialState, {
    [fetchSchoolGroupsRequest.type]: handleFetchRequest,
    [fetchSchoolGroupsSuccess.type]: handleFetchAllSuccess,
    [fetchSchoolGroupsFailure.type]: handleFetchError,
    [createSchoolGroupRequest.type]: handlePostRequest,
    [createSchoolGroupSuccess.type]: handlePostSuccess,
    [createSchoolGroupFailure.type]: handlePostError,
    [updateSchoolGroupRequest.type]: handlePostRequest,
    [updateSchoolGroupSuccess.type]: handlePostSuccess,
    [updateSchoolGroupFailure.type]: handlePostError,
    [deleteSchoolGroupRequest.type]: handlePostRequest,
    [deleteSchoolGroupFailure.type]: handlePostError,
    [deleteSchoolGroupSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: SchoolGroupsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: SchoolGroupsState, action: PayloadAction<string>) {
    state.fetchError = action.payload;
    state.isFetching = false;
}

function handleFetchAllSuccess(state: SchoolGroupsState, action: PayloadAction<SchoolGroup[]>) {
    state.schoolGroups = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handlePostRequest(state: SchoolGroupsState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostError(state: SchoolGroupsState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handlePostSuccess(state: SchoolGroupsState, action: PayloadAction<SchoolGroup>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.schoolGroups[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: SchoolGroupsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.schoolGroups[action.payload];
}
