import DataCheck from '@components/common/DataCheck';
import InstitutionType from './InstitutionType';
import useFetchInstitutionType from './hooks/useFetchInstitutionTypes';
import InstitutionTypeEditModal from './InstitutionTypeEditModal';
import InstitutionTypeDeleteModal from './InstitutionTypeDeleteModal';

interface Props {
    showEditModal?: boolean;
    showDeleteModal?: boolean;
}

const InstitutionTypeContainer: React.FC<Props> = ({
    showEditModal = false,
    showDeleteModal = false,
}) => {
    const { institutionType, isFetching, fetchError } = useFetchInstitutionType();

    return (
        <>
            <DataCheck dataExists={!!institutionType} isFetching={isFetching} error={fetchError}>
                <InstitutionType institutionType={institutionType} />
            </DataCheck>
            {!!institutionType && showEditModal && (
                <InstitutionTypeEditModal institutionType={institutionType} />
            )}
            {showDeleteModal && <InstitutionTypeDeleteModal />}
        </>
    );
};

export default InstitutionTypeContainer;
