import React from 'react';
import InstitutionTypeCreateEditModal from '../shared/InstitutionTypeCreateEditModal';
import useCreateInstitutionTypes from './hooks/useCreateInstitutionType';

const InstitutionTypeCreateModal: React.FC = () => {
    const { handleSubmit, initialFormState } = useCreateInstitutionTypes();

    return (
        <InstitutionTypeCreateEditModal
            onSubmit={handleSubmit}
            initialFormState={initialFormState}
            title="Create Institution Types"
            redirectURL="/institution-types"
        />
    );
};

export default InstitutionTypeCreateModal;
