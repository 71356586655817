import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';
import { api, handleApiErrors } from '@utils/api';

import { APIError } from '../../../types/shared/APIError';
import { UserActivities } from '../../../types/models/Activities';

export const fetchUserActivitiesRequest = createAction('fetchUserActivitiesRequest');
export const fetchUserActivitiesSuccess = createAction<UserActivities[]>(
    'fetchUserActivitiesSuccess',
);
export const fetchUserActivitiesFailure = createAction('fetchUserActivitiesFailure');

export const fetchUserActivities = (userID: number) => async (dispatch: AppDispatch) => {
    dispatch(fetchUserActivitiesRequest());

    try {
        const { data } = await api.get<UserActivities[]>(`UserActivities/User/${userID}`);

        dispatch(fetchUserActivitiesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchUserActivitiesFailure, e as APIError);
    }
};
