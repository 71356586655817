import { forwardRef, useImperativeHandle } from 'react';

import Select from '@components/form/Select';
import TextInput from '@components/form/TextInput';
import {
    SchoolCreateEditFormState,
    SchoolCreateEditFormStepProps,
    SchoolCreateEditFormStepRefHandle,
} from './types/SchoolCreateEditFormStep';
import useCreateEditSchoolStep1 from './hooks/useCreateEditSchoolStep1';
import useFetchRegions from '@hooks/useFetchRegions';
import CountrySelect from '@components/form/CountrySelect';

const SchoolCreateEditStep1 = forwardRef<
    SchoolCreateEditFormStepRefHandle,
    SchoolCreateEditFormStepProps
>(({ formState, handleChange }, ref) => {
    const { areaOptions, handleSubmit } = useCreateEditSchoolStep1();

    useImperativeHandle(
        ref,
        (): SchoolCreateEditFormStepRefHandle => ({
            onSubmit(formState: SchoolCreateEditFormState) {
                handleSubmit(formState);
            },
        }),
    );

    const { regionOptions } = useFetchRegions();

    return (
        <>
            <TextInput
                required
                label="Name"
                name="name"
                value={formState.name}
                onChange={handleChange}
            />
            <TextInput
                label="Website"
                name="website"
                value={formState.website}
                onChange={handleChange}
            />
            <TextInput
                label="Email"
                type="email"
                name="email"
                value={formState.email}
                onChange={handleChange}
            />

            <CountrySelect
                name="country"
                value={formState.country}
                onChange={handleChange}
                label="Country"
                required
            />

            <TextInput
                required
                label="Address Line 1"
                name="addressLine1"
                value={formState.addressLine1}
                onChange={handleChange}
            />
            <TextInput
                label="Address Line 2"
                name="addressLine2"
                value={formState.addressLine2}
                onChange={handleChange}
            />

            <TextInput
                required
                label="City"
                name="city"
                value={formState.city}
                onChange={handleChange}
            />

            <TextInput
                required
                label="Postcode"
                name="postcode"
                value={formState.postcode}
                onChange={handleChange}
            />
            <Select
                label="UK Region"
                name="ukRegion"
                value={formState.ukRegion}
                options={regionOptions}
                onChange={handleChange}
            />
            <Select
                label="School Area"
                name="schoolArea"
                value={formState.schoolArea}
                options={areaOptions}
                onChange={handleChange}
            />
        </>
    );
});

export default SchoolCreateEditStep1;
