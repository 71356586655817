import { useEffect } from 'react';
import usePrevious from '@hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import useForm from '@hooks/useForm';

import { createActivityGroup } from '@actions/activityGroups';

import {
    selectActivityGroupsIsPosting,
    selectActivityGroupsPostError,
    selectActivityGroupsPostSuccess,
} from '@selectors/activityGroups';
import useRedirect from '@hooks/useRedirect';

const useCreateActivityGroup = () => {
    const dispatch = useDispatch();

    const isPosting = useSelector(selectActivityGroupsIsPosting);
    const postError = useSelector(selectActivityGroupsPostError);
    const postSuccess = useSelector(selectActivityGroupsPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    const [form, handleChange] = useForm({
        name: '',
    });

    const handleSubmit = () => {
        dispatch(createActivityGroup(form));
    };

    const closeModal = useRedirect('/activity-groups');

    useEffect(() => {
        if (postSuccess && !prevPostSuccess) {
            closeModal();
        }
    }, [prevPostSuccess, postSuccess, closeModal]);

    return { form, handleChange, handleSubmit, isPosting, postError, closeModal };
};

export default useCreateActivityGroup;
