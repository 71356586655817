import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAllUsers } from '@actions/users/fetchAllUsers';
import { getUsers, getUsersError, getUsersIsFetching, getUsersItemCount } from '@selectors/users';

import useApiFilterTableRef from '@hooks/useApiFilterTableRef';

const useUsersFetch = () => {
    const dispatch = useDispatch();
    const users = useSelector(getUsers);
    const isFetching = useSelector(getUsersIsFetching);
    const error = useSelector(getUsersError);
    const itemCount = useSelector(getUsersItemCount);

    const tableRef = useApiFilterTableRef();

    const handleFetch = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            const params = { page, limit: pageSize, searchTerm };
            dispatch(fetchAllUsers(params));
        },
        [dispatch],
    );

    return { tableRef, users, itemCount, handleFetch, isFetching, error };
};

export default useUsersFetch;
