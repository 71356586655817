import useFetchActivity from './hooks/useFetchActivity';

import EditActivityModal from './EditActivityModal';
import DeleteActivityModal from './DeleteActivityModal';
import DataCheck from '@components/common/DataCheck';
import Activity from './Activity';

interface Props {
    showDeleteModal?: boolean;
    showEditModal?: boolean;
}

const ActivityContainer = ({ showDeleteModal, showEditModal }: Props) => {
    const { activity, activityGroup, isFetching, error } = useFetchActivity();

    return (
        <>
            <DataCheck
                isFetching={isFetching}
                error={error}
                dataExists={!!(activity && activityGroup)}
            >
                <Activity activity={activity} activityGroup={activityGroup} />
            </DataCheck>

            {activity && showEditModal && <EditActivityModal activity={activity} />}
            {activityGroup && showDeleteModal && (
                <DeleteActivityModal activityGroup={activityGroup} />
            )}
        </>
    );
};

export default ActivityContainer;
