import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';

export const removeUsersFromCcdbGroupRequest = createAction('removeUsersFromCcdbGroupRequest');
export const removeUsersFromCcdbGroupSuccess = createAction('removeUsersFromCcdbGroupSuccess');
export const removeUsersFromCcdbGroupFailure = createAction('removeUsersFromCcdbGroupFailure');

export const removeUsersFromCcdbGroup =
    (groupID: number, data: PostBody) => async (dispatch: AppDispatch) => {
        dispatch(removeUsersFromCcdbGroupRequest());

        try {
            await api.post(`CCDBGroups/${groupID}/RemoveUsers`, data);
            dispatch(removeUsersFromCcdbGroupSuccess());
        } catch (e) {
            handleApiErrors(dispatch, removeUsersFromCcdbGroupFailure, e as APIError);
        }
    };

interface PostBody {
    userIDs: number[];
}
