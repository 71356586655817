import Institution from '../../../types/models/Institution';
import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchInstitutionRequest = createAction('fetchInstitutionRequest');
export const fetchInstitutionSuccess = createAction<Institution>('fetchInstitutionSuccess');
export const fetchInstitutionFailure = createAction('fetchInstitutionFailure');

export const fetchInstitution =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchInstitutionRequest());
        try {
            const { data } = await api.get<Institution>(`institutions/${id}`);
            dispatch(fetchInstitutionSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchInstitutionFailure, e as APIError);
        }
    };
