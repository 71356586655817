import { InstitutionOption } from '../../types/models/Institution';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';
import {
    deleteInstitutionFailure,
    deleteInstitutionRequest,
    deleteInstitutionSuccess,
    fetchInstitutionsFailure,
    fetchInstitutionsRequest,
    fetchInstitutionsSuccess,
    fetchInstitutionFailure,
    fetchInstitutionRequest,
    fetchInstitutionSuccess,
    createInstitutionRequest,
    createInstitutionSuccess,
    createInstitutionFailure,
    updateInstitutionFailure,
    updateInstitutionRequest,
    updateInstitutionSuccess,
    FetchInstitutionsResponse,
} from '@actions/institutions';
import Institution from 'src/types/models/Institution';

interface InstitutionsState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    fetchError: string | null;
    postError: string | null;
    institutions: Record<number, Institution>;
    itemCount: number;
    options: InstitutionOption[];
}

const initialState: InstitutionsState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    fetchError: null,
    postError: null,
    institutions: {},
    itemCount: 0,
    options: [],
};

export default createReducer(initialState, {
    [fetchInstitutionsRequest.type]: handleFetchRequest,
    [fetchInstitutionsSuccess.type]: handleFetchAllSuccess,
    [fetchInstitutionsFailure.type]: handleFetchError,
    [fetchInstitutionRequest.type]: handleFetchRequest,
    [fetchInstitutionSuccess.type]: handleFetchSingleSuccess,
    [fetchInstitutionFailure.type]: handleFetchError,
    [createInstitutionRequest.type]: handlePostRequest,
    [createInstitutionSuccess.type]: handlePostSuccess,
    [createInstitutionFailure.type]: handlePostError,
    [updateInstitutionRequest.type]: handlePostRequest,
    [updateInstitutionSuccess.type]: handlePostSuccess,
    [updateInstitutionFailure.type]: handlePostError,
    [deleteInstitutionRequest.type]: handlePostRequest,
    [deleteInstitutionFailure.type]: handlePostError,
    [deleteInstitutionSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: InstitutionsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: InstitutionsState, action: PayloadAction<string>) {
    state.fetchError = action.payload;
    state.isFetching = false;
}

function handleFetchOptionsSuccess(
    state: InstitutionsState,
    action: PayloadAction<InstitutionOption[]>,
) {
    state.options = action.payload;
}

function handleFetchAllSuccess(
    state: InstitutionsState,
    action: PayloadAction<FetchInstitutionsResponse>,
) {
    state.institutions = convertArrToObj(action.payload.items);
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handlePostRequest(state: InstitutionsState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostError(state: InstitutionsState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handlePostSuccess(state: InstitutionsState, action: PayloadAction<Institution>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.institutions[action.payload.id] = action.payload;
}

function handlePostSuccessNoUpdate(state: InstitutionsState, action: PayloadAction) {
    state.isPosting = false;
    state.postSuccess = true;
}
function handleValidateSuccess(state: InstitutionsState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFetchSingleSuccess(state: InstitutionsState, action: PayloadAction<Institution>) {
    state.institutions[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleDeleteSuccess(state: InstitutionsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.institutions[action.payload];
}
