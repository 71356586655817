import { useCallback, useEffect, useRef, useState } from 'react';
import FormField from './FormField';

import { FormInputProps } from 'src/types/shared/FormInputProps';
import useFieldValidation from '@hooks/useFieldValidation';
import { bytesToMB, checkIsFilenameImage } from '@utils/generic';
import config from '@config/index';
import usePrevious from '@hooks/usePrevious';
const { S3_URL } = config;

interface Props extends FormInputProps<File | null> {
    maxSize?: number;
    position?: 'left' | 'right' | 'center';
    label?: string;
    accept?: string;
}

const ClientSingleFileUpload: React.FC<Props> = ({
    name,
    value,
    required = false,
    disabled,
    customValidate,
    onChange,
    maxSize = 100, // Max file size in MB.
    position = 'center',
    label,
    accept = '*',
}) => {
    const memoizedValidate = useCallback(_validate, [maxSize, required]);
    const [fieldError, showError] = useFieldValidation({
        name,
        required: false,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [uploadError, setUploadError] = useState<string | null>(null);
    const error = uploadError || fieldError;

    const prevVal = usePrevious(value);
    useEffect(() => {
        if (prevVal !== value) {
            showError();
        }
    }, [prevVal, value, showError]);

    return (
        <FormField label={label} name={name} required={required}>
            <div
                className={`single-image-upload-container position-${position} ${
                    error ? 'error' : ''
                }`}
            >
                <div className="single-image-upload">
                    <input
                        ref={inputRef}
                        type="file"
                        className="hidden"
                        name={name}
                        id={name}
                        multiple={false}
                        onChange={handleChange}
                        accept={accept}
                        disabled={disabled}
                    />
                    <label htmlFor={name}>
                        {!!value &&
                            (checkIsFilenameImage(value.name) ? (
                                <img
                                    className="single-image-preview"
                                    alt="preview"
                                    src={`${S3_URL}/${value}`}
                                />
                            ) : (
                                <>{value.name}</>
                            ))}

                        <button
                            disabled={disabled}
                            onClick={handleButtonClick}
                            type="button"
                            className="button primary"
                        >
                            {value ? 'Change' : 'Choose'}
                        </button>
                    </label>
                    {!!value && (
                        <button
                            disabled={disabled}
                            onClick={handleClear}
                            type="button"
                            className="button secondary"
                        >
                            Clear
                        </button>
                    )}
                </div>
                <p className="form-error">{error}</p>
            </div>
        </FormField>
    );

    function handleButtonClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        inputRef.current?.click();
    }

    async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setUploadError(null);

        const files = e.target.files;
        if (!files || !files.length) return;

        const file = files[0];
        onChange(name, file);
    }

    function handleClear(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setUploadError(null);
        onChange(name, null);
    }

    function _validate(val: File | null) {
        if (required && val === null) return 'This is a required field.';
        if (val === null) return;

        if (bytesToMB(val.size) > maxSize)
            return `You cannot upload a file larger than ${maxSize}MB.`;
    }
};

export default ClientSingleFileUpload;
