import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export default function useRedirect(path: string) {
    const history = useHistory();

    const redirect = useCallback(() => {
        history.replace(path);
    }, [history, path]);

    return redirect;
}
