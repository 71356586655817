import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';
import {
    deleteInstitutionTypeFailure,
    deleteInstitutionTypeRequest,
    deleteInstitutionTypeSuccess,
    fetchInstitutionTypesFailure,
    fetchInstitutionTypesRequest,
    fetchInstitutionTypesSuccess,
    fetchInstitutionTypeFailure,
    fetchInstitutionTypeRequest,
    fetchInstitutionTypeSuccess,
    createInstitutionTypeRequest,
    createInstitutionTypeSuccess,
    createInstitutionTypeFailure,
    updateInstitutionTypeFailure,
    updateInstitutionTypeRequest,
    updateInstitutionTypeSuccess,
    FetchInstitutionTypesResponse,
} from '@actions/institutionTypes';
import InstitutionType from 'src/types/models/InstitutionType';

interface InstitutionTypesState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    fetchError: string | null;
    postError: string | null;
    institutionTypes: Record<number, InstitutionType>;
    itemCount: number;
}

const initialState: InstitutionTypesState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    fetchError: null,
    postError: null,
    institutionTypes: {},
    itemCount: 0,
};

export default createReducer(initialState, {
    [fetchInstitutionTypesRequest.type]: handleFetchRequest,
    [fetchInstitutionTypesSuccess.type]: handleFetchAllSuccess,
    [fetchInstitutionTypesFailure.type]: handleFetchError,
    [fetchInstitutionTypeRequest.type]: handleFetchRequest,
    [fetchInstitutionTypeSuccess.type]: handleFetchSingleSuccess,
    [fetchInstitutionTypeFailure.type]: handleFetchError,
    [createInstitutionTypeRequest.type]: handlePostRequest,
    [createInstitutionTypeSuccess.type]: handlePostSuccess,
    [createInstitutionTypeFailure.type]: handlePostError,
    [updateInstitutionTypeRequest.type]: handlePostRequest,
    [updateInstitutionTypeSuccess.type]: handlePostSuccess,
    [updateInstitutionTypeFailure.type]: handlePostError,
    [deleteInstitutionTypeRequest.type]: handlePostRequest,
    [deleteInstitutionTypeFailure.type]: handlePostError,
    [deleteInstitutionTypeSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: InstitutionTypesState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: InstitutionTypesState, action: PayloadAction<string>) {
    state.fetchError = action.payload;
    state.isFetching = false;
}

function handleFetchAllSuccess(
    state: InstitutionTypesState,
    action: PayloadAction<FetchInstitutionTypesResponse>,
) {
    state.institutionTypes = convertArrToObj(action.payload.items);
    state.itemCount = action.payload.totalItems;
    state.isFetching = false;
}

function handlePostRequest(state: InstitutionTypesState) {
    state.isPosting = true;
    state.postSuccess = false;
    state.postError = null;
}

function handlePostError(state: InstitutionTypesState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handlePostSuccess(state: InstitutionTypesState, action: PayloadAction<InstitutionType>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.institutionTypes[action.payload.id] = action.payload;
}

function handleFetchSingleSuccess(
    state: InstitutionTypesState,
    action: PayloadAction<InstitutionType>,
) {
    state.institutionTypes[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleDeleteSuccess(state: InstitutionTypesState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.institutionTypes[action.payload];
}
