import { fetchSchoolGroups } from '@actions/schoolGroups';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@hooks/usePrevious';
import {
    getSchoolsIsPosting,
    getSchoolsPostError,
    getSchoolsPostSuccess,
} from '@selectors/schools';
import { useCallback, useEffect, useState, useRef } from 'react';
import useForm from '@hooks/useForm';

import {
    SchoolCreateEditFormStepRefHandle,
    SchoolCreateEditFormState,
} from '@pages/schools/shared/types/SchoolCreateEditFormStep';

import SchoolCreateEditStep1 from '@pages/schools/shared/SchoolCreateEditStep1';
import SchoolCreateEditStep2 from '@pages/schools/shared/SchoolCreateEditStep2';
import SchoolCreateEditStep3 from '@pages/schools/shared/SchoolCreateEditStep3';
import SchoolCreateEditStep4 from '@pages/schools/shared/SchoolCreateEditStep4';

interface Options {
    onSubmit: (formState: SchoolCreateEditFormState) => void;
    initialFormState: SchoolCreateEditFormState;
    redirectURL: string;
}

const steps = [
    SchoolCreateEditStep1,
    SchoolCreateEditStep2,
    SchoolCreateEditStep3,
    SchoolCreateEditStep4,
];

const useCreateEditSchool = ({ onSubmit, initialFormState, redirectURL }: Options) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isPosting = useSelector(getSchoolsIsPosting);
    const error = useSelector(getSchoolsPostError);
    const success = useSelector(getSchoolsPostSuccess);
    const [formState, handleChange] = useForm(initialFormState);

    const [stepIndex, setStep] = useState(0);

    const isLastStep = stepIndex === steps.length - 1;
    const stepFormRef = useRef<SchoolCreateEditFormStepRefHandle>(null);

    const handleSubmit = () => {
        if (isLastStep) {
            onSubmit(formState);
        } else if (stepFormRef?.current?.onSubmit) {
            stepFormRef.current.onSubmit(formState);
        }
    };

    const closeModal = useCallback(() => {
        history.replace(redirectURL);
    }, [history, redirectURL]);

    const prevSuccess = usePrevious(success);
    useEffect(() => {
        if (!prevSuccess && success) {
            if (isLastStep) {
                dispatch(fetchSchoolGroups());
                closeModal();
            } else {
                setStep(prev => prev + 1);
            }
        }
    }, [dispatch, prevSuccess, success, closeModal, isLastStep]);

    useEffect(() => {
        dispatch(fetchSchoolGroups());
    }, [dispatch]);

    const handlePrev = () => {
        setStep(prev => prev - 1);
    };

    return {
        closeModal,
        handleSubmit,
        isPosting,
        error,
        formState,
        handleChange,
        handlePrev,
        isFirstStep: stepIndex === 0,
        isLastStep,
        CurrentStepForm: steps[stepIndex],
        stepFormRef,
    };
};

export default useCreateEditSchool;
