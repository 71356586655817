import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

import InstitutionType from '../../../types/models/InstitutionType';

export const createInstitutionTypeRequest = createAction('createInstitutionTypeRequest');
export const createInstitutionTypeSuccess = createAction<InstitutionType>(
    'createInstitutionTypeSuccess',
);
export const createInstitutionTypeFailure = createAction('createInstitutionTypeFailure');

export const createInstitutionType =
    (postbody: PostBody) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createInstitutionTypeRequest());
        try {
            const { data } = await api.post<PostBody, InstitutionType>(
                `institutionTypes`,
                postbody,
            );

            dispatch(createInstitutionTypeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createInstitutionTypeFailure, e as APIError);
        }
    };

interface PostBody {
    name: string;
}
