import {
    fetchRegionsFailure,
    fetchRegionsRequest,
    fetchRegionsSuccess,
} from '@actions/regions/fetchRegions';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { Region } from 'src/types/models/Regions';

interface RegionState {
    isFetching: boolean;
    fetchError: string | null;
    regions: Region[];
}

const initialState: RegionState = {
    isFetching: false,
    fetchError: null,
    regions: [],
};

export default createReducer(initialState, {
    [fetchRegionsRequest.type]: handleFetchRequest,
    [fetchRegionsSuccess.type]: handleFetchSuccess,
    [fetchRegionsFailure.type]: handleFetchFailure,
});

function handleFetchRequest(state: RegionState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchSuccess(state: RegionState, action: PayloadAction<Region[]>) {
    state.isFetching = false;
    state.regions = action.payload;
}

function handleFetchFailure(state: RegionState, action: PayloadAction<string>) {
    state.fetchError = action.payload;
    state.isFetching = false;
}
