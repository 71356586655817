import ConfirmModal from '@components/modal/ConfirmModal';
import useDeleteInstitution from './hooks/useDeleteInstitution';

const InstitutionDeleteModal = () => {
    const { handleSubmit, closeModal, isPosting, error, institution } = useDeleteInstitution();

    if (!institution) return <></>;
    return (
        <ConfirmModal
            isPosting={isPosting}
            title="Delete institution"
            description="Are you sure you want to delete this institution?"
            handleSubmit={handleSubmit}
            closeModal={closeModal}
            error={error}
        />
    );
};

export default InstitutionDeleteModal;
