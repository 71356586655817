import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from './redux/store';
import App from './App';

import './_styles/main.scss';

import ErrorBoundary from '@pages/error/ErrorBoundary';

const container = document.getElementById('root');
const root = createRoot(container!); // eslint-disable-line

root.render(
    <ErrorBoundary>
        <Router>
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </ErrorBoundary>,
);
