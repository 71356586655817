import Select from './Select';

import { FormInputProps } from '../../types/shared/FormInputProps';
import useCountryOptions from '@hooks/useCountryOptions';
import { extraCountryNames } from '@utils/countries';

interface Props extends FormInputProps<string | null> {
    label?: string;
    placeholder?: string;
    subPlaceholder?: string;
    alwaysShowSub?: boolean;
}

const CountrySelect: React.FC<Props> = ({
    name,
    label,
    value = null,
    onChange,
    required,
    placeholder,
    subPlaceholder,
    alwaysShowSub,
}) => {
    const { defaultCountryOptions, ukSubCountryOptions } = useCountryOptions();
    const showSub = value != null && (value === 'GB' || !!extraCountryNames[value]);

    return (
        <>
            <Select
                search
                label={label}
                name={name}
                value={showSub ? 'GB' : value}
                options={defaultCountryOptions}
                onChange={onChange}
                required={required}
                placeholder={placeholder}
            />
            {(alwaysShowSub || showSub) && (
                <Select
                    label="UK Country"
                    name={`${name}---ukSubCountries`}
                    value={value === 'GB' ? null : value}
                    options={ukSubCountryOptions}
                    onChange={(_, newVal) => {
                        onChange(name, newVal === null ? 'GB' : newVal);
                    }}
                    placeholder={subPlaceholder}
                />
            )}
        </>
    );
};

export default CountrySelect;
