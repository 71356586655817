import { useCallback, useRef, useState } from 'react';
import FormField from './FormField';

import axios from 'axios';
import { FormInputProps } from 'src/types/shared/FormInputProps';
import useFieldValidation from '@hooks/useFieldValidation';
import config from '@config/index';

import { api } from '@utils/api';
import { bytesToMB, checkIsFilenameImage } from '@utils/generic';
const { S3_URL } = config;

interface Props extends FormInputProps<string | null> {
    maxSize?: number;
    position?: 'left' | 'right' | 'center';
    label?: string;
}

const SingleFileUpload: React.FC<Props> = ({
    name,
    value,
    required = false,
    disabled,
    customValidate,
    onChange,
    maxSize = 5, // Max file size in MB.
    position = 'center',
    label,
}) => {
    const memoizedValidate = useCallback(_validate, []);
    const [fieldError, showError] = useFieldValidation({
        name,
        required,
        value,
        customValidate,
        extendedValidate: memoizedValidate,
    });

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [isPosting, setIsPosting] = useState(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const error = uploadError || fieldError;

    return (
        <FormField label={label} name={name} required={required}>
            <div
                className={`single-image-upload-container position-${position} ${
                    error ? 'error' : ''
                }`}
            >
                <div className="single-image-upload">
                    <input
                        ref={inputRef}
                        type="file"
                        className="hidden"
                        name={name}
                        id={name}
                        multiple={false}
                        onChange={handleChange}
                        accept="image/*"
                        disabled={disabled}
                    />
                    <label htmlFor={name}>
                        {!!value &&
                            (checkIsFilenameImage(value) ? (
                                <img
                                    className="single-image-preview"
                                    alt="preview"
                                    src={`${S3_URL}/${value}`}
                                />
                            ) : (
                                <>{getFileNameFromS3Key(value)}</>
                            ))}

                        <button
                            disabled={disabled || isPosting}
                            onClick={handleButtonClick}
                            type="button"
                            className="button primary"
                        >
                            {value ? 'Change' : 'Choose'}
                            {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
                        </button>
                    </label>
                    {!!value && (
                        <button
                            disabled={disabled || isPosting}
                            onClick={handleClear}
                            type="button"
                            className="button secondary"
                        >
                            Clear
                        </button>
                    )}
                </div>
                <p className="form-error">{error}</p>
            </div>
        </FormField>
    );

    function handleButtonClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        inputRef.current?.click();
    }

    async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setUploadError(null);
        setIsPosting(true);

        try {
            const files = e.target.files;
            if (!files || !files.length) return;

            const file = files[0];
            if (bytesToMB(file.size) > maxSize) {
                setUploadError(`You cannot upload a file larger than ${maxSize}MB.`);
                return;
            }

            const s3Key = await uploadFile(file);
            onChange(name, s3Key);
        } catch (err) {
            setUploadError('There was an error uploading your file.');
        }

        showError();
        setIsPosting(false);
    }

    function handleClear(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setUploadError(null);
        onChange(name, null);
        showError();
    }

    function _validate(val: string | null) {}
};

async function uploadFile(file: File) {
    const tidyFileName = makeTidyFileName(file.name);
    const {
        data: { s3UploadURL, s3Key },
    } = await requestMediaURL(tidyFileName, file.type, file.size);

    const options = {
        headers: { 'Content-Type': file.type, 'x-amz-acl': 'public-read' },
    };
    await axios.put(s3UploadURL, file, options);

    return s3Key;
}

function requestMediaURL(fileName: string, type: string, size: number) {
    const postBody = { fileName, type, size };

    return api.post<typeof postBody, PresignedUrlResponse>(`media/get-presigned-url`, postBody);
}

interface PresignedUrlResponse {
    s3UploadURL: string;
    s3Key: string;
}

function makeTidyFileName(filename = '') {
    const tidyName = filename
        .trim()
        .replace(/[^a-zA-Z0-9 .]/g, '')
        .replace(/[ ]/g, '-')
        .toLowerCase();

    return tidyName;
}

function getFileNameFromS3Key(key: string) {
    const name = key.split('/').pop();
    return name;
}

export default SingleFileUpload;
