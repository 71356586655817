import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { APIError } from 'src/types/shared/APIError';

export const postImportUsersRequest = createAction('postImportUsersRequest');
export const postImportUsersSuccess = createAction<ImportUsersResponse>('postImportUsersSuccess');
export const postImportUsersFailure = createAction('postImportUsersFailure');

export const postImportUsers =
    (file: File, continueWithErrors: boolean) =>
    async (dispatch: Dispatch): Promise<void> => {
        dispatch(postImportUsersRequest());

        try {
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('continueWithErrors', `${continueWithErrors}`);

            const { data } = await api.postMultipart<ImportUsersResponse>('users/import', formData);

            dispatch(postImportUsersSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postImportUsersFailure, e as APIError);
        }
    };

export interface ImportUsersResponse {
    errors: string[];
}
