import Select from '@components/form/Select';
import { MegeSchoolStepsProps } from './shared/MegeSchoolStepsProps';
import { useMemo } from 'react';

const SchoolsMergeSelectSource: React.FC<MegeSchoolStepsProps> = ({
    sourceSchoolID,
    setSourceSchoolID,
    schools,
}) => {
    const sourceSchoolOptions = useMemo(() => {
        return schools.map(s => ({ label: `${s.name} (${s.uniqueID})`, value: s.id }));
    }, [schools]);

    return (
        <>
            <div style={{ minHeight: 150 }}>
                <Select
                    label="Select source school"
                    name="sourceSchoolID"
                    omitRemove
                    value={sourceSchoolID}
                    options={sourceSchoolOptions}
                    onChange={(_, value) => {
                        setSourceSchoolID(value as number);
                    }}
                />
            </div>
        </>
    );
};

export default SchoolsMergeSelectSource;
