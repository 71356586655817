import useFetchActivitiesForGroup from './hooks/useFetchActivitiesForGroup';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';

import { Activity } from '../../../types/models/Activities';
import { TableColumns } from '../../../types/shared/table';
import Table from '@components/table/Table';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';

const Activities = () => {
    const { activities, isFetching, error, activityGroupID } = useFetchActivitiesForGroup();

    return (
        <ContentBlock>
            <CreateHeader>
                Activities
                <ButtonRow>
                    <LinkButton
                        size="small"
                        href={`/activity-groups/${activityGroupID}/create-activity`}
                    >
                        Create
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>
            <Table columns={columns} isLoading={isFetching} error={error} rows={activities} />
        </ContentBlock>
    );
};

const columns: TableColumns<Activity> = [
    {
        key: 0,
        heading: 'Name',
        getValue: row => row.name,
        getSort: (a, b) => a.name.localeCompare(b.name),
        searchable: true,
    },
    {
        key: 1,
        heading: 'Activity Code',
        getValue: row => row.activityCode || '-',
        getSort: (a, b) => (a.activityCode || '').localeCompare(b.activityCode || ''),
        searchable: true,
    },
    {
        key: 8,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/activities/${row.id}`}>View</LinkButton>
            </ButtonRow>
        ),
    },
];

export default Activities;
