import { useParams } from 'react-router-dom';
import useFetchSingleCcdbGroup from '@pages/ccdbGroups/ccdbGroup/_hooks/useFetchSingleCcdbGroup';

import DataCheck from '@components/common/DataCheck';

import CcdbGroup from '@pages/ccdbGroups/ccdbGroup/CcdbGroup';
import DeleteCcdbGroupModal from '@pages/ccdbGroups/ccdbGroup/DeleteCcdbGroupModal';
import UpdateCcdbGroupModal from '@pages/ccdbGroups/ccdbGroup/UpdateCcdbGroupModal';
import AddUsersToCcdbGroupModal from '@pages/ccdbGroups/ccdbGroup/users/AddUsersToCcdbGroupModal';
import RemoveUsersFromCcdbGroupModal from '@pages/ccdbGroups/ccdbGroup/users/RemoveUsersFromCcdbGroupModal';
import ApplyActivitiesToCcdbGroupModal from './activityHistories/ApplyActivitiesToCcdbGroupModal';
import UndoActivityHistoryModal from './activityHistories/UndoActivityHistoryModal';

interface Props {
    showEditModal?: boolean;
    showDeleteModal?: boolean;
    showAddUserModal?: boolean;
    showRemoveUserModal?: boolean;
    showApplyActivitiesModal?: boolean;
    showUndoActivityHistoryModal?: boolean;
}

const CcdbGroupContainer = ({
    showEditModal = false,
    showDeleteModal = false,
    showAddUserModal = false,
    showRemoveUserModal = false,
    showApplyActivitiesModal = false,
    showUndoActivityHistoryModal = false,
}: Props) => {
    const { id } = useParams<{ id: string }>();

    const { isFetching, fetchError, ccdbGroup } = useFetchSingleCcdbGroup(+id);

    return (
        <>
            <DataCheck dataExists={!!ccdbGroup} isFetching={isFetching} error={fetchError}>
                <CcdbGroup ccdbGroup={ccdbGroup} />
            </DataCheck>

            {showDeleteModal && <DeleteCcdbGroupModal />}
            {showEditModal && <UpdateCcdbGroupModal />}
            {showAddUserModal && <AddUsersToCcdbGroupModal />}
            {showRemoveUserModal && <RemoveUsersFromCcdbGroupModal />}
            {showApplyActivitiesModal && <ApplyActivitiesToCcdbGroupModal />}
            {showUndoActivityHistoryModal && <UndoActivityHistoryModal />}
        </>
    );
};

export default CcdbGroupContainer;
