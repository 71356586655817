import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'src/types/shared/APIError';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import SchoolGroup from 'src/types/models/SchoolGroup';

export const fetchSchoolGroupsRequest = createAction('fetchSchoolGroupsRequest');
export const fetchSchoolGroupsSuccess = createAction<SchoolGroup[]>('fetchSchoolGroupsSuccess');
export const fetchSchoolGroupsFailure = createAction('fetchSchoolGroupsFailure');

export const fetchSchoolGroups =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchSchoolGroupsRequest());
        try {
            const { data } = await api.get<SchoolGroup[]>('schoolGroups');

            dispatch(fetchSchoolGroupsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchSchoolGroupsFailure, e as APIError);
        }
    };
