import {
    selectActivityHistoriesIsPosting,
    selectActivityHistoriesPostError,
    selectActivityHistoriesPostSuccess,
} from '@selectors/activityHistories';
import { selectActivitiesObj } from '@selectors/activities';
import { fetchActivities } from '@actions/activities/fetchActivities';
import { fetchActivityGroups } from '@actions/activityGroups/fetchActivityGroups';
import { selectActivityGroupsObj } from '@selectors/activityGroups';
import { useCallback, useEffect, useMemo } from 'react';
import useForm from '@hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import usePrevious from '@hooks/usePrevious';

import { getUsers } from '@selectors/users';

import { applyActivitiesToUsers } from '@actions/activityHistories';

const useApplyActivitiesToCcdbGroup = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();
    const ccdbGroupID = +id;

    const isPosting = useSelector(selectActivityHistoriesIsPosting);
    const error = useSelector(selectActivityHistoriesPostError);
    const success = useSelector(selectActivityHistoriesPostSuccess);
    const prevSuccess = usePrevious(success);
    const activityGroupsObj = useSelector(selectActivityGroupsObj);
    const activitiesObj = useSelector(selectActivitiesObj);
    const users = useSelector(getUsers);

    const activities = useMemo(() => Object.values(activitiesObj), [activitiesObj]);

    const [form, handleChange] = useForm<FromState>({
        userIDs: [],
        activityGroupIDs: [],
        activityIDs: [],
        activityLocation: '',
        activityDate: null,
    });

    const userOptions = useMemo(
        () =>
            Object.values(users)
                .filter(user => user.ccdbGroupIds.includes(ccdbGroupID))
                .map(u => ({ label: `${u.firstName} ${u.lastName}`, value: u.id })),
        [users, ccdbGroupID],
    );

    const activityGroupOptions = useMemo(() => {
        return Object.values(activityGroupsObj).map(ag => ({ label: ag.name, value: ag.id }));
    }, [activityGroupsObj]);

    const activityOptions = useMemo(() => {
        return Object.values(activities)
            .filter(a => form.activityGroupIDs.includes(a.activityGroupID))
            .map(a => ({
                label: `${activityGroupsObj[a.activityGroupID]?.name} > ${a.name}`,
                value: a.id,
            }))
            .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }, [activities, activityGroupsObj, form.activityGroupIDs]);

    const selectedActivityIDs = useMemo(() => {
        const selectedIDs = form.activityIDs.filter(aID =>
            form.activityGroupIDs.includes(activitiesObj[aID]?.activityGroupID),
        );
        return selectedIDs;
    }, [form.activityIDs, form.activityGroupIDs, activitiesObj]);

    const handleSubmit = () => {
        const { activityLocation, activityDate, userIDs } = form;
        const postBody = {
            activityLocation,
            activityDate,
            activityIDs: selectedActivityIDs,
            userIDs,
        };
        dispatch(applyActivitiesToUsers(ccdbGroupID, postBody));
    };

    const closeModal = useCallback(() => {
        history.push(`/ccdb-groups/${id}`);
    }, [history, id]);

    useEffect(() => {
        dispatch(fetchActivityGroups());
        dispatch(fetchActivities());
    }, [dispatch]);

    useEffect(() => {
        if (success && !prevSuccess) {
            closeModal();
        }
    }, [dispatch, history, id, success, prevSuccess, closeModal]);

    return {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        closeModal,
        error,
        activityGroupOptions,
        activityOptions,
        selectedActivityIDs,
        userOptions,
    };
};

interface FromState {
    userIDs: number[];
    activityGroupIDs: number[];
    activityIDs: number[];
    activityLocation: string;
    activityDate: Date | null;
}

export default useApplyActivitiesToCcdbGroup;
