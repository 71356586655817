import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { getUsers, getUsersError, getUsersIsFetching, getUsersItemCount } from '@selectors/users';
import { fetchUsersForCcdbGroup } from '@actions/users';
import useApiFilterTableRef from '@hooks/useApiFilterTableRef';
import usePrevious from '@hooks/usePrevious';
import { selectActivityHistoriesPostSuccess } from '@selectors/activityHistories';
import { selectCcdbGroupsPostSuccess } from '@selectors/ccdbGroups';

const useFetchUsersForCcdbGroup = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();

    const ccdbGroupID = +id;

    const users = useSelector(getUsers);
    const isFetching = useSelector(getUsersIsFetching);
    const error = useSelector(getUsersError);
    const itemCount = useSelector(getUsersItemCount);

    const tableRef = useApiFilterTableRef();

    // post actions within page
    const activityHistoriesPostSuccess = useSelector(selectActivityHistoriesPostSuccess);
    const ccdbGroupsPostSuccess = useSelector(selectCcdbGroupsPostSuccess);
    const prevProps = usePrevious({ activityHistoriesPostSuccess, ccdbGroupsPostSuccess });

    useEffect(() => {
        if (activityHistoriesPostSuccess && !activityHistoriesPostSuccess) {
            tableRef.current?.refreshData();
        }
    }, [tableRef, activityHistoriesPostSuccess, prevProps.activityHistoriesPostSuccess]);

    useEffect(() => {
        if (ccdbGroupsPostSuccess && !prevProps.ccdbGroupsPostSuccess) {
            tableRef.current?.refreshData();
        }
    }, [tableRef, ccdbGroupsPostSuccess, prevProps.ccdbGroupsPostSuccess]);

    const handleFetch = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            const params = { page, limit: pageSize, searchTerm };
            dispatch(fetchUsersForCcdbGroup(ccdbGroupID, params));
        },
        [dispatch, ccdbGroupID],
    );

    return { ccdbGroupID, isFetching, error, users, itemCount, tableRef, handleFetch };
};

export default useFetchUsersForCcdbGroup;
