import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchActivityGroups } from '@actions/activityGroups';
import { selectActivityGroupsObj, selectActivityGroupsFetchError } from '@selectors/activityGroups';
import { selectActivitiesIsFetching } from '@selectors/activities';

const useFetchActivityGroups = () => {
    const dispatch = useDispatch();

    const activityGroups = Object.values(useSelector(selectActivityGroupsObj));
    const isFetching = useSelector(selectActivitiesIsFetching);
    const error = useSelector(selectActivityGroupsFetchError);

    useEffect(() => {
        dispatch(fetchActivityGroups());
    }, [dispatch]);

    return {
        activityGroups,
        isFetching,
        error,
    };
};

export default useFetchActivityGroups;
