import ct, { CountryCode } from 'countries-and-timezones';
import { Region } from '../types/models/Regions';

export const defaultCountryNames = Object.entries(ct.getAllCountries()).reduce(
    (acc: Record<string, string>, [countryCode, country]) => {
        acc[countryCode] = country.name;
        return acc;
    },
    {},
);

export const extraCountryNames: Record<string, string> = {
    'GB-SCT': 'Scotland',
    'GB-ENG': 'England',
    'GB-WLS': 'Wales',
    'GB-NIR': 'Northern Ireland',
};

export const allCountryNames = {
    ...defaultCountryNames,
    ...extraCountryNames,
} as unknown as Record<CountryCode, string>;

export const getCountryName = (countryCode?: CountryCode) => {
    if (!countryCode || !allCountryNames[countryCode]) return '';
    return allCountryNames[countryCode];
};

export const getCountryNames = (countryCodes?: CountryCode[] | null) => {
    if (!countryCodes) return null;
    return countryCodes?.map(getCountryName).join(', ');
};

export function formatRegionOptions(regions: Region[]) {
    return [...regions]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(region => {
            const { name } = region;
            return {
                label: name,
                value: name,
            };
        });
}
