import CreateHeader from '@components/layout/createHeader/CreateHeader';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';
import Title from '@components/typography/Title';

import { ActivityGroup as ActivityGroupModel } from 'src/types/models/ActivityGroups';
import Activities from './Activities';

interface Props {
    activityGroup: ActivityGroupModel;
}

const ActivityGroup = ({ activityGroup }: Props) => {
    return (
        <>
            <CreateHeader>
                <Title>Activity Group - {activityGroup?.name}</Title>
                <ButtonRow>
                    <LinkButton
                        source="secondary"
                        href={`/activity-groups/${activityGroup.id}/edit`}
                    >
                        Edit
                    </LinkButton>
                    <LinkButton
                        source="negative"
                        href={`/activity-groups/${activityGroup.id}/delete`}
                    >
                        Delete
                    </LinkButton>
                </ButtonRow>
            </CreateHeader>
            <Activities />
        </>
    );
};

export default ActivityGroup;
