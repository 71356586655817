import { api, handleApiErrors } from '@utils/api';
import { createAction } from '@reduxjs/toolkit';

import { AppDispatch } from '../../store';
import { APIError } from '../../../types/shared/APIError';
import { Activity } from '../../../types/models/Activities';

export const restoreActivityRequest = createAction('restoreActivityRequest');
export const restoreActivitySuccess = createAction<Activity>('restoreActivitySuccess');
export const restoreActivityFailure = createAction('restoreActivityFailure');

export const restoreActivity = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(restoreActivityRequest());

    try {
        const { data } = await api.patch<object, Activity>(`activities/${id}`, {});

        dispatch(restoreActivitySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, restoreActivityFailure, e as APIError);
    }
};
