import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../redux/store';
import { getSchool, getSchoolsIsFetching, getSchoolsFetchError } from '@selectors/schools';
import { fetchSchool } from '@actions/schools';

const useFetchSchool = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const school = useAppSelector(state => getSchool(state, +id));
    const isFetching = useSelector(getSchoolsIsFetching);
    const fetchError = useSelector(getSchoolsFetchError);

    useEffect(() => {
        dispatch(fetchSchool(+id));
    }, [dispatch, id]);

    return {
        school,
        isFetching,
        fetchError,
    };
};

export default useFetchSchool;
